import React, {
  useCallback,
  useEffect,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "@ag-grid-community/react";
import {
  ColDef,
  FirstDataRenderedEvent,
  PaginationNumberFormatterParams,
} from "@ag-grid-community/core";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import styled from "styled-components";
import { Popup } from "../../../constants/interface";

import { convertDateToYYYYMMDD_HHmm } from "../../../utils";
import Api from "../../../service";
import { Modal } from "@mui/material";
import CreateFormModal from "./CreateFormModal";
import ActionsCell from "./ActionsCell";
import ImageCell from "./ImageCell";
import PopupContext from "../context/popup-context";

const Container = styled.div`
  width: 100%;
  height: 75vh;
  /* box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.2); */
  border-radius: 10px;
  position: relative;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;
`;

const CreateButton = styled.div`
  padding: 8px 24px;
  background: rgb(85, 177, 71);
  color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
`;

const ButtonText = styled.span`
  font-size: 18px;
`;

const PageSizeContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: -50px;
`;

// Register the required feature modules with the Grid
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const PopupContainer = () => {
  const popupCtx = useContext(PopupContext);
  const { isOpenModal, setIsOpenModal, isReloadList } = popupCtx;

  const handleOpen = () => {
    setIsOpenModal(true);
  };

  const cellStyle = {
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
  };

  const gridRef = useRef<AgGridReact<Popup>>(null);
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState<Popup[]>();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    // {
    //   field: "_id",
    //   headerName: "ID",
    //   cellStyle: cellStyle,
    // },
    {
      colId: "thumbnail",
      headerName: "Thumbnail",
      floatingFilter: false,
      cellRenderer: ImageCell,
      filter: false,
    },
    {
      field: "redirectLink",
      headerName: "Redirect Link",
      cellStyle: cellStyle,
    },
    {
      field: "createdAt",
      filter: false,
      floatingFilter: false,
      cellStyle: cellStyle,
    },
    {
      colId: "action",
      headerName: "",
      floatingFilter: false,
      cellRenderer: ActionsCell,
      filter: false,
    },
  ]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      // editable: true,
      // enableRowGroup: true,

      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      // flex: 1,
      minWidth: 100,
    };
  }, []);
  const paginationNumberFormatter = useCallback(
    (params: PaginationNumberFormatterParams) => {
      return "[" + params.value.toLocaleString() + "]";
    },
    []
  );

  const onGridReady = useCallback(() => {
    Api.Popup.getPopups().then((popups: any) => {
      const formattedDatePopups = popups?.data.map((popup: Popup) => {
        return {
          ...popup,
          createdAt: `${convertDateToYYYYMMDD_HHmm(
            popup?.createdAt || new Date()
          )}`,
        };
      });
      setRowData(formattedDatePopups);
      gridRef?.current?.api.sizeColumnsToFit({
        defaultMinWidth: 50,
        columnLimits: [
          { key: "thumbnail", maxWidth: 200 },
          { key: "createdAt", maxWidth: 150 },
          { key: "action", maxWidth: 200 },
        ],
      });
    });
  }, []);

  useEffect(() => {
    onGridReady();
  }, [isReloadList]);

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    gridRef.current!.api.paginationGoToPage(0);
    gridRef.current!.api.paginationSetPageSize(20);
  }, []);

  const onPageSizeChanged = useCallback(() => {
    var value = (document.getElementById("page-size") as HTMLInputElement)
      .value;
    gridRef.current!.api.paginationSetPageSize(Number(value));
  }, []);

  return (
    <Container>
      <ButtonWrap>
        <CreateButton onClick={handleOpen}>
          <ButtonText>Create</ButtonText>
        </CreateButton>
      </ButtonWrap>

      <div style={containerStyle}>
        <div
          className="example-wrapper"
          style={{ width: "100%", height: "100%", position: "relative" }}
        >
          <div style={gridStyle} className="ag-theme-alpine">
            <AgGridReact<Popup>
              ref={gridRef}
              rowData={rowData}
              getRowHeight={() => 150}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              pivotPanelShow={"always"}
              pagination={true}
              paginationPageSize={10}
              paginationNumberFormatter={paginationNumberFormatter}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
          </div>

          <PageSizeContainer className="example-header">
            Page Size:{" "}
            <select onChange={onPageSizeChanged} id="page-size">
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="500">500</option>
            </select>
          </PageSizeContainer>
        </div>
      </div>
      {isOpenModal && (
        <CreateFormModal isOpen={isOpenModal} setIsOpen={setIsOpenModal} />
      )}
    </Container>
  );
};
export default React.memo(PopupContainer);
