import React, { useState } from "react";
import ConfirmModalV4 from "../../../../components/modal/ConfirmModal_V4";
import { useModal } from "../../../../hooks/use-modal";
import { ModalKey } from "../../../../context/modal-context";
import useMaterialsLibrary from "../../MaterialsLibrary.hooks";
import LoadingPage from "../../../../components/LoadingPage";
import { toast } from "react-toastify";
import Api from "../../../../service";

const ActionConfirmModal = () => {
  const { isModalOpen, closeModal } = useModal();
  const { material, refreshTable } = useMaterialsLibrary();
  const [isLoading, setIsLoading] = useState(false);

  const onDeleteMaterialHandler = async () => {
    setIsLoading(true);
    try {
      if (!material?._id) return;

      const response = await Api.MaterialsLib.deleteMaterial({
        material_id: material?._id,
      });

      if (response.data) {
        toast.success(`${material.title} is deleted!`);
        closeModal(ModalKey.MATERIALS_LIBRARY_DELETE);
        refreshTable();
      }
    } catch (error) {
      toast.error(error as string);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <LoadingPage />;

  return (
    <>
      <ConfirmModalV4
        isOpen={isModalOpen(ModalKey.MATERIALS_LIBRARY_DELETE)}
        body="Are you sure you want to DELETE this material?"
        onClose={() => closeModal(ModalKey.MATERIALS_LIBRARY_DELETE)}
        onConfirm={onDeleteMaterialHandler}
      />
    </>
  );
};

export default ActionConfirmModal;
