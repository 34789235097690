import React, { useContext } from "react";
import ReactSelect from "react-select";
import OrderContext, { FormMode } from "../../../context/order-context";
import {
  ICountry,
  IDistrict,
  IState,
  Order,
} from "../../../../../constants/interface";
import { RowWrap, TitleStyle, WrapText } from "../CreateForm";

interface IProps {
  title: string;
  keyOfOrder: keyof Order;
  listData: any[];
}
const SelectAddress = ({ title, listData, keyOfOrder }: IProps) => {
  const { order, setOrder } = useContext(OrderContext);
  const onChangeHandler = (item: any) => {
    switch (keyOfOrder) {
      case "country":
        const country: ICountry = listData.find((countryItem: ICountry) => {
          return countryItem.id === item.value;
        });
        setOrder((prev: any) => ({
          ...prev,
          [keyOfOrder]: country,
        }));
        break;
      case "state":
        const state: IState = listData.find((stateItem: IState) => {
          return stateItem.id === item.value;
        });
        setOrder((prev: any) => ({
          ...prev,
          [keyOfOrder]: state,
        }));
        break;
      case "city":
        const city: IDistrict = listData.find((cityItem: IDistrict) => {
          return cityItem.id === item.value;
        });
        setOrder((prev: any) => ({
          ...prev,
          [keyOfOrder]: city,
        }));
        break;
      default:
        break;
    }
  };
  const value: any = order ? order[keyOfOrder] : null;
  const selectedValue = listData.find((item) => item.id === value?.id);

  return (
    <RowWrap>
      <WrapText>
        <TitleStyle>{title}</TitleStyle>
      </WrapText>
      <WrapText>
        <ReactSelect
          classNamePrefix="select"
          className="input-select"
          onChange={onChangeHandler}
          isDisabled={true}
          value={{ value: selectedValue?.id, label: selectedValue?.name }}
          isSearchable
          options={listData.map((item: any) => ({
            label: item.name,
            value: item.id,
          }))}
        />
      </WrapText>
    </RowWrap>
  );
};

export default SelectAddress;
