import { Button } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import Api from "../../../service";
import EventContext from "../context/event-context";
import Popover from "@mui/material/Popover";
import { toast } from "react-toastify";
import moment from "moment";
import { PushNotiType } from "../../../constants/types";
import ButtonCRUD from "../../../components/buttonCRUD";
import { CRUDType } from "../../../components/buttonCRUD";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Wrapper = styled.div`
  min-width: 150px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`;
const EditBtn = styled(Button)`
  background-color: rgba(1, 1, 218, 0.5) !important;
  color: #fff !important;
`;

const DeleteBtn = styled(EditBtn)`
  background-color: #ea4545 !important;
`;

const ConfirmDelete = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 16px;
  color: #222222;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const ConfirmTitle = styled.span`
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
`;

const ConfirmButton = styled.div<{ background: string }>`
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  background: ${({ background }) =>
    background ? background : "rgb(36, 107, 212)"};
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
`;

const Wrap = styled.div``;

interface Action {
  type: string;
  title: string;
}

function ActionsCell(props: ICellRendererParams) {
  const eventCtx = useContext(EventContext);
  const { setIsOpenModal, setNode, setEvent, isReloadList, setIsReloadList } =
    eventCtx;

  const onClickEditBtn = async () => {
    setIsOpenModal(true);
    setNode(props.node);
    setEvent(props.data);
  };

  const onSendEmailBtn = async () => {};

  const onDeleteBtn = async () => {
    try {
      const postToDelete = {
        eventId: props?.data?._id,
      };

      const response = await Api.Event.deleteEvent(postToDelete);
      if (response) {
        setIsReloadList(!isReloadList);
        toast.success("Deleted !");
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  //confirm delete popup
  const [action, setAction] = useState<Action>({
    type: "",
    title: "",
  });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleConfirmDelete = (
    event: React.MouseEvent<HTMLButtonElement>,
    type: string
  ) => {
    if (!type) return;
    const selectedAction = {
      type: type,
      title:
        type === CRUDType.Delete
          ? "Are you sure to delete this row?"
          : "Confirm send email to notice new Blog?",
    };
    setAction(selectedAction);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  //end confirm delete popup

  return (
    <Container>
      <Wrapper>
        <Wrap onClick={onClickEditBtn}>
          <ButtonCRUD type={CRUDType.Update} size="medium" />
        </Wrap>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <ConfirmDelete>
            <ConfirmTitle>{action.title}</ConfirmTitle>
            <ConfirmButton
              onClick={
                action.type === CRUDType.Delete ? onDeleteBtn : onSendEmailBtn
              }
              background="rgb(36, 107, 212)"
            >
              Confirm
            </ConfirmButton>
          </ConfirmDelete>
        </Popover>
        <Wrap onClick={(e: any) => handleConfirmDelete(e, CRUDType.Delete)}>
          <ButtonCRUD type={CRUDType.Delete} size="medium" />
        </Wrap>
      </Wrapper>
    </Container>
  );
}

export default ActionsCell;
