import React, { useContext, useEffect, useMemo } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  redirect,
} from "react-router-dom";
import { AdminRole, MaterialType } from "./constants/types";
import Layout from "./components/layout";
import Login from "./pages/login";
import Home from "./pages/home/Home";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import AuthContext from "./context/auth-context";
import User from "./pages/user";
import Media from "./pages/media";
import OrderPage from "./pages/order";
import Setting from "./pages/setting";
import UserAgent from "./pages/user-agent";
import Community from "./pages/community";
import Withdraw from "./pages/withdraw";
import Subscribers from "./pages/subscribers";
import Event from "./pages/event";
import Popup from "./pages/popup";
import PendingRequest from "./pages/user-agent/pages/pending-requests/PendingRequest";
import { AgentOutlet } from "./pages/user-agent/context/agent-context";
import ProductKey from "./pages/product-key/ProductKey";
import MaterialsLibrary from "./pages/materials-lib/MaterialsLibrary";
import { MaterialsLibraryOutlet } from "./pages/materials-lib/context/materials-lib-context";
import MaterialsLibraryImage from "./pages/materials-lib/sub-pages/image/MaterialsLibraryImage";
import MaterialsLibraryVideo from "./pages/materials-lib/sub-pages/video/MaterialsLibraryVideo";
import MaterialsLibraryOther from "./pages/materials-lib/sub-pages/other/MaterialsLibraryOther";
import MaterialsLibraryCollection from "./pages/materials-lib/sub-pages/collection/MaterialsLibraryCollection";

function App() {
  const authCtx = useContext(AuthContext);
  const { isLogin, role } = authCtx;

  const routeMarketingAgent = [
    <Route path="" element={<UserAgent />} />,
    <Route path="/user-agent" element={<UserAgent />} />,
    <Route path="/community" element={<Community />} />,
  ];

  const routeAdmin = [
    <Route path="" element={<Home />} />,
    <Route path="/user" element={<User />} />,
    <Route path="/user-agent">
      <Route element={<AgentOutlet />}>
        <Route index element={<UserAgent />} />
        <Route path="account-agent" element={<UserAgent />} />
        <Route path="pending-requests" element={<PendingRequest />} />
      </Route>
    </Route>,
    <Route path="/media" element={<Media />} />,
    <Route path="/order" element={<OrderPage />} />,
    <Route path="/community" element={<Community />} />,
    <Route path="/withdraw" element={<Withdraw />} />,
    <Route path="/subscribers" element={<Subscribers />} />,
    <Route path="/agent-event" element={<Event />} />,
    <Route path="/popup" element={<Popup />} />,
    <Route path="/product-key" element={<ProductKey />} />,
    <Route path="/materials-lib">
      <Route element={<MaterialsLibraryOutlet />}>
        <Route index element={<MaterialsLibrary />} />
        <Route path="collections" element={<MaterialsLibraryCollection />} />
        <Route path="images" element={<MaterialsLibraryImage />} />
        <Route path="videos" element={<MaterialsLibraryVideo />} />
        <Route path="others" element={<MaterialsLibraryOther />} />
      </Route>
    </Route>,
    <Route path="/setting" element={<Setting />} />,
  ];

  const routeAuthorElement = useMemo(() => {
    if (
      role === AdminRole.SuperAdmin ||
      role === AdminRole.Admin ||
      role === "SUPPER"
    )
      return routeAdmin;

    if (role === AdminRole.MarketingAgent) return routeMarketingAgent;
  }, [role]);

  return (
    <Layout isLogin={isLogin}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<ProtectedRoute redirect="/login" />}>
          {/* <Route path="" element={<Home />} />
          <Route path="/user" element={<User />} />
          <Route path="/user-agent" element={<UserAgent />} />
          <Route path="/media" element={<Media />} />
          <Route path="/order" element={<Order />} />
          <Route path="/setting" element={<Setting />} /> */}
          {routeAuthorElement}
        </Route>
      </Routes>
    </Layout>
  );
}

export default App;
