import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import CommunityContext from "../../../context/community-context";
import AuthContext from "../../../../../context/auth-context";
import styled from "styled-components";
import ButtonCRUD from "../../../../../components/buttonCRUD";
import { CRUDType } from "../../../../../components/buttonCRUD";
import { AgentStatus, UserType } from "../../../../../constants/types";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Button, Chip, Modal } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Api from "../../../../../service";
import { toast } from "react-toastify";
import { breakPoints } from "../../../../../constants/device";
import { Agent } from "../../../../../constants/interface";
import { TypePost } from "../Detail";
import moment from "moment";

const Container = styled.div`
  height: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 50px;
`;

const Row = styled.div<{ marginLeft?: number; isContainer?: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 32px;
  ${({ marginLeft }) => marginLeft && `padding-left: ${marginLeft}px`}
  ${({ isContainer }) =>
    isContainer && "gap: 160px; overflow: hidden auto;flex-grow: 1;"}
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  @media (max-width: ${breakPoints.exDesktop}) {
    gap: 10px;
  }
`;

const AvatarWrap = styled.div`
  min-width: 200px;
  max-width: 200px;
`;

const Avatar = styled.img`
  object-fit: cover;
  width: 100%;
  max-height: 200px;
  border-radius: 50%;
`;

const WrapText = styled.div`
  min-width: 250px;
  @media (max-width: ${breakPoints.exDesktop}) {
    min-width: 200px;
  }
`;

const WrapImage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 500px;
  max-width: 500px;
  @media (max-width: ${breakPoints.exDesktop}) {
    min-width: 300px;
    max-width: 300px;
  }
`;

const WrapInput = styled.div`
  min-width: 400px;
  @media (max-width: ${breakPoints.exDesktop}) {
    min-width: 250px;
  }
`;

const WrapInputMutiline = styled.div`
  min-width: 800px;
  @media (max-width: ${breakPoints.exDesktop}) {
    min-width: 400px;
  }
`;

const WrapTextRightColumn = styled.div`
  min-width: 250px;
  @media (max-width: ${breakPoints.exDesktop}) {
    min-width: 200px;
  }
`;

const TextStyle = styled.span`
  line-height: 40px;
  font-size: 20px;
  font-weight: 600;
  font-family: "Quicksand";
  font-style: normal;
  /* text-transform: capitalize; */
  color: #222222;
  @media (max-width: ${breakPoints.exDesktop}) {
    font-size: 16px;
  }
`;

const TitleStyle = styled(TextStyle)`
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
  color: gray;
  @media (max-width: ${breakPoints.exDesktop}) {
    font-size: 16px;
  }
`;

const GroupTitleStyle = styled(TextStyle)`
  font-size: 24px;
  font-weight: 700;
  text-transform: capitalize;
  @media (max-width: ${breakPoints.exDesktop}) {
    font-size: 20px;
  }
`;

const FullName = styled(TitleStyle)`
  font-size: 24px;
  font-weight: 700;
  color: #222222;
`;

const Email = styled(TextStyle)`
  font-weight: 600;
  font-size: 20px;
  text-transform: none;
  color: gray;
`;

const Wrap = styled.div``;

const WrapButton = styled.div`
  display: flex;
  gap: 24px;
  @media (max-width: ${breakPoints.exDesktop}) {
    gap: 12px;
  }
`;

const CustomButton = styled(LoadingButton)<{ bgColor: string; width?: number }>`
  display: inline-block !important;
  vertical-align: middle !important;
  /* padding: 0 !important; */
  padding: 0 24px !important;
  margin: 0 !important;
  width: ${({ width }) => (width ? `${width}px` : "auto")};
  /* min-width: 35px !important; */
  min-width: 110px !important;
  height: 35px !important;
  line-height: unset !important;
  background-color: ${({ bgColor }) =>
    bgColor ? `${bgColor} !important` : "#54B4D3 !important"};
  color: #fff !important;

  .MuiIcon-root {
    height: auto;
  }
`;

const ImageWrapper = styled.div`
  width: 300px;
  height: 300px;
  img {
    width: 100%;
    height: 100%;
    transition: all 0.2s;
    object-fit: contain;
    :hover {
      //transform: scale(3);
      cursor: zoom-in;
    }
  }
`;
const ModalContainer = styled.div`
  width: 60%;
  /* max-height: 90vh;
  overflow-y: scroll; */
`;
const CustomModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export interface AgentUpdate {
  fullname?: string;
  email?: string;
  phone?: string;
  country?: string;
  main_channel?: string;
  communication_channel?: string;
  currently_job?: string;
  sale_experience?: string;
  sales_volume?: string;
  areas_of_bussiness?: string;
  address_detail?: string;
  outline_reason?: string;
  as_an_agent?: string;
  what_do_you_think?: string;
  provider?: string;
}

function Content() {
  const authCtx = useContext(AuthContext);
  const communityCtx = useContext(CommunityContext);
  const { role } = authCtx;
  const {
    node,
    post,
    setPost,
    setIsOpenConfirmModal,
    typeSubmit,
    setTypeSubmit,
  } = communityCtx;

  const [imgPopup, setImgPopup] = useState<string | null>(null);

  const handleDelete = () => {
    setTypeSubmit(TypePost.Delete);
    setIsOpenConfirmModal(true);
  };

  return (
    <Container>
      <Wrapper>
        <Row isContainer>
          <Column style={{ minWidth: "30%" }}>
            {/* <GroupTitleStyle>General Information</GroupTitleStyle> */}
            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Content</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>{post?.body ? post?.body : "-"}</TextStyle>
              </WrapText>
            </Row>
            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Images</TitleStyle>
              </WrapText>
              <WrapImage>
                {post?.media_url.length ? (
                  post?.media_url.map((url) => (
                    <ImageWrapper>
                      <img src={url} alt="" onClick={() => setImgPopup(url)} />
                    </ImageWrapper>
                  ))
                ) : (
                  <TextStyle>{"-"}</TextStyle>
                )}
              </WrapImage>
            </Row>
            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Likes</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>
                  {post?.like_count ? post?.like_count : "-"}
                </TextStyle>
              </WrapText>
            </Row>
            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Comments</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>
                  {post?.comment_count ? post?.comment_count : "-"}
                </TextStyle>
              </WrapText>
            </Row>
            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Posted At</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>
                  {post?.createdAt
                    ? moment(post?.createdAt).format("YYYY MMM DD hh:mm")
                    : "-"}
                </TextStyle>
              </WrapText>
            </Row>
          </Column>
        </Row>
        <Row style={{ justifyContent: "center" }}>
          <CustomButton onClick={handleDelete} bgColor="#DC4C64">
            Delete
          </CustomButton>
        </Row>
      </Wrapper>

      {imgPopup && (
        <CustomModal
          open={imgPopup ? true : false}
          onClose={() => setImgPopup(null)}
        >
          <img style={{ maxWidth: "60vw" }} src={imgPopup} alt="" />
        </CustomModal>
      )}
    </Container>
  );
}

export default Content;
