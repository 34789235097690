"use strict";
import React, {
  useCallback,
  useEffect,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";

import { AgGridReact } from "@ag-grid-community/react";
import {
  ColDef,
  FirstDataRenderedEvent,
  PaginationNumberFormatterParams,
} from "@ag-grid-community/core";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import styled from "styled-components";
import { Order } from "../../../../constants/interface";

import { convertDateToYYYYMMDD_HHmm } from "../../../../utils";
import Api from "../../../../service";
import ActionsCell from "./ActionsCell";
import OrderContext from "../../context/order-context";
import { useSearchParams } from "react-router-dom";
import { OrderStatusType, SummaryKeys } from "../../constants";

export const TableContainer = styled.div`
  width: 100%;
  height: 75vh;
  /* box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.2); */
  border-radius: 10px;
  position: relative;
`;

export const PageSizeContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: -50px;
`;

// Register the required feature modules with the Grid
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const OrderTable = () => {
  const orderCtx = useContext(OrderContext);
  const { isReloadList } = orderCtx;
  let [searchParams] = useSearchParams();
  const currentTab = searchParams.get("tab") || "All";

  const cellStyle = {
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
  };
  const TotalStyle = {
    fontWeight: "700",
    display: "flex",
    alignItems: "center",
  };

  const gridRef = useRef<AgGridReact<Order>>(null);
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(
    () => ({ height: "100%", width: "100%", padding: "0 20px" }),
    []
  );
  const [rowData, setRowData] = useState<Order[]>();
  const [columnDefs] = useState<ColDef[]>([
    {
      field: "orders_code",
      headerName: "Order Code",
      cellStyle: cellStyle,
      filter: true,
    },
    {
      field: "customer_name",
      headerName: "Customer",
      cellStyle: cellStyle,
    },
    {
      field: "agentName",
      headerName: "Agent",
      cellStyle: cellStyle,
    },
    {
      field: "status",
      headerName: "Status",
      cellStyle: cellStyle,
    },
    {
      field: "shipment_amount",
      headerName: "Shipping Fee($)",
      cellStyle: cellStyle,
    },
    {
      field: "final_amount",
      headerName: "Total($)",
      cellStyle: TotalStyle,
    },
    {
      field: "createdAt",
      cellStyle: cellStyle,
      filter: false,
      floatingFilter: false,
      // resizable: false,
    },
    {
      colId: "action",
      headerName: "",
      floatingFilter: false,
      cellRenderer: ActionsCell,
      filter: false,
      resizable: false,
      // suppressSizeToFit: true,
      // minWidth: 300,
      width: currentTab === SummaryKeys.RequestCancel ? 300 : 200,
    },
  ]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      editable: true,
      // enableRowGroup: true,

      sortable: true,
      resizable: true,
      // filter: true,
      // floatingFilter: true,
      flex: 1,
      // minWidth: 100,
    };
  }, []);

  const gridOptions = {
    suppressRowTransform: true,
    enableRangeSelection: true,
    copyHeadersToClipboard: true,
  };

  const paginationNumberFormatter = useCallback(
    (params: PaginationNumberFormatterParams) => {
      return "[" + params.value.toLocaleString() + "]";
    },
    []
  );

  const getFilteredOrders = (orders: Order[]) => {
    let filteredOrders: Order[];

    switch (currentTab) {
      case SummaryKeys.All:
        filteredOrders = orders;
        break;
      case SummaryKeys.InProgress:
        filteredOrders = orders.filter(
          (order: Order) => order.status === OrderStatusType.InProgress
        );
        break;
      case SummaryKeys.Confirmed:
        filteredOrders = orders.filter(
          (order: Order) => order.status === OrderStatusType.Confirmed
        );
        break;
      case SummaryKeys.Packing:
        filteredOrders = orders.filter(
          (order: Order) => order.status === OrderStatusType.Packing
        );
        break;
      case SummaryKeys.Shipping:
        filteredOrders = orders.filter(
          (order: Order) => order.status === OrderStatusType.Shipping
        );
        break;
      case SummaryKeys.Canceled:
        filteredOrders = orders.filter(
          (order: Order) => order.status === OrderStatusType.Canceled
        );
        break;
      case SummaryKeys.RequestCancel:
        filteredOrders = orders.filter(
          (order: Order) => order.status === OrderStatusType.RequestCancel
        );
        break;
      case SummaryKeys.RejectRequestCancel:
        filteredOrders = orders.filter(
          (order: Order) => order.status === OrderStatusType.RejectRequestCancel
        );
        break;
      case SummaryKeys.Delivered:
        filteredOrders = orders.filter(
          (order: Order) => order.status === OrderStatusType.Delivered
        );
        break;
      case SummaryKeys.Refunded:
        filteredOrders = orders.filter(
          (order: Order) => order.status === OrderStatusType.Refunded
        );
        break;
      default:
        filteredOrders = orders;
        break;
    }

    return filteredOrders;
  };

  const onGridReady = useCallback(async () => {
    let response = await Api.Order.getOrders();
    const orders: Order[] = response.data;
    const filteredOrders = getFilteredOrders(orders);

    const formattedDateOrders = filteredOrders?.map((order: Order) => {
      return {
        ...order,
        agentName: order?.user_agent?.fullname || "None",
        total: order?.quantity * order?.price - order?.discount || 0,
        createdAt: `${convertDateToYYYYMMDD_HHmm(
          order?.createdAt || new Date()
        )}`,
      };
    });
    setRowData(formattedDateOrders);
  }, [currentTab]);

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    gridRef.current!.api.paginationGoToPage(0);
    gridRef.current!.api.paginationSetPageSize(20);
    gridRef?.current?.api.sizeColumnsToFit({
      defaultMinWidth: 100,
      columnLimits: [
        { key: "orders_code", maxWidth: 150 },
        { key: "shipment_amount", maxWidth: 150 },
        { key: "final_amount", maxWidth: 150 },
        { key: "createdAt", maxWidth: 150 },
        // { key: "action", minWidth: 300 },
      ],
    });
  }, []);
  const changeSizeBaseOnTab = useCallback(() => {
    if (currentTab === SummaryKeys.RequestCancel) {
      gridRef.current!.columnApi?.autoSizeColumns(["action"], true);
      gridRef?.current?.api?.sizeColumnsToFit({
        columnLimits: [{ key: "action", minWidth: 300 }],
      });
    } else {
      gridRef.current!.columnApi?.autoSizeColumns(["action"], true);
      gridRef?.current?.api?.sizeColumnsToFit({
        columnLimits: [{ key: "action", minWidth: 200, maxWidth: 200 }],
      });
    }
  }, [currentTab]);

  useEffect(() => {
    onGridReady();
  }, [isReloadList, onGridReady]);

  useEffect(() => {
    changeSizeBaseOnTab();
  }, [currentTab]);

  const onPageSizeChanged = useCallback(() => {
    var value = (document.getElementById("page-size") as HTMLInputElement)
      .value;
    gridRef.current!.api.paginationSetPageSize(Number(value));
  }, []);

  return (
    <TableContainer>
      <div style={containerStyle}>
        <div
          className="example-wrapper"
          style={{ width: "100%", height: "100%", position: "relative" }}
        >
          <div style={gridStyle} className="ag-theme-alpine">
            <AgGridReact<Order>
              ref={gridRef}
              rowData={rowData}
              getRowHeight={() => 60}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              pagination={true}
              animateRows={true}
              paginationPageSize={20}
              gridOptions={gridOptions}
              paginationNumberFormatter={paginationNumberFormatter}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
          </div>

          <PageSizeContainer className="example-header">
            Page Size:{" "}
            <select onChange={onPageSizeChanged} id="page-size">
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="500">500</option>
            </select>
          </PageSizeContainer>
        </div>
      </div>
    </TableContainer>
  );
};
export default React.memo(OrderTable);
