import { TabList } from "@mui/lab";
import TabPanel from "@mui/lab/TabPanel";
import { Modal, Tab } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import styled from "styled-components";
import TabContext from "@mui/lab/TabContext";
import Cart from "./Cart";
import CreateForm from "./CreateForm";
import TrackingStatusV2 from "./tracking-status/TrackingStatusV2";
import { useSearchParams } from "react-router-dom";
import { SummaryKeys } from "../../constants";
import SupportRequestForm from "./SupportRequestForm";

interface IProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const OrderMenu = ({ isOpen, setIsOpen }: IProps) => {
  const [searchParams] = useSearchParams();
  const currentTab = searchParams.get("tab");
  const [value, setValue] = useState(
    currentTab === "SupportRequestCancel" ? "0" : "1"
  );

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <CustomModal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        sx={{
          width: "70vw",
          maxHeight: "80vh",
          height: "80vh",
          bgcolor: "#fff",
        }}
      >
        <Container>
          <TabContext value={value}>
            <Box sx={{ width: "100%", paddingTop: "2rem" }}>
              <TabList value={value} onChange={handleChange} centered>
                {currentTab === SummaryKeys.SupportRequestCancel && (
                  <CustomTab label="Support Request" value="0" />
                )}
                <CustomTab label="Information" value="1" />
                <CustomTab label="Order Detail" value="2" />
                <CustomTab label="Tracking Status" value="3" />
              </TabList>
            </Box>

            <CustomPanel value="0">
              <SupportRequestForm />
            </CustomPanel>
            <CustomPanel value="1">
              <CreateForm />
            </CustomPanel>
            <CustomPanel value="2">
              <TabPanelContainer>
                <Cart />
              </TabPanelContainer>
            </CustomPanel>
            <CustomPanel value="3">
              <TabPanelContainer>
                {/* <TrackingStatus /> */}
                <TrackingStatusV2 />
              </TabPanelContainer>
            </CustomPanel>
          </TabContext>
        </Container>
      </Box>
    </CustomModal>
  );
};

export default OrderMenu;
const TabPanelContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: auto;
`;
const CustomTab = styled(Tab)`
  font-size: 1.2rem !important;
`;
const CustomPanel = styled(TabPanel)`
  width: 100%;
  max-height: 90%;
  flex-grow: 1;
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  /* padding: 2rem 4rem; */
  h1 {
    font-size: 3rem;
    font-weight: bold;
    color: rgb(115, 131, 149);
    font-family: "Roboto";
  }
`;

const CustomModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
