import React from "react";
import useMaterialsLibraryVideo from "./MaterialsLibraryVideo.hooks";
import useMaterialsLibrary from "../../MaterialsLibrary.hooks";
import LoadingPage from "../../../../components/LoadingPage";
import MaterialsTable from "../../components/MaterialsTable";
import { MaterialType } from "../../../../constants/types";

const MaterialsLibraryVideo = () => {
  const { isReloadList } = useMaterialsLibrary();
  const { videosData, isLoading } = useMaterialsLibraryVideo({
    refresh: isReloadList,
  });

  if (isLoading) return <LoadingPage />;
  if (!videosData) return null;

  return <MaterialsTable data={videosData} type={MaterialType.VIDEO} />;
};

export default MaterialsLibraryVideo;
