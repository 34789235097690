import { ICellRendererParams } from "ag-grid-community";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import OrderContext from "../../context/order-context";
import { useSearchParams } from "react-router-dom";
import {
  ActionsCellContainer,
  ActionsWrap,
  ActionsWrapper,
} from "../table/ActionsCell";
import ButtonCRUD, {
  ButtonDisplayType,
  CRUDType,
} from "../../../../components/buttonCRUD";
import ConfirmModal from "../../../../components/modal/ConfirmModal";
import { toast } from "react-toastify";
import Api from "../../../../service";

const ActionsCell = (props: ICellRendererParams) => {
  const data = props.data;
  const orderCtx = useContext(OrderContext);
  const {
    setIsOpenModal,
    setNode,
    setOrder,
    setSupportRequestCancelOrder,
    reloadList,
    onApproveCancel,
    onApproveSupportRequestCancelOrder,
    setModal,
    updateSupportRequestCancelOrder,
    onCloseModal,
  } = orderCtx;
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get("tab") || "All";
  const clickedBtn = searchParams.get("btn");

  const onClickEditBtn = async () => {
    searchParams.set("orderId", data.order._id);
    searchParams.set("supportRequestId", data._id);
    setSearchParams(searchParams);
    setIsOpenModal(true);
    setNode(props.node);
    setOrder(data.order);
    const supportRequestCancelOrder = { ...data };
    delete supportRequestCancelOrder.order;
    setSupportRequestCancelOrder(supportRequestCancelOrder);
  };

  const onClickBtn = async (type: CRUDType) => {
    searchParams.set("orderId", data.order._id);
    searchParams.set("supportRequestId", data._id);
    searchParams.set("btn", type);
    setSearchParams(searchParams);
    setOrder(data.order);
    const supportRequestCancelOrder = { ...data };
    delete supportRequestCancelOrder.order;
    setSupportRequestCancelOrder(supportRequestCancelOrder);
    setOpenModalConfirm(true);
  };
  const onConfirmReject = async () => {
    // @ts-ignore
    const reject = document.getElementById("input-modal-nvd")?.value;
    setSupportRequestCancelOrder((prev) => ({
      ...prev,
      admin_reject: reject,
    }));
    await updateSupportRequestCancelOrder({ admin_reject: reject });
    onCloseModal();
  };

  const onConfirm = async () => {
    setDisableBtn(true);
    switch (clickedBtn) {
      case CRUDType.Reject:
        setModal((prev) => ({
          isOpen: true,
          body: "Are you sure you want to reject this request?",
          label: "Reject Reason",
          inputText: "",
          onConfirm: onConfirmReject,
        }));
        setOpenModalConfirm(false);

        break;
      case CRUDType.Approve:
        await onApproveSupportRequestCancelOrder(data._id);
        setOpenModalConfirm(false);
        break;

      default:
        break;
    }
    setDisableBtn(false);
  };

  const renderStatus = () => {
    let status = clickedBtn as string;
    switch (clickedBtn) {
      case CRUDType.Reject:
        status = CRUDType.InProgress;
        break;
      case CRUDType.Approve:
        status = "CANCELED";
        break;
      default:
        break;
    }
    return status;
  };

  return (
    <ActionsCellContainer>
      <ActionsWrapper>
        <ActionsWrap onClick={onClickEditBtn}>
          <ButtonCRUD
            displayType={ButtonDisplayType.Text}
            type={CRUDType.View}
            size="medium"
          />
        </ActionsWrap>
        <ActionsWrap onClick={() => onClickBtn(CRUDType.Reject)}>
          <ButtonCRUD
            displayType={ButtonDisplayType.Text}
            type={CRUDType.Reject}
            size="medium"
          />
        </ActionsWrap>
        <ActionsWrap onClick={() => onClickBtn(CRUDType.Approve)}>
          <ButtonCRUD
            displayType={ButtonDisplayType.Text}
            type={CRUDType.Refunded}
            size="medium"
          />
        </ActionsWrap>
      </ActionsWrapper>

      <ConfirmModal
        isOpen={openModalConfirm}
        setIsOpen={setOpenModalConfirm}
        onConfirm={onConfirm}
        status={renderStatus()}
        disabled={disableBtn}
      />
    </ActionsCellContainer>
  );
};

export default ActionsCell;
