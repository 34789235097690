import { CircularProgress } from "@mui/material";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 9999;
`;

const LoadingPage = () => {
  return (
    <Container>
      <CircularProgress color="inherit" />
    </Container>
  );
};

export default LoadingPage;
