import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, TextField } from "@mui/material";
import React, { useState, useContext } from "react";
import styled from "styled-components";
import Profile from "./profile/Profile";
import UserContext from "../../../../context/agent-context";
import {
  AgentStatus,
  UserType,
  UserAgentIdentifyStatus,
} from "../../../../../../constants/types";
import { Button, Chip } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import AuthContext from "../../../../../../context/auth-context";
import { toast } from "react-toastify";
import Api from "../../../../../../service";
import Popover from "@mui/material/Popover";
import { Modal } from "@mui/material";
import RegistrationTracking from "./registrationTracking/RegistrationTracking";
import { Status } from "../../../../components/StatusField";

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-width: 1000px;
  min-height: 820px;
  background-color: #fff;
  padding: 20px;
`;
const Header = styled.div`
  padding: 8px 16px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 40px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const AvatarWrap = styled.div`
  min-width: 80px;
  min-height: 80px;
  max-width: 80px;
  max-height: 80px;
`;

const Avatar = styled.img`
  object-fit: cover;
  width: 100%;
  max-height: 80px;
  border-radius: 50%;
`;

const TextStyle = styled.span`
  font-size: 22px;
  font-weight: 600;
  font-family: "Quicksand";
  font-style: normal;
  text-transform: capitalize;
  color: #222222;
`;

const TitleStyle = styled(TextStyle)`
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
  color: gray;
`;

const FullName = styled(TitleStyle)`
  font-size: 26px;
  font-weight: 700;
  color: #222222;
`;

const Email = styled(TextStyle)`
  font-weight: 600;
  font-size: 16px;
  text-transform: none;
  color: #0b7bf4;
  text-decoration: underline;
`;

const StatusColor = styled(TextStyle)<{ color: string }>`
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  color: ${({ color }) => (color ? color : "#0b7bf4")};
`;

const SubmittedStyle = styled(StatusColor)`
  text-transform: uppercase;
`;

const WrapButton = styled.div`
  display: flex;
  gap: 24px;
  @media (max-width: 1600px) {
    gap: 12px;
  }
`;

const CustomButton = styled(LoadingButton)<{ bgColor: string; width?: number }>`
  display: inline-block !important;
  vertical-align: middle !important;
  /* padding: 0 !important; */
  padding: 0 24px !important;
  margin: 0 !important;
  width: ${({ width }) => (width ? `${width}px` : "auto")};
  min-width: 35px !important;
  height: 35px !important;
  line-height: unset !important;
  background-color: ${({ bgColor }) =>
    bgColor ? `${bgColor} !important` : "#54B4D3 !important"};
  color: #fff !important;

  .MuiIcon-root {
    height: auto;
  }
`;

export const Type = styled.div`
  padding: 4px 8px;
  background: rgba(14, 203, 129, 0.1);
  border-radius: 3px;

  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Dot = styled.span`
  background: #0ecb81;
  border-radius: 50%;
  min-width: 8px;
  width: 8px;
  height: 8px;
`;

export const TypeText = styled.span`
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0ecb81;
  text-transform: capitalize;
`;

const CustomTabContext = styled(TabContext)`
  width: 100%;
  height: 100%;
  background-color: #fff;
`;
const CustomTabPanel = styled(TabPanel)<{ maxHeight?: string }>`
  width: 100%;
  background-color: #fff;
  padding: 24px 0 !important;
  height: 100%;
  max-height: calc(100% - 200px);
  ${({ maxHeight }) => maxHeight && { maxHeight: maxHeight }}
`;

const CustomTab = styled(Tab)`
  font-size: 18px !important;
  font-weight: 700 !important;
  font-family: "Quicksand" !important;
  font-style: normal !important;
  text-transform: capitalize !important;
  color: #222222 !important;
`;

const LineColumn = styled.span`
  display: block;
  height: 100px;
  width: 2px;
  background-color: gray;
`;

const ConfirmChangeStatus = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 16px;
  color: #222222;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const ConfirmHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const ConfirmTitle = styled.span`
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
`;

const ConfirmButton = styled.div<{ background: string }>`
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  background: ${({ background }) =>
    background ? background : "rgb(36, 107, 212)"};
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
`;

const CustomModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  width: 30%;
  height: 20vh;
  max-height: 20vh;
  overflow-y: hidden;
`;

enum TypePost {
  Approve = "APPROVE",
  ApproveIdentify = "APPROVE_IDENTIFY",
  CancelApprove = "CANCEL_APPROVE",
  CancelSubmit = "CANCEL_SUBMIT",
  RejectIdentify = "REJECT_IDENTIFY",
}

function Detail() {
  const authCtx = useContext(AuthContext);
  const { role } = authCtx;
  const userCtx = useContext(UserContext);
  const {
    node,
    agentPending,
    setAgentPending,
    isReloadList,
    setIsReloadList,
    setIsOpenDetailModal,
  } = userCtx;
  const [tab, setTab] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [isCheckError, setIsCheckError] = useState<boolean>(false);
  const [reason, setReason] = useState<string>("");
  const [typeSubmit, setTypeSubmit] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleChange = (e: any, value: number) => {
    setTab(value);
  };

  const handleUpdateProfile = async () => {
    if (!typeSubmit || !agentPending?._id) {
      toast.error("Cannot update !");
      return;
    }

    if (
      (typeSubmit === TypePost.CancelApprove ||
        typeSubmit === TypePost.CancelSubmit ||
        typeSubmit === TypePost.RejectIdentify) &&
      !reason
    ) {
      setIsCheckError(true);
      return;
    }

    try {
      setLoading(true);

      const dataToCancel = {
        reason: reason,
      };

      const response =
        typeSubmit === TypePost.Approve
          ? await Api.Agent.approveProfile(agentPending?._id)
          : typeSubmit === TypePost.ApproveIdentify
          ? await Api.Agent.approveIdentify(agentPending?._id)
          : typeSubmit === TypePost.RejectIdentify
          ? await Api.Agent.rejectIdentify(agentPending?._id, dataToCancel)
          : typeSubmit === TypePost.CancelApprove
          ? await Api.Agent.cancelApproveProfile(
              agentPending?._id,
              dataToCancel
            )
          : await Api.Agent.cancelSubmitProfile(
              agentPending?._id,
              dataToCancel
            );
      if (response) {
        setAgentPending(response.data);
        node.setData({
          ...agentPending,
          status: response?.data?.status || AgentStatus.Pending,
          updated: response?.data?.updated,
        });
        toast.success(
          typeSubmit === TypePost.Approve ||
            typeSubmit === TypePost.ApproveIdentify
            ? "Approve success !"
            : typeSubmit === TypePost.RejectIdentify
            ? "Reject success !"
            : "Cancel success !"
        );
        setReason("");
        setIsCheckError(false);
        handleCloseModal();
        setIsReloadList(!isReloadList);
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoading(false);
    }
  };

  //confirm change status popup
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleConfirmChangeStatus = (
    event: React.MouseEvent<HTMLButtonElement>,
    typeSubmit: string
  ) => {
    //setAnchorEl(event.currentTarget);
    setOpenModal(true);
    setTypeSubmit(typeSubmit);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsCheckError(false);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  //end confirm change status popup

  const handleCloseModal = () => {
    setOpenModal(false);
    setIsCheckError(false);
    setIsOpenDetailModal(false);
  };

  return (
    <Container>
      <Header>
        <Row>
          <Column>
            <Row>
              <AvatarWrap>
                <Avatar src={agentPending?.avatar} />
              </AvatarWrap>
              <Column>
                <FullName>{agentPending?.fullname}</FullName>
                <Row>
                  <TitleStyle>Email :</TitleStyle>
                  <Email>{agentPending?.email}</Email>
                </Row>
              </Column>
            </Row>
          </Column>
          <LineColumn />
          <Column>
            {agentPending?.status?.toUpperCase() === Status.Pending &&
            agentPending?.updated ? (
              <>
                <Row>
                  <TitleStyle>Status :</TitleStyle>
                  <StatusColor
                    color={
                      agentPending?.status === AgentStatus.Approved
                        ? "#2e7d32"
                        : agentPending?.status === AgentStatus.Pending
                        ? "#ed6c02"
                        : "#d32f2f"
                    }
                  >
                    {agentPending?.status}
                  </StatusColor>
                  <div>
                    {/* <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "center",
                  }}
                  anchorPosition={{
                    left: 20,
                    top: 20,
                  }}
                >
                  <ConfirmChangeStatus>
                    <ConfirmTitle>
                      {typeSubmit === TypePost.Approve
                        ? "Are you sure APPROVE?"
                        : "Are you sure CANCEL?"}
                    </ConfirmTitle>
                    {typeSubmit !== TypePost.Approve && (
                      <TextField
                        name="reason"
                        error={isCheckError && !reason}
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                        fullWidth
                        id="outlined"
                        size="small"
                        label="Reason"
                      />
                    )}

                    <CustomButton
                      loading={loading}
                      disabled={loading}
                      loadingPosition="start"
                      startIcon={<></>}
                      onClick={() => handleUpdateProfile()}
                      bgColor="rgb(36, 107, 212)"
                    >
                      Confirm
                    </CustomButton>
                  </ConfirmChangeStatus>
                </Popover> */}
                    {agentPending?.status === AgentStatus.Pending && (
                      <CustomButton
                        onClick={(e) =>
                          handleConfirmChangeStatus(e, TypePost.Approve)
                        }
                        bgColor="#2e7d32"
                      >
                        Approve
                      </CustomButton>
                    )}
                    {agentPending?.status === AgentStatus.Approved && (
                      <CustomButton
                        onClick={(e) =>
                          handleConfirmChangeStatus(e, TypePost.CancelApprove)
                        }
                        bgColor="gray"
                      >
                        Cancel
                      </CustomButton>
                    )}
                  </div>
                </Row>
                <Row>
                  <TitleStyle>Submitted Registration :</TitleStyle>
                  <SubmittedStyle
                    color={agentPending?.updated ? "#2e7d32" : "#ed6c02"}
                  >
                    {agentPending?.updated ? "YES" : "NO"}
                  </SubmittedStyle>
                  {agentPending?.status !== AgentStatus.Approved &&
                    agentPending?.updated && (
                      <CustomButton
                        onClick={(e) =>
                          handleConfirmChangeStatus(e, TypePost.CancelSubmit)
                        }
                        bgColor="gray"
                      >
                        Cancel
                      </CustomButton>
                    )}
                </Row>
              </>
            ) : (
              <Row>
                <TitleStyle>Submitted Identify :</TitleStyle>
                <StatusColor
                  color={
                    agentPending?.identifyStatus ===
                    UserAgentIdentifyStatus.Approved
                      ? "#2e7d32"
                      : agentPending?.identifyStatus ===
                        UserAgentIdentifyStatus.Pending
                      ? "#ed6c02"
                      : "#1268E9"
                  }
                >
                  {agentPending?.identifyStatus}
                </StatusColor>
                <div>
                  {agentPending?.identifyStatus ===
                    UserAgentIdentifyStatus.Submitted && (
                    <div style={{ display: "flex", gap: 16 }}>
                      <CustomButton
                        onClick={(e) =>
                          handleConfirmChangeStatus(e, TypePost.ApproveIdentify)
                        }
                        bgColor="#2e7d32"
                      >
                        Approve
                      </CustomButton>

                      <CustomButton
                        onClick={(e) =>
                          handleConfirmChangeStatus(e, TypePost.RejectIdentify)
                        }
                        bgColor="#DC143C"
                      >
                        reject
                      </CustomButton>
                    </div>
                  )}
                </div>
              </Row>
            )}
          </Column>
        </Row>
      </Header>
      <CustomTabContext value={tab.toString()}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <CustomTab label="Profile" value="1" />
            <CustomTab label="Registration Tracking" value="2" />
          </TabList>
        </Box>
        <CustomTabPanel value="1">
          <Profile />
        </CustomTabPanel>
        <CustomTabPanel value="2">
          <RegistrationTracking />
        </CustomTabPanel>
      </CustomTabContext>
      <CustomModal
        open={openModal}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContainer>
          <ConfirmChangeStatus>
            <ConfirmHeader>
              <ConfirmTitle>
                {typeSubmit === TypePost.Approve ||
                typeSubmit === TypePost.ApproveIdentify
                  ? "Are you sure APPROVE?"
                  : typeSubmit === TypePost.RejectIdentify
                  ? "Are you sure REJECT?"
                  : "Are you sure CANCEL?"}
              </ConfirmTitle>
              {typeSubmit !== TypePost.Approve &&
                typeSubmit !== TypePost.ApproveIdentify && (
                  <TextField
                    name="reason"
                    error={isCheckError && !reason}
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    fullWidth
                    id="outlined"
                    size="small"
                    label="Reason"
                  />
                )}
            </ConfirmHeader>

            <CustomButton
              loading={loading}
              disabled={loading}
              loadingPosition="start"
              startIcon={<></>}
              onClick={() => handleUpdateProfile()}
              bgColor="rgb(36, 107, 212)"
            >
              Confirm
            </CustomButton>
          </ConfirmChangeStatus>
        </ModalContainer>
      </CustomModal>
    </Container>
  );
}

export default Detail;
