import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "@ag-grid-community/react";
import {
  ColDef,
  FirstDataRenderedEvent,
  PaginationNumberFormatterParams,
} from "@ag-grid-community/core";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import styled from "styled-components";

import { convertNumberToDateString } from "../../../utils";
import { IProductKey } from "../constants";
import Api from "../../../service";
import CreateCodeModal from "./modal/CreateCodeModal";
import useProductKey from "../ProductKey.hooks";
import FakeInputCodeModal from "./modal/FakeInputCodeModal";
import ActionsCell from "./cells/ActionsCell";
import ApplicationNameRender from "./cells/ApplicationNameRender";
import ProductKeyConfirmModal from "./modal/ConfirmModal";
import StatusCell from "./cells/StatusCell";
import { useModal } from "../../../hooks/use-modal";
import { ModalKey } from "../../../context/modal-context";

// Register the required feature modules with the Grid
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const ProductKeysTable = () => {
  const { isReloadList, setProductKey } = useProductKey();
  const { openModal } = useModal();

  const cellStyle = {
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
  };

  const gridRef = useRef<AgGridReact<IProductKey>>(null);
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState<IProductKey[]>();
  const [columnDefs] = useState<ColDef[]>([
    {
      field: "code",
      headerName: "Code",
      editable: true,
    },
    {
      field: "device_id",
      headerName: "Device Id",
    },
    {
      field: "application",
      headerName: "Application",
      cellRenderer: ApplicationNameRender,
    },
    {
      field: "activation_date",
      headerName: "Activation Date",
      cellStyle: cellStyle,
      filter: false,
      floatingFilter: false,
    },
    {
      field: "expiration_date",
      headerName: "Expiration Date",
      filter: false,
      floatingFilter: false,
    },
    {
      field: "deactivation_date",
      headerName: "Deactivation Date",
      filter: false,
      floatingFilter: false,
    },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: StatusCell,
      filter: false,
      floatingFilter: false,
    },
    {
      colId: "actions",
      cellRenderer: ActionsCell,
      filter: false,
    },
  ]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      // editable: true,
      // enableRowGroup: true,

      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      // flex: 1,
      minWidth: 100,
    };
  }, []);
  const paginationNumberFormatter = useCallback(
    (params: PaginationNumberFormatterParams) => {
      return "[" + params.value.toLocaleString() + "]";
    },
    []
  );

  const onGridReady = useCallback(() => {
    Api.ProductKey.getProductKeys().then((productKeys: any) => {
      const formattedDateIProductKeys = productKeys?.data.map(
        (productKey: IProductKey) => {
          return {
            ...productKey,
            device_id: productKey?.device_id ?? "-",
            activation_date: productKey?.activation_date
              ? convertNumberToDateString(productKey.activation_date)
              : "-",
            expiration_date: productKey?.expiration_date
              ? convertNumberToDateString(productKey.expiration_date)
              : "-",
            deactivation_date: productKey?.deactivate_date
              ? convertNumberToDateString(productKey.deactivate_date)
              : "-",
          };
        }
      );
      setRowData(formattedDateIProductKeys);
      gridRef?.current?.api.sizeColumnsToFit({
        columnLimits: [{ key: "action", maxWidth: 200 }],
      });
    });
  }, []);

  useEffect(() => {
    onGridReady();
  }, [isReloadList, onGridReady]);

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    gridRef.current!.api.paginationGoToPage(0);
    gridRef.current!.api.paginationSetPageSize(20);
  }, []);

  const onPageSizeChanged = useCallback(() => {
    var value = (document.getElementById("page-size") as HTMLInputElement)
      .value;
    gridRef.current!.api.paginationSetPageSize(Number(value));
  }, []);

  return (
    <Container>
      <ButtonWrap>
        <FakeButton onClick={() => openModal(ModalKey.PRODUCT_KEY_FAKE_INPUT)}>
          <ButtonText>Fake Active Code</ButtonText>
        </FakeButton>
        <CreateButton onClick={() => openModal(ModalKey.PRODUCT_KEY_CREATE)}>
          <ButtonText>Create Code</ButtonText>
        </CreateButton>
      </ButtonWrap>

      <div style={containerStyle}>
        <div
          className="example-wrapper"
          style={{ width: "100%", height: "100%", position: "relative" }}
        >
          <div style={gridStyle} className="ag-theme-alpine">
            <AgGridReact<IProductKey>
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows={true}
              pivotPanelShow={"always"}
              pagination={true}
              paginationPageSize={10}
              paginationNumberFormatter={paginationNumberFormatter}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
              onCellClicked={(params) => {
                setProductKey(params.data as IProductKey);
              }}
            ></AgGridReact>
          </div>

          <PageSizeContainer className="example-header">
            Page Size:{" "}
            <select onChange={onPageSizeChanged} id="page-size">
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="500">500</option>
            </select>
          </PageSizeContainer>
        </div>
      </div>
      <CreateCodeModal />
      <FakeInputCodeModal />
      <ProductKeyConfirmModal />
    </Container>
  );
};
export default React.memo(ProductKeysTable);

const Container = styled.div`
  width: 100%;
  height: 75vh;
  border-radius: 10px;
  position: relative;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;
  gap: 1rem;
`;

const CreateButton = styled.div`
  padding: 8px 24px;
  background: #00a6ff;
  color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  :hover {
    filter: brightness(1.1);
  }
`;
const FakeButton = styled(CreateButton)`
  background-color: #587700 !important;
`;

const ButtonText = styled.span`
  font-size: 18px;
`;
const PageSizeContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: -50px;
`;
