"use strict";

import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "@ag-grid-community/react";
import {
  ColDef,
  FirstDataRenderedEvent,
  GridReadyEvent,
  PaginationNumberFormatterParams,
} from "@ag-grid-community/core";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import styled from "styled-components";
import { WithdrawHistoryI } from "../../../constants/interface";
import Detail from "./detail/Detail";

import { convertDateToYYYYMMDD_HHmm } from "../../../utils";
import Api from "../../../service";
import { Modal } from "@mui/material";
import ActionsCell from "./ActionsCell";
import WithdrawContext from "../context/withdraw-context";
import StatusField from "../../user-agent/components/StatusField";

const Container = styled.div`
  width: 100%;
  height: 75vh;
  /* box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.2); */
  border-radius: 10px;
  position: relative;
  .ag-row {
    cursor: pointer !important;
  }
`;
const PaginationContainer = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: center;
`;
const NumberOfDaoWithSameLevelContainer = styled.div`
  margin-top: 50px;
  position: absolute;
  right: 0;
  label {
    font-size: 18px;
    font-style: italic;
    span {
      font-weight: 700;
    }
  }
`;
const ModalContainer = styled.div`
  width: 70%;
  overflow-y: auto;
`;

const CustomModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PageSizeContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: -50px;
`;

// Register the required feature modules with the Grid
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const ListWithdraw = () => {
  const { isOpenDetailModal, setIsOpenDetailModal, withdraw } =
    useContext(WithdrawContext);

  const gridRef = useRef<AgGridReact<WithdrawHistoryI>>(null);
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState<WithdrawHistoryI[]>();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    { field: "agent.fullname", headerName: "Name" },
    {
      field: "withdraw_type",
      headerName: "Withdraw Type",
      cellStyle: { "font-weight": "700" },
      filter: false,
    },
    {
      field: "withdraw_account",
      headerName: "Withdraw Account",
    },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: StatusField,
      filter: false,
    },
    {
      field: "amount",
      headerName: "Amount",
      cellStyle: { "font-weight": "700" },
      filter: false,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      cellStyle: { "font-weight": "700" },
      filter: false,
    },
    {
      colId: "action",
      headerName: "",
      cellRenderer: ActionsCell,
      floatingFilter: false,
      filter: false,
      resizable: false,
    },
  ]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      // editable: true,
      // enableRowGroup: true,

      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      // flex: 1,
      minWidth: 100,
    };
  }, []);
  const paginationNumberFormatter = useCallback(
    (params: PaginationNumberFormatterParams) => {
      return "[" + params.value.toLocaleString() + "]";
    },
    []
  );

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      Api.Admin.getWithdrawHistories().then((listWithdrawHistories: any) => {
        const formattedDateList = listWithdrawHistories.data.map(
          (withdraw: WithdrawHistoryI) => {
            return {
              ...withdraw,
              createdAt: `${convertDateToYYYYMMDD_HHmm(withdraw.createdAt)}`,
            };
          }
        );
        setRowData(formattedDateList);
        gridRef?.current?.api.sizeColumnsToFit({
          defaultMinWidth: 50,
          columnLimits: [
            { key: "avatar", maxWidth: 100 },
            { key: "agent.fullname", minWidth: 100 },
            { key: "action", maxWidth: 150 },
          ],
        });
      });
    },
    [withdraw]
  );

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    gridRef.current!.api.paginationGoToPage(0);
    gridRef.current!.api.paginationSetPageSize(20);
  }, []);

  const onPageSizeChanged = useCallback(() => {
    var value = (document.getElementById("page-size") as HTMLInputElement)
      .value;
    gridRef.current!.api.paginationSetPageSize(Number(value));
  }, []);

  return (
    <Container>
      <div style={containerStyle}>
        <div
          className="example-wrapper"
          style={{ width: "100%", height: "100%", position: "relative" }}
        >
          <div style={gridStyle} className="ag-theme-alpine">
            <AgGridReact<WithdrawHistoryI>
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows={true}
              pivotPanelShow={"always"}
              pagination={true}
              paginationPageSize={10}
              paginationNumberFormatter={paginationNumberFormatter}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
          </div>

          <PageSizeContainer className="example-header">
            Page Size:{" "}
            <select onChange={onPageSizeChanged} id="page-size">
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="500">500</option>
            </select>
          </PageSizeContainer>
        </div>
      </div>

      {isOpenDetailModal && (
        <CustomModal
          open={isOpenDetailModal}
          onClose={() => setIsOpenDetailModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModalContainer>
            <Detail />
          </ModalContainer>
        </CustomModal>
      )}
    </Container>
  );
};
export default ListWithdraw;
