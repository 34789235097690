import { MaterialType, VideoRatio } from "./types";

export interface SelectCountry {
  id: string;
  name: string;
  phoneCode: string;
  emoji: string;
  _id: string;
}
export interface Country {
  id: number;
  name: string;
  iso2: string;
  numeric_code: string;
  phone_code: string;
  capital: string;
  currency_name: string;
  currency: string;
  emoji: string;
}

export interface IMaterialCollection {
  title: string;
  description: string;
  createdAt?: string;
  _id?: string;

}
export interface IMaterial {
  type: MaterialType;
  url?: string;
  title: string;
  size: number;
  _id?: string;
  collection_id?: string;
  materials_collection?: string;
  thumbnail?: string;
  tags?: string;
  otherContent?: string;
  likes?: number;
  views?: number;
  copies?: number;
  download?: number;
  ratio?: VideoRatio;
}
export interface IModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface Member {
  memberAddress: string;
  name: string;
  timestamp: number;
  amount: number;
  idDao: number;
}

export interface Dao {
  _id: string;
  idDao: number;
  level: number;
  name: string;
  owner: string;
  createdAt: string;
  amount: number;
  buyToken: string;
  description: string;
  refCode: string;
  totalMembers: number;
}

export interface Notice {
  _id: string;
  idDao: number;
  title: string;
  body: string;
  type: string;
  deleted: boolean;
  createdAt: string;
  createdDate: number;
  updatedAt: string;
}

export interface WithdrawHistory {
  _id: string;
  txHash: string;
  from: string;
  amount: number;
  createdDate: number;
}

export interface Login {
  username: string;
  password: string;
}

interface ProfileLogin {
  name: string;
}
export interface LoginResponse {
  profile: ProfileLogin;
  accessToken: string;
}

export interface User {
  _id: string;
  avatar?: string;
  email?: string;
  point?: number;
  fullname?: string;
  appleId?: string;
  googleId?: string;
  refCode?: string;
  status?: string;
  type?: string;
  lang?: string;
  provider?: string;
  createdAt?: Date;
}

export interface Channel {
  channel: string;
  link: string;
}

export interface WithdrawHistoryI {
  _id?: string;
  amount?: number;
  note?: string;
  status?: string;
  isDeleted?: boolean;
  agent?: Agent;
  withdraw_type?: string;
  withdraw_account?: string;
  WI?: string;
  transactionId?: string;
  createdAt?: Date;
  updatedAt?: Date;
}
export enum Gender {
  Male = "MALE",
  Female = "FEMALE",
  Others = "OTHERS",
}

export interface Agent {
  _id: string;
  fullname: string;
  email: string;
  status: string;
  identifyStatus: string;
  ref_code: string;
  verified: boolean;
  gender: Gender;
  phone: string;
  dob: string;
  country: string;
  country_id?: string;
  state: string;
  state_id?: string;
  city: string;
  city_id?: string;
  zip_code: string;
  id_card: string;
  main_channel: Channel[];
  communication_channel: Channel[];
  currently_job: string;
  currently_job_custom: string;
  sale_experience: string;
  sales_volume: string;
  areas_of_bussiness: string;
  areas_of_bussiness_custom: string;
  about_yourself: string;
  updated: boolean;
  address_detail: string;
  outline_reason: string;
  as_an_agent: string;
  what_do_you_think: string;
  image_card_identify_front: string;
  image_card_identify_back: string;
  image_selfie: string;
  provider: string;
  avatar: string;
  googleId: string;
  createdAt?: Date;
  updatedAt?: Date;
  level?: string;
  balance?: number;
}

export interface AgentPending {
  _id: string;
  fullname: string;
  email: string;
  status: string;
  identifyStatus: string;
  ref_code: string;
  verified: boolean;
  gender: string;
  phone: string;
  dob: string;
  country: string;
  zip_code: string;
  id_card: string;
  main_channel: Channel[];
  communication_channel: Channel[];
  currently_job: string;
  currently_job_custom: string;
  sale_experience: string;
  sales_volume: string;
  areas_of_bussiness: string;
  areas_of_bussiness_custom: string;
  about_yourself: string;
  updated: boolean;
  address_detail: string;
  outline_reason: string;
  as_an_agent: string;
  what_do_you_think: string;
  image_card_identify_front: string;
  image_card_identify_back: string;
  image_selfie: string;
  provider: string;
  avatar: string;
  googleId: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface AgentStatusHistory {
  _id: string;
  user_agent: string;
  createdAt?: Date;
  code: string;
  status: string;
  note: string;
  profile: any;
}

export interface Post {
  _id: string;
  body: string;
  comment_count: number;
  like_count: number;
  view_count: number;
  media_url: string[];
  user: User;
  status: string;
  createdAt?: Date;
  updatedAt: string;
}

export interface NewsfeedComment {
  _id: string;
  comment: string;
  like_count: number;
  newsfeed: string;
  parentId: string;
  status: string;
  liked: boolean;
  user: User;
  children: any[];
  createdAt?: Date;
  updatedAt: string;
}

export interface UserPointHistory {
  _id: string;
  beforeChangeAmount: number;
  changeAmount: number;
  afterChangeAmount: number;
  changeType: string;
  createdAt?: Date;
}

export interface Feedback {
  _id: string;
  rate?: number;
  comment?: string;
  createdAt?: Date;
  user?: User;
}

export interface Settings {
  key: string;
  value: string;
  type: string;
  description: string;
}
export interface Launch {
  _id: string;
  isCloseableIOS: boolean;
  isCloseableAndroid: boolean;
  isShowAndroid: boolean;
  isShowIOS: boolean;
  titleIOS: string;
  titleAndroid: string;
  bodyIOS: string;
  bodyAndroid: string;
  version: string;
  createdAt?: Date;
}

export interface Version {
  _id: string;
  name: string;
  createdAt?: Date;
}

export interface IContentNotification {
  lang: string;
  title: string;
  body: string;
}

export interface Notification {
  _id: string;
  scheduleAt: number;
  type: string;
  contents: IContentNotification[];
  isDeleted: boolean;
  createdAt?: Date;
}

export interface ILangMedia {
  lang: string;
  title: string;
  description: string;
  body: string;
}

export interface Media {
  _id: string;
  // title: string;
  // body: string;
  isShow: boolean;
  thumbnail: string;
  contents: ILangMedia[];
  isDeleted: boolean;
  createdAt?: Date;
}

export interface Order_User {
  _id: string;
  email: string;
  avatar: string;
  fullname: string;
}

export interface Order_User_Agent {
  _id: string;
  email: string;
  avatar: string;
  fullname: string;
  ref_code: string;
}
export interface ICountry {
  id: number;
  name: string;
  iso2: string;
}
export interface IState {
  id: number;
  name: string;
  state_code: string;
  country_code: string;
}
export interface IDistrict {
  name: string;
  id: number;
  state_code: string;
  country_code: string;
}
export interface ProductGiftDocument {
  product: ProductDocument;
  isDeleted: boolean;
  quantity: number;
  quantitySold: number;
}
export interface ProductDocument {
  name: string;
  code: string;
  description: string;
  images: string[];
  origin_price: number;
  sale_price: number;
  // gifts: ProductGiftDocument[];
}
export interface IOrderDetail {
  quantity: number;
  origin_price: number;
  sale_price: number;
  product: ProductDocument;
  gifts: ProductGiftDocument[];
  quantityGift: number;
}
export interface Order {
  _id: string;
  orders_code: string;
  phone_color: string;
  quantity: number;
  customer_name: string;
  customer_email: string;
  phone_number: string;
  receiver_phone_number: string;
  address: string;
  country: ICountry;
  state: IState;
  city: IDistrict;
  zip_code: string;
  company_name: string;
  apartment: string;
  payment_method: string;
  transaction_id: string;
  payment_status: string;
  status: string;
  price: number;
  discount: number;
  agent_commission: number;
  user_agent: Order_User_Agent;
  user: Order_User;
  final_amount: number;
  shipment_amount: number;
  discount_amount: number;
  total_amount: number;
  details: IOrderDetail[];
  createdAt?: any;
  cancel_reason: string;
}

export interface UpdateOrder {
  orderId: string;
  customer_name?: string;
  customer_email?: string;
  phone_number?: string;
  receiver_phone_number?: string;
  address?: string;
  zip_code?: string;
  company_name?: string;
  apartment?: string;
  country_code?: string;
  state_code?: string;
  city_id?: number;
}

export interface FeedbackSummary {
  total: number;
  rate1: number;
  rate2: number;
  rate3: number;
  rate4: number;
  rate5: number;
}

export interface Subscriber {
  _id: string;
  user_email: string;
  unsubscribe_code: string;
  type: string;
  createdAt?: Date;
}

export interface ILangEvent {
  lang: string;
  title: string;
  shortDescription: string;
  description: string;
  banner: string;
}

export interface Event {
  _id: string;
  thumbnail: string;
  isShow: boolean;
  isHot: boolean;
  isHotFrom: number;
  isHotTo: number;
  contents: ILangEvent[];
  isDeleted: boolean;
  createdAt?: Date;
}

export interface Popup {
  _id: string;
  thumbnail: string;
  thumbnailMobile: string;
  redirectLink: string;
  isShow: boolean;
  createdAt?: Date;
}
