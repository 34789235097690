import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { NavbarList } from "../../constants/navbar";
import { breakPoints } from "../../constants/device";
import { Link, useNavigate } from "react-router-dom";
import ExpandIcon from "./Icons/Expand";
import LogoIcon from "./Icons/Logo";
import LogoutIcon from "@mui/icons-material/Logout";
import { Icon } from "@mui/material";
import AuthContext from "../../context/auth-context";
import { useLocation } from "react-router-dom";
import Popover from "@mui/material/Popover";
import { AdminRole } from "../../constants/types";

const NavbarWrap = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  background-color: #fff;

  /* background: linear-gradient(180deg, #6e50fa 0%, rgb(95, 75, 139) 100%); */
  box-shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
  width: 250px;
  overflow-y: auto;
  height: 100vh;
  z-index: 999;
`;

const NavbarTop = styled.div`
  /* border-bottom: 0.5px solid rgb(133, 149, 171); */
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NavbarBottom = styled.div`
  border-top: 0.5px solid rgb(133, 149, 171, 0.2);
  min-height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProfileWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 16px;
`;

const IconWrap = styled(Icon)`
  cursor: pointer;
`;

const LogoutBtn = styled(LogoutIcon)`
  color: #738395;
  width: 100% !important;
  height: 100% !important;
`;

const UserName = styled.span`
  text-transform: capitalize;
  color: #738395;
  font-size: 22px;
`;

const NavbarMenu = styled.div`
  overflow: hidden auto;
  height: calc(100% - 170px);
`;

const NavbarMenuWrap = styled.div`
  margin: 16px 0;
`;

const MenuMobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.5s ease;
`;

const MenuMobileItemWrapper = styled.div<{ $isActive: boolean }>`
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 0;
  width: 100%;
  border-radius: 0.375rem;
  background-color: ${(p) =>
    p.$isActive ? "rgba(255, 255, 255, 0.2)" : "transparent"};
  cursor: pointer;
  :hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const Wrapper = styled.div`
  width: 100%;
  transition: all 0.3s ease;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BottomWrapper = styled.div<{ isExpand: boolean }>`
  transition: all 0.4s ease;
  height: ${(p) => (p.isExpand ? "auto" : "0")};
  min-height: ${(p) => (p.isExpand ? "75px" : "0")};
  opacity: ${(p) => (p.isExpand ? "1" : "0")};
  visibility: ${({ isExpand }) => (isExpand ? "visible" : "hidden")};
  overflow: hidden;
`;

const MenuItemText = styled.span<{ $isActive?: boolean }>`
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  padding: 10px 20px;
  border-radius: 8px;

  color: ${({ $isActive: isActive }) => (isActive ? "#696cff" : "#738395")};
  margin: 0 20px;
  width: 100%;
  cursor: pointer;
  @media (max-width: 1600px) {
    font-size: 16px;
  }
  @media (max-width: ${breakPoints.laptop}) {
    font-size: 14px;
  }
`;

const MenuItem = styled(Link)<{ $isActive?: boolean }>`
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  padding: 10px 20px;
  border-radius: 8px;
  background-color: ${({ $isActive: isActive }) => (isActive ? "#e7e7ff" : "")};
  color: ${({ $isActive: isActive }) => (isActive ? "#696cff" : "#738395")};
  margin: 0 20px;
  width: 100%;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  /* :hover {
    color: #ffffff;
  } */

  @media (max-width: 1600px) {
    font-size: 16px;
  }
  @media (max-width: ${breakPoints.laptop}) {
    font-size: 14px;
  }
`;

const ExpandIconWrap = styled.div<{ isExpand: boolean }>`
  min-width: 18px;
  width: 18px;
  height: 18px;
  margin: 0 20px;
  transition: all 0.3s ease;
  transform: ${({ isExpand }) =>
    isExpand ? "rotate(0deg);" : "rotate(180deg)"};
`;

const ExpandIconElement = styled.img`
  width: 100%;
  height: 100%;
`;

const SubMenuMobile = styled.div<{ isExpand: boolean }>`
  color: #ffffff;
  border-radius: 8px;
  z-index: 9999999999;
  opacity: ${({ isExpand }) => (isExpand ? "1" : "0")};
  visibility: ${({ isExpand }) => (isExpand ? "visible" : "hidden")};
  overflow: hidden;
  transition: 0.8s ease;
  :first-child {
    margin-top: 10px;
  }
`;

const SubMenuItemWrapper = styled.div`
  margin-left: 0;
  padding: 8px 28px;
  transition: all 0.3s ease-in-out;
  :hover {
    a {
      color: #222222;
    }
  }
`;

const SubMenuItem = styled(Link)<{ $isActive?: boolean }>`
  position: relative;
  display: block;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  padding: 10px 20px;
  border-radius: 8px;
  background-color: ${({ $isActive: isActive }) => (isActive ? "#e7e7ff" : "")};
  color: ${({ $isActive: isActive }) => (isActive ? "#696cff" : "#738395")};
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  :hover {
    color: #ffffff;
  }

  @media (max-width: 1440px) {
    font-size: 16px;
  }
  @media (max-width: ${breakPoints.laptop}) {
    font-size: 14px;
  }
`;
const LogoSpan = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: rgb(115, 131, 149);
`;

const ConfirmLogout = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 16px;
  color: #222222;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const ConfirmTitle = styled.span`
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
`;

const ConfirmButton = styled.div<{ background: string }>`
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  background: ${({ background }) =>
    background ? background : "rgb(36, 107, 212)"};
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
`;

interface NavProps {
  id: string;
  title: string;
  url: string;
  isActive: boolean;
  isExpand: boolean;
}
interface Nav {
  id: string;
  title: string;
  url: string;
  isActive: boolean;
  isExpand: boolean;
  subMenu?: NavProps[];
}
function Navbar() {
  const authCtx = useContext(AuthContext);
  const { role, isLogin } = authCtx;
  const { pathname, hash, key } = useLocation();
  const navigate = useNavigate();

  //confirm logout popup
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleConfirmLogout = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  //end confirm logout popup

  const [navbarList, setNavbarList] = useState<Nav[]>(NavbarList);
  const handleExpandSubmenuMobile = (id: string) => {
    const listMenuMobileCustom = navbarList.map((item) =>
      // item.subMenu?.length && item.id === id
      //   ? { ...item, isExpand: !item.isExpand }
      //   : item

      item.subMenu?.length
        ? item.id === id
          ? { ...item, isExpand: !item.isExpand }
          : { ...item, isExpand: false }
        : item
    );
    setNavbarList(listMenuMobileCustom);
  };

  const onLogoutHandler = () => {
    authCtx.logout();
  };

  const handleActiveMenu = () => {
    const activeMenu = navbarList.map((menu) => {
      let newMenuItem = null;
      if (menu.url === "" && menu.subMenu?.length) {
        const selectSubMenu = menu.subMenu?.find(
          (sub: any) => sub.url?.toLowerCase() === pathname?.toLowerCase()
        );
        if (selectSubMenu) {
          const customSubmenu = menu.subMenu?.map((sub: any) =>
            sub.url?.toLowerCase() === selectSubMenu?.url?.toLowerCase()
              ? { ...sub, isActive: true }
              : { ...sub, isActive: false }
          );
          newMenuItem = { ...menu, isActive: true, subMenu: customSubmenu };
        } else {
          const customSubmenu = menu.subMenu?.map(
            (item: any) => item && { ...item, isActive: false }
          );
          newMenuItem = {
            ...menu,
            isActive: false,
            subMenu: customSubmenu,
          };
        }
      } else {
        if (menu.url?.toLowerCase() === pathname?.toLowerCase()) {
          newMenuItem = { ...menu, isActive: true };
        } else {
          newMenuItem = { ...menu, isActive: false };
        }
      }
      return newMenuItem;
    });

    setNavbarList(activeMenu);
  };

  const handleAuthorize = () => {
    const menuMarketingAgent = NavbarList.filter((menu) =>
      ["2", "5"].includes(menu.id)
    );
    if (role === AdminRole.MarketingAgent) {
      setNavbarList(menuMarketingAgent);
      return;
    }
  };

  useEffect(() => {
    handleActiveMenu();
  }, [pathname, hash, key]);

  useEffect(() => {
    handleAuthorize();
  }, [role]);

  return (
    <NavbarWrap>
      <NavbarTop>
        {/* <LogoIcon /> */}
        <LogoSpan>START MOBIS</LogoSpan>
      </NavbarTop>
      <NavbarMenu>
        <NavbarMenuWrap>
          <MenuMobileWrapper>
            {navbarList?.length &&
              navbarList.map((item, index) => (
                <MenuMobileItemWrapper
                  $isActive={item.isActive}
                  onClick={() => {
                    handleExpandSubmenuMobile(item.id);
                    if (item.id === "2")
                      navigate("/user-agent/pending-requests");
                    if (item.id === "11")
                      navigate("/materials-lib/collections");
                  }}
                  key={index}
                >
                  {item.subMenu?.length ? (
                    <>
                      {item.title?.toUpperCase() === "DASHBOARD" &&
                      item.subMenu?.length === 1 ? (
                        <MenuItem
                          $isActive={item.isActive}
                          //onClick={handleCloseModal}
                          to={item.subMenu[0]?.url}
                        >
                          {item.title}
                        </MenuItem>
                      ) : (
                        <>
                          <Wrapper>
                            <TopWrapper>
                              <MenuItemText $isActive={item.isActive}>
                                {item.title}
                              </MenuItemText>
                              <ExpandIconWrap isExpand={item.isExpand}>
                                <ExpandIcon />
                              </ExpandIconWrap>
                            </TopWrapper>
                            <BottomWrapper isExpand={item.isExpand}>
                              <SubMenuMobile isExpand={item.isExpand}>
                                {item.subMenu?.map((sub, i) => (
                                  <SubMenuItemWrapper key={i}>
                                    <SubMenuItem
                                      $isActive={sub.isActive}
                                      //onClick={handleCloseModal}
                                      onClick={(e) => e.stopPropagation()}
                                      to={sub.url}
                                    >
                                      {sub.title}
                                    </SubMenuItem>
                                  </SubMenuItemWrapper>
                                ))}
                              </SubMenuMobile>
                            </BottomWrapper>
                          </Wrapper>
                        </>
                      )}
                    </>
                  ) : (
                    <MenuItem
                      $isActive={item.isActive}
                      //onClick={handleCloseModal}
                      to={item.url}
                    >
                      {item.title}
                    </MenuItem>
                  )}
                </MenuMobileItemWrapper>
              ))}
          </MenuMobileWrapper>
        </NavbarMenuWrap>
      </NavbarMenu>
      <NavbarBottom>
        <ProfileWrap>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <ConfirmLogout>
              <ConfirmTitle>Do you want to sign out?</ConfirmTitle>
              <ConfirmButton
                onClick={onLogoutHandler}
                background="rgb(36, 107, 212)"
              >
                Confirm
              </ConfirmButton>
            </ConfirmLogout>
          </Popover>
          <IconWrap fontSize="medium" onClick={handleConfirmLogout}>
            <LogoutBtn />
          </IconWrap>
          <UserName>{authCtx.name}</UserName>
        </ProfileWrap>
      </NavbarBottom>
    </NavbarWrap>
  );
}

export default Navbar;
