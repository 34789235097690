import ListPopup from "./components/Popups";
import { PopupOutlet } from "./context/popup-context";

function Popup() {
  return (
    <div>
      <PopupOutlet>
        <ListPopup />
      </PopupOutlet>
    </div>
  );
}

export default Popup;
