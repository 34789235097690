import React from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;
function Container({ children }: { children: React.ReactNode }) {
  return <StyledContainer>{children}</StyledContainer>;
}

export default Container;
