import React, { createContext, useState } from "react";
import { Outlet } from "react-router-dom";
import { IMaterial, IMaterialCollection } from "../../../constants/interface";

interface Props {
  children: React.ReactNode;
}

// Define the context interface
interface MaterialsLibraryContextType {
  isReloadList: boolean;
  refreshTable: () => void;
  refreshCollectionTable: () => void;

  material: IMaterial | null;
  setMaterial: React.Dispatch<React.SetStateAction<IMaterial | null>>;

  materialCollection: IMaterialCollection | null;
  setMaterialCollection: React.Dispatch<
    React.SetStateAction<IMaterialCollection | null>
  >;
}

// Create the context
const MaterialsLibraryContext = createContext<
  MaterialsLibraryContextType | undefined
>(undefined);

// Custom Provider component
export const MaterialsLibraryContextProvider = (props: Props) => {
  const [isReloadList, setIsReloadList] = useState<boolean>(false);
  const [material, setMaterial] = useState<IMaterial | null>(null);
  const [materialCollection, setMaterialCollection] =
    useState<IMaterialCollection | null>(null);

  const refreshTable = () => {
    setIsReloadList((prev) => !prev);
    setMaterial(null);
  };
  const refreshCollectionTable = () => {
    setIsReloadList((prev) => !prev);
    setMaterialCollection(null);
  };

  return (
    <MaterialsLibraryContext.Provider
      value={{
        isReloadList,
        refreshTable,
        refreshCollectionTable,
        material,
        setMaterial,
        materialCollection,
        setMaterialCollection,
      }}
    >
      {props.children}
    </MaterialsLibraryContext.Provider>
  );
};

export const MaterialsLibraryOutlet = (props: any) => {
  return (
    <MaterialsLibraryContextProvider>
      {props.children ? props.children : <Outlet />}
    </MaterialsLibraryContextProvider>
  );
};
export default MaterialsLibraryContext;
