import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const MaterialsLibrary = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/materials-lib/collections");
  }, []);
  return <div>MaterialsLibrary</div>;
};

export default MaterialsLibrary;
