import { useContext, useEffect } from "react";
import styled from "styled-components";
import { Box, Button } from "@mui/material";
import moment from "moment";
import OrderContext, { FormMode } from "../../context/order-context";
import { breakPoints } from "../../../../constants/device";
import InputText from "./InputText/InputText";
import { Order, UpdateOrder } from "../../../../constants/interface";
import LoadingButton from "@mui/lab/LoadingButton";
import useAddress from "../../hooks/use-address";
import SelectAddress from "./selectAddress/SelectAddress";
import Api from "../../../../service";
import { toast } from "react-toastify";
import StatusInputText from "./InputText/StatusInputText";
import { OrderStatusType } from "../../constants";

export const RowWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 0 24px;
  width: 100%;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  padding: 0 24px;
  padding-top: 32px;
  width: 100%;
`;

export const ColumnWrap = styled.div<{ widthPercent?: number }>`
  display: flex;
  flex-direction: column;
  width: ${({ widthPercent }) => (widthPercent ? `${widthPercent}%` : "100%")};
  gap: 16px;
  .MuiFormHelperText-root {
    color: #d32f2f;
  }
`;

export const SectionTitle = styled.h3`
  margin: 0;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 60px;
  letter-spacing: -0.0025em;
  text-transform: uppercase;
  color: #222222;
`;

export const WrapText = styled.div`
  min-width: 220px;
  align-self: flex-start;
  @media (max-width: ${breakPoints.exDesktop}) {
    min-width: 160px;
  }
`;

const TextStyle = styled.span`
  line-height: 40px;
  font-size: 20px;
  font-weight: 600;
  font-family: "Quicksand";
  font-style: normal;
  /* text-transform: capitalize; */
  color: #222222;
  @media (max-width: ${breakPoints.exDesktop}) {
    font-size: 16px;
  }
`;

export const TitleStyle = styled(TextStyle)`
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
  color: gray;
  @media (max-width: ${breakPoints.exDesktop}) {
    font-size: 16px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
`;
const ButtonWrap = styled.div`
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  gap: 1rem;
`;
const CreateButton = styled(LoadingButton)`
  padding: 8px 32px !important;
  background: rgb(85, 177, 71) !important;
  color: #ffffff !important;
  border-radius: 8px !important;
  cursor: pointer;
`;
const CancelBtn = styled(CreateButton)`
  background: #f2c936 !important;
`;
const ButtonText = styled.span`
  font-size: 20px;
`;

function CreateFormModal() {
  const { order, setFormMode, formMode } = useContext(OrderContext);
  const { countries, states, districts } = useAddress();

  const handleSubmitForm = async () => {
    switch (formMode) {
      case FormMode.View:
        setFormMode(FormMode.Edit);
        break;
      case FormMode.Edit:
        if (!order) return;
        const dataToUpdate: UpdateOrder = {
          orderId: order?._id,
          customer_name: order?.customer_name,
          customer_email: order?.customer_email,
          phone_number: order?.phone_number,
          receiver_phone_number: order?.receiver_phone_number,
          apartment: order?.apartment,
          company_name: order?.company_name,
          country_code: order?.country.iso2,
          state_code: order?.state.state_code,
          city_id: order?.city.id,
          zip_code: order?.zip_code,
          address: order?.address,
        };
        const res = await Api.Order.updateOrder(dataToUpdate);
        if (res.data) {
          toast.success("Order is updated successfully");
          setFormMode(FormMode.View);
        }
        break;
    }
  };

  return (
    <Container>
      <Box
        component="form"
        sx={{ width: "100%", maxHeight: "100%", overflow: "auto" }}
      >
        <Content>
          <InfoContainer>
            <ColumnWrap widthPercent={50}>
              <SectionTitle>Information</SectionTitle>
              <InputText
                title={"Order Code"}
                content={order?.orders_code as string}
                keyOfOrder={"orders_code"}
              />
              <InputText
                title={"Customer"}
                content={order?.customer_name as string}
                mode={formMode}
                keyOfOrder={"customer_name"}
              />

              <InputText
                title={"Email"}
                content={order?.customer_email as string}
                mode={formMode}
                keyOfOrder={"customer_email"}
              />

              <InputText
                title={"Phone"}
                content={order?.phone_number as string}
                mode={formMode}
                keyOfOrder={"phone_number"}
              />

              <InputText
                title={"Receiver Phone"}
                content={order?.receiver_phone_number as string}
                mode={formMode}
                keyOfOrder={"receiver_phone_number"}
              />

              <InputText
                title={"Apartment"}
                content={order?.apartment || "-"}
                mode={formMode}
                keyOfOrder={"apartment"}
              />

              <InputText
                title={"Company"}
                content={order?.company_name || "-"}
                mode={formMode}
                keyOfOrder={"company_name"}
              />

              <SelectAddress
                title={`Country`}
                listData={countries}
                keyOfOrder={"country"}
              />
              <SelectAddress
                title={`Province`}
                listData={states}
                keyOfOrder={"state"}
              />
              <SelectAddress
                title={`District`}
                listData={districts}
                keyOfOrder={"city"}
              />
              <InputText
                title={"Address"}
                content={order?.address || "-"}
                mode={formMode}
                keyOfOrder={"address"}
              />
              <StatusInputText
                title={"Status"}
                content={order?.status || "-"}
                keyOfOrder={"status"}
              />
              {(order?.status === OrderStatusType.Canceled ||
                order?.status === OrderStatusType.RequestCancel) && (
                <InputText
                  title={"Cancel reason"}
                  content={order?.cancel_reason || "-"}
                  keyOfOrder={"cancel_reason"}
                />
              )}
              <InputText
                title={"Created"}
                content={moment(order?.createdAt).format("YYYY-MM-DD HH:mm")}
                keyOfOrder={"createdAt"}
              />
              <InputText
                title={"Zip Code"}
                content={order?.zip_code as string}
                mode={formMode}
                keyOfOrder={"zip_code"}
              />
              <InputText
                title={"Payment Method"}
                content={order?.payment_method || "-"}
                keyOfOrder={"payment_method"}
              />
              <InputText
                title={"Payment Status"}
                content={order?.payment_status || "-"}
                keyOfOrder={"payment_status"}
              />
              <InputText
                title={"Transaction Id"}
                content={order?.transaction_id || "-"}
                keyOfOrder={"transaction_id"}
              />
            </ColumnWrap>
            <ColumnWrap widthPercent={50}>
              <SectionTitle>User</SectionTitle>
              <RowWrap>
                <WrapText>
                  <TitleStyle>Full Name</TitleStyle>
                </WrapText>
                <WrapText>
                  <TextStyle>{order?.user?.fullname}</TextStyle>
                </WrapText>
              </RowWrap>
              <RowWrap>
                <WrapText>
                  <TitleStyle>Email</TitleStyle>
                </WrapText>
                <WrapText>
                  <TextStyle>{order?.user?.email}</TextStyle>
                </WrapText>
              </RowWrap>

              {order?.user_agent && (
                <>
                  <SectionTitle>User Agent</SectionTitle>
                  <RowWrap>
                    <WrapText>
                      <TitleStyle>Full Name</TitleStyle>
                    </WrapText>
                    <WrapText>
                      <TextStyle>{order?.user_agent?.fullname}</TextStyle>
                    </WrapText>
                  </RowWrap>
                  <RowWrap>
                    <WrapText>
                      <TitleStyle>Email</TitleStyle>
                    </WrapText>
                    <WrapText>
                      <TextStyle>{order?.user_agent?.email}</TextStyle>
                    </WrapText>
                  </RowWrap>
                </>
              )}
            </ColumnWrap>
          </InfoContainer>
        </Content>
        <ButtonWrap>
          {formMode === FormMode.Edit && (
            <CancelBtn>
              <ButtonText onClick={() => setFormMode(FormMode.View)}>
                Cancel
              </ButtonText>
            </CancelBtn>
          )}
          <CreateButton
            // loading={isLoading}
            // disabled={isLoading}
            loadingPosition="start"
            startIcon={<></>}
            onClick={handleSubmitForm}
          >
            <ButtonText>
              {formMode === FormMode.Edit ? "Update" : "Edit"}
            </ButtonText>
          </CreateButton>
        </ButtonWrap>
      </Box>
    </Container>
  );
}

export default CreateFormModal;
