import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, TextField } from "@mui/material";
import React, { useState, useContext } from "react";
import styled from "styled-components";
import Content from "./content/Content";
import Comments from "./comments";
import CommunityContext from "../../context/community-context";
import { NewfeedStatus } from "../../../../constants/types";
import { Button, Chip } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import AuthContext from "../../../../context/auth-context";
import { toast } from "react-toastify";
import Api from "../../../../service";
import Popover from "@mui/material/Popover";
import { Modal } from "@mui/material";

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-width: 1000px;
  min-height: 820px;
  background-color: #fff;
  padding: 20px;
`;
const Header = styled.div`
  padding: 8px 16px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 40px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const AvatarWrap = styled.div`
  min-width: 80px;
  min-height: 80px;
  max-width: 80px;
  max-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Avatar = styled.img`
  object-fit: cover;
  width: 100%;
  max-height: 80px;
  border-radius: 50%;
`;

const TextStyle = styled.span`
  font-size: 22px;
  font-weight: 600;
  font-family: "Quicksand";
  font-style: normal;
  text-transform: capitalize;
  color: #222222;
`;

const TitleStyle = styled(TextStyle)`
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
  color: gray;
`;

const FullName = styled(TitleStyle)`
  font-size: 26px;
  font-weight: 700;
  color: #222222;
`;

const Email = styled(TextStyle)`
  font-weight: 600;
  font-size: 16px;
  text-transform: none;
  color: #0b7bf4;
  text-decoration: underline;
`;

const Status = styled(TextStyle)<{ color: string }>`
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  color: ${({ color }) => (color ? color : "#0b7bf4")};
`;

const SubmittedStyle = styled(Status)`
  text-transform: uppercase;
`;

const WrapButton = styled.div`
  display: flex;
  gap: 24px;
  @media (max-width: 1600px) {
    gap: 12px;
  }
`;

const CustomButton = styled(LoadingButton)<{ bgColor: string; width?: number }>`
  display: inline-block !important;
  vertical-align: middle !important;
  /* padding: 0 !important; */
  padding: 0 24px !important;
  margin: 0 !important;
  width: ${({ width }) => (width ? `${width}px` : "auto")};
  min-width: 35px !important;
  height: 35px !important;
  line-height: unset !important;
  background-color: ${({ bgColor }) =>
    bgColor ? `${bgColor} !important` : "#54B4D3 !important"};
  color: #fff !important;

  .MuiIcon-root {
    height: auto;
  }
`;

export const Type = styled.div`
  padding: 4px 8px;
  background: rgba(14, 203, 129, 0.1);
  border-radius: 3px;

  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Dot = styled.span`
  background: #0ecb81;
  border-radius: 50%;
  min-width: 8px;
  width: 8px;
  height: 8px;
`;

export const TypeText = styled.span`
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0ecb81;
  text-transform: capitalize;
`;

const CustomTabContext = styled(TabContext)`
  width: 100%;
  height: 100%;
  background-color: #fff;
`;
const CustomTabPanel = styled(TabPanel)<{ maxHeight?: string }>`
  width: 100%;
  background-color: #fff;
  padding: 24px 0 !important;
  height: 100%;
  ${({ maxHeight }) => maxHeight && { maxHeight: maxHeight }}
`;

const CustomTab = styled(Tab)`
  font-size: 18px !important;
  font-weight: 700 !important;
  font-family: "Quicksand" !important;
  font-style: normal !important;
  text-transform: capitalize !important;
  color: #222222 !important;
`;

const LineColumn = styled.span`
  display: block;
  height: 50px;
  width: 2px;
  background-color: gray;
`;

const ConfirmChangeStatus = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 16px;
  color: #222222;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const ConfirmHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const ConfirmTitle = styled.span`
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
`;

const CustomModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  width: 30%;
  height: 20vh;
  max-height: 20vh;
  overflow-y: hidden;
`;

export enum TypePost {
  Approve = "APPROVE",
  CancelApprove = "CANCEL_APPROVE",
  Delete = "DELETE",
}

function Detail() {
  const authCtx = useContext(AuthContext);
  const { role } = authCtx;
  const communityCtx = useContext(CommunityContext);
  const {
    node,
    post,
    setPost,
    isOpenConfirmModal,
    setIsOpenConfirmModal,
    isReloadList,
    setIsReloadList,
    setIsOpenModal,
    typeSubmit,
    setTypeSubmit,
  } = communityCtx;
  const [tab, setTab] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (e: any, value: number) => {
    setTab(value);
  };

  const handleUpdate = async () => {
    if (!typeSubmit || !post?._id) {
      toast.error("Cannot update !");
      return;
    }

    try {
      setLoading(true);

      const response =
        typeSubmit === TypePost.Approve
          ? await Api.Community.approvePost(post?._id)
          : await Api.Community.cancelApprovePost(post?._id);

      if (response) {
        setPost({
          ...post,
          status: response?.data?.status || NewfeedStatus.Pending,
        });
        node.setData({
          ...post,
          imagePreview: response?.data?.media_url[0] || "",
          status: response?.data?.status || NewfeedStatus.Pending,
        });
        toast.success(
          typeSubmit === TypePost.Approve
            ? "Approve success !"
            : "Cancel success !"
        );
        handleCloseConfirmModal();
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!typeSubmit || !post?._id) {
      toast.error("Cannot delete !");
      return;
    }

    try {
      setLoading(true);

      const response = await Api.Community.deletePost(post?._id);

      if (response) {
        setIsReloadList(!isReloadList);
        toast.success("Delete success!");
        handleCloseConfirmModal();
        setIsOpenModal(false);
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmChangeStatus = (
    event: React.MouseEvent<HTMLButtonElement>,
    typeSubmit: string
  ) => {
    setIsOpenConfirmModal(true);
    setTypeSubmit(typeSubmit);
  };

  const handleCloseConfirmModal = () => {
    setIsOpenConfirmModal(false);
  };

  return (
    <Container>
      <Header>
        <Row>
          <Column>
            <Row>
              <AvatarWrap>
                <Avatar src={post?.user?.avatar} />
              </AvatarWrap>
              <Column>
                <FullName>{post?.user?.fullname}</FullName>
                <Row>
                  <TitleStyle>Email :</TitleStyle>
                  <Email>{post?.user?.email}</Email>
                </Row>
              </Column>
            </Row>
          </Column>
          <LineColumn />
          <Column>
            <Row>
              <TitleStyle>Status :</TitleStyle>
              <Status
                color={
                  post?.status === NewfeedStatus.Approved
                    ? "#2e7d32"
                    : post?.status === NewfeedStatus.Pending
                    ? "#ed6c02"
                    : "#d32f2f"
                }
              >
                {post?.status}
              </Status>
              <div>
                {post?.status === NewfeedStatus.Pending && (
                  <CustomButton
                    onClick={(e) =>
                      handleConfirmChangeStatus(e, TypePost.Approve)
                    }
                    bgColor="#2e7d32"
                  >
                    Approve
                  </CustomButton>
                )}
                {post?.status === NewfeedStatus.Approved && (
                  <CustomButton
                    onClick={(e) =>
                      handleConfirmChangeStatus(e, TypePost.CancelApprove)
                    }
                    bgColor="gray"
                  >
                    Cancel
                  </CustomButton>
                )}
              </div>
            </Row>
          </Column>
        </Row>
      </Header>
      <CustomTabContext value={tab.toString()}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <CustomTab label="Content" value="1" />
            <CustomTab label="Comments" value="2" />
          </TabList>
        </Box>
        <CustomTabPanel maxHeight="calc(100% - 200px)" value="1">
          <Content />
        </CustomTabPanel>
        <CustomTabPanel value="2">
          <Comments />
        </CustomTabPanel>
      </CustomTabContext>
      <CustomModal
        open={isOpenConfirmModal}
        onClose={() => handleCloseConfirmModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContainer>
          <ConfirmChangeStatus>
            <ConfirmHeader>
              <ConfirmTitle>
                {typeSubmit === TypePost.Approve
                  ? "Are you sure APPROVE?"
                  : typeSubmit === TypePost.CancelApprove
                  ? "Are you sure CANCEL?"
                  : "Are you sure DELETE?"}
              </ConfirmTitle>
            </ConfirmHeader>

            <CustomButton
              loading={loading}
              disabled={loading}
              loadingPosition="start"
              startIcon={<></>}
              onClick={() =>
                typeSubmit === TypePost.Delete ? handleDelete() : handleUpdate()
              }
              bgColor="rgb(36, 107, 212)"
            >
              Confirm
            </CustomButton>
          </ConfirmChangeStatus>
        </ModalContainer>
      </CustomModal>
    </Container>
  );
}

export default Detail;
