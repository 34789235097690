import React, { useState } from "react";
import styled from "styled-components";
import PlusIcon from "./Plus";
import { IPhoto } from "../../../../../constants/types";
import { AgentUpdate } from "./Profile";
import { useAgent } from "../../../context/agent-context";

interface IProps {
  field_name: string;
  chooseFile: IPhoto | undefined;
  setChosenFile: React.Dispatch<React.SetStateAction<IPhoto | undefined>>;
}
const FileInput = ({
  field_name,
  chooseFile,
  setChosenFile,
  ...rest
}: IProps) => {
  const { setAgent } = useAgent();

  const handleCapture = (event: any) => {
    const file = event.target.files[0];
    if (!file) return;
    const url = URL.createObjectURL(file);
    setChosenFile({
      urlPreview: url,
      formDataValue: file,
      fileName: file.name,
    });
    setAgent((prev: any) => {
      return { ...prev, [field_name]: url };
    });
  };

  return (
    <Container>
      <label htmlFor={field_name}>
        <PlusIcon />
      </label>
      <input id={field_name} type="file" onChange={handleCapture} {...rest} />
    </Container>
  );
};

export default FileInput;

const Container = styled.div`
  > input {
    display: none;
  }
  > label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    background-color: rgba(128, 128, 128, 0.1);
    cursor: pointer;
    border-radius: 1rem;

    > svg {
      height: 2rem;
      width: 2rem;
    }
  }
`;
