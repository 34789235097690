import React, { createContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Event } from "../../../constants/interface";
import { toast } from "react-toastify";
import { LangType } from "../../../constants/types";

interface Props {
  children: React.ReactNode;
}
// Define the context interface
interface EventContextType {
  isReloadList: boolean;
  setIsReloadList: React.Dispatch<React.SetStateAction<boolean>>;

  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;

  event: Event | null;
  setEvent: React.Dispatch<React.SetStateAction<Event | null>>;

  node: any;
  setNode: React.Dispatch<any>;
}

// Create the context
const EventContext = createContext<EventContextType>({
  /*eslint-disable */
  isReloadList: false,
  setIsReloadList: () => {},
  isOpenModal: false,
  setIsOpenModal: () => {},
  event: null,
  setEvent: () => {},
  node: "",
  setNode: () => {},
});

// Custom Provider component
export const EventContextProvider = (props: Props) => {
  const [isReloadList, setIsReloadList] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [event, setEvent] = useState<Event | null>(null);
  const [node, setNode] = useState<any | null>(null);

  return (
    <EventContext.Provider
      value={{
        isReloadList,
        setIsReloadList,
        isOpenModal,
        setIsOpenModal,
        event,
        setEvent,
        node,
        setNode,
      }}
    >
      {props.children}
    </EventContext.Provider>
  );
};

export const EventOutlet = (props: any) => {
  return (
    <EventContextProvider>
      {props.children ? props.children : <Outlet />}
    </EventContextProvider>
  );
};
export default EventContext;
