import { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import Modal from "@mui/material/Modal";
import { Icon, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import "react-quill/dist/quill.snow.css";
import { Box } from "@mui/material";
import { useModal } from "../../../../../hooks/use-modal";
import { ModalKey } from "../../../../../context/modal-context";
import { useSearchParams } from "react-router-dom";
import { FormMode, SearchParamsKey } from "../../../../../constants/types";
import Api from "../../../../../service";
import { toast } from "react-toastify";
import useMaterialsLibrary from "../../../MaterialsLibrary.hooks";
import { useForm } from "react-hook-form";

interface IForm {
  title: string;
  description: string;
}

function MaterialCollectionCreateModal() {
  const { isModalOpen, closeModal } = useModal();
  const { materialCollection, setMaterialCollection, refreshTable } =
    useMaterialsLibrary();
  const [searchParams, setSearchParams] = useSearchParams();

  const formMode = searchParams.get(SearchParamsKey.FormMode);
  const isCreateMode = formMode === FormMode.Create;

  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm<IForm>();

  const handleCloseModal = () => {
    closeModal(ModalKey.MATERIALS_LIBRARY_CREATE);
    searchParams.delete(SearchParamsKey.FormMode);
    setSearchParams(searchParams);
    setMaterialCollection(null);
  };

  const handleSubmitForm = async (formData: IForm) => {
    try {
      setIsLoading(true);
      const dataToCreate = {
        title: formData.title,
        description: formData.description,
      };
      const dataToUpdate = {
        ...dataToCreate,
        collection_id: materialCollection?._id || "",
      };

      const response = isCreateMode
        ? await Api.MaterialsLib.createCollection(dataToCreate)
        : await Api.MaterialsLib.updateCollection(dataToUpdate);
      if (response?.data) {
        toast.success(
          `${isCreateMode ? "Create" : "Update"} collection successfully!`
        );
        reset();
        handleCloseModal();
        refreshTable();
      }
    } catch (error) {
      console.log("error:", error);
      toast.error(error as string);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBindingFormData = useCallback(() => {
    setValue("title", materialCollection?.title || "");
    setValue("description", materialCollection?.description || "");
  }, [materialCollection, setValue]);

  useEffect(() => {
    handleBindingFormData();
  }, [handleBindingFormData]);

  return (
    <>
      <Modal
        open={isModalOpen(ModalKey.MATERIALS_LIBRARY_CREATE)}
        onClose={handleCloseModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box component="form">
          <FormWrap>
            <Form>
              <CloseWrap>
                <IconWrap fontSize="large" onClick={handleCloseModal}>
                  <CloseBtn />
                </IconWrap>
              </CloseWrap>
              <TitleWrap>
                <Title>Material Collection</Title>
              </TitleWrap>
              <RowWrap>
                <TextField
                  fullWidth
                  id="outlined"
                  label="Title *"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("title", {
                    required: {
                      value: true,
                      message: "Can't be empty!",
                    },
                  })}
                />
              </RowWrap>
              {errors.title && <ErrorText>Can't be empty!</ErrorText>}
              <RowWrap>
                <TextField
                  fullWidth
                  id="outlined"
                  label="Description *"
                  size="small"
                  multiline
                  rows={5}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("description", {
                    required: {
                      value: true,
                      message: "Can't be empty!",
                    },
                  })}
                />
              </RowWrap>
              {errors.description && <ErrorText>Can't be empty!</ErrorText>}
              <ButtonWrap>
                <CreateButton
                  loading={isLoading}
                  disabled={isLoading}
                  loadingPosition="start"
                  startIcon={<></>}
                  onClick={handleSubmit(handleSubmitForm)}
                >
                  <ButtonText>{!isCreateMode ? "Update" : "Create"}</ButtonText>
                </CreateButton>
              </ButtonWrap>
            </Form>
          </FormWrap>
        </Box>
      </Modal>
    </>
  );
}

export default MaterialCollectionCreateModal;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
`;
const CreateButton = styled(LoadingButton)`
  padding: 8px 32px !important;
  background: rgb(85, 177, 71) !important;
  color: #ffffff !important;
  border-radius: 8px !important;
  cursor: pointer;
`;
const ButtonText = styled.span`
  font-size: 20px;
`;

const FormWrap = styled.div`
  overflow: hidden auto;
  height: 100vh;
`;

const Form = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  min-height: 400px;
  background: #fff;
  border-radius: 24px;
  width: 70%;
  margin: 5% auto;
`;

const RowWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 0 24px;
  width: 100%;
  position: relative;
`;

const TitleWrap = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  margin: 0;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.0025em;
  text-transform: uppercase;
  color: #222222;
`;

const CloseWrap = styled.div<{ isCloseLang?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: ${({ isCloseLang }) => (isCloseLang ? "0" : "0 24px")};

  ${({ isCloseLang }) =>
    isCloseLang && {
      position: "absolute",
      right: "0",
      top: "0",
    }};
`;

const IconWrap = styled(Icon)`
  cursor: pointer;
`;

const CloseBtn = styled(CloseIcon)`
  width: 100% !important;
  height: 100% !important;
`;
const ErrorText = styled.p`
  /* position: absolute;
  bottom: -60%;
  left: 0; */
  margin-top: -24px;
  padding: 0 24px;

  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  color: rgb(211, 47, 47);
`;
