import React from "react";
import { ProductKeyOutlet } from "./context/product-key-context";
import ProductKeysTable from "./components/ProductKeysTable";

const ProductKey = () => {
  return (
    <div>
      <ProductKeyOutlet>
        <ProductKeysTable />
      </ProductKeyOutlet>
    </div>
  );
};

export default ProductKey;
