import { useEffect, useState } from 'react'
import { IMaterial } from '../../../../constants/interface';
import Api from '../../../../service';
import { MaterialType } from '../../../../constants/types';
import { toast } from 'react-toastify';

const useMaterialsLibraryImage = ({ refresh }: { refresh?: boolean }) => {
    const [imagesData, setImagesData] = useState<IMaterial[]>()
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async () => {
        try {
            setIsLoading(true)
            const res = await Api.MaterialsLib.getMaterials({ type: MaterialType.IMAGE })
            if (res.data) {
                setImagesData(res.data.materials)
            }
        } catch (error) {
            toast.error(error as string);
        } finally {
            setIsLoading(false)
        }

    }

    useEffect(() => {
        fetchData();
    }, [refresh])

    return {
        imagesData,
        isLoading
    }
}

export default useMaterialsLibraryImage