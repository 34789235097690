export interface ISupportRequest {
  _id?: string;
  full_name?: string;
  email?: string;
  phone_number?: string;
  reason?: string;
  payment_service_name?: string;
  address_to_refund?: string;
  admin_response?: string;
  user?: any;
  order?: any;
}
export interface ISelect {
  label: string;
  value: string;
  code: string;
  id: string;
}
export interface IContent {
  lang: string;
  title: string;
  description: string;
  body: string;
}

export interface AddOrder {
  thumbnail: string;
  isShow: boolean;
  contents: IContent[];
}

export interface UpdateOrder {
  thumbnail: string;
  isShow: boolean;
  contents: IContent[];
  orderId: string;
}

export interface DeleteOrder {
  orderId: string;
}
export enum SummaryKeys {
  All = "All",
  InProgress = "InProgress",
  Confirmed = "Confirmed",
  Packing = "Packing",
  Shipping = "Shipping",
  RequestCancel = "RequestCancel",
  RejectRequestCancel = "RejectRequestCancel",
  SupportRequestCancel = "SupportRequestCancel",
  Canceled = "Canceled",
  Delivered = "Delivered",
  Refunded = "Refunded",
}

export enum OrderStatusType {
  Draft = "DRAFT",
  InProgress = "IN PROGRESS",
  Confirmed = "CONFIRMED",
  RequestCancel = "REQUEST CANCEL",
  Packing = "PACKING",
  Shipping = "SHIPPING",
  Delivered = "DELIVERED",
  Refunded = "REFUNDED",
  Completed = "COMPLETED",
  RejectRequestCancel = "REJECT REQUEST CANCEL",
  Canceled = "CANCELED",
}
