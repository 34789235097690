import React from "react";
import styled from "styled-components";
import GridExample from "./components/gridExample/GridExample";
import GridExampleWithCustomData from "./components/gridExample/GridExampleWithCustomData";
import User from "../user";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const DaoContainer = styled.div`
  margin: 50px 0;
  /* width: 100%;
  height: 500px; */
  /* padding: 2% 5%; */
`;
const MembersContainer = styled(DaoContainer)``;
const GridContainer = styled(DaoContainer)`
  width: 100%;
  height: 500px;
`;
function Home() {
  return (
    <Container>
      <User />
    </Container>
  );
}

export default Home;
