import { Chip } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
`;
const StatusText = styled.span<{ color: string }>`
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  color: ${({ color }) => (color ? color : "#0b7bf4")};
`;

enum Status {
  OnReview = "ON_REVIEW",
  Pass = "PASS",
  Fail = "FAIL",
  OnReviewIdentity = "ON_REVIEW_IDENTITY",
  PassIdentity = "PASS_IDENTITY",
  FailIdentity = "FAIL_IDENTITY",
}
function StatusField(props: ICellRendererParams) {
  const status = props.data.status.toUpperCase();

  const renderStatus = () => {
    switch (status) {
      case Status.Fail:
        return <StatusText color="rgb(246, 79, 89)">{Status.Fail}</StatusText>;
      case Status.FailIdentity:
        return (
          <StatusText color="rgb(246, 79, 89)">
            {Status.FailIdentity?.replaceAll("_", " ")}
          </StatusText>
        );
      case Status.OnReview:
        return (
          <StatusText color="rgb(18, 104, 233)">
            {Status.OnReview?.replaceAll("_", " ")}
          </StatusText>
        );
      case Status.OnReviewIdentity:
        return (
          <StatusText color="rgb(18, 104, 233)">
            {Status.OnReviewIdentity?.replaceAll("_", " ")}
          </StatusText>
        );
      case Status.Pass:
        return <StatusText color="rgb(180, 59, 240)">{Status.Pass}</StatusText>;
      case Status.PassIdentity:
        return (
          <StatusText color="rgb(180, 59, 240)">
            {Status.PassIdentity?.replaceAll("_", " ")}
          </StatusText>
        );
      default:
        return <StatusText color="rgb(246, 79, 89)">{Status.Fail}</StatusText>;
    }
  };

  return <Container>{renderStatus()}</Container>;
}

export default StatusField;
