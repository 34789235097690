import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "@ag-grid-community/react";
import {
  ColDef,
  FirstDataRenderedEvent,
  PaginationNumberFormatterParams,
} from "@ag-grid-community/core";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import styled from "styled-components";
import useMaterialsLibrary from "../MaterialsLibrary.hooks";
import { IMaterial } from "../../../constants/interface";
import { ModalKey } from "../../../context/modal-context";
import { useModal } from "../../../hooks/use-modal";
import ImageCell from "./cells/ImageCell";
import {
  FormMode,
  MaterialType,
  SearchParamsKey,
} from "../../../constants/types";
import MaterialCreateModal from "./modal/MaterialCreateModal";
import { useSearchParams } from "react-router-dom";
import ActionsCell from "./cells/ActionsCell";
import ActionConfirmModal from "./modal/ActionConfirmModal";

// Register the required feature modules with the Grid
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const MaterialsTable = ({
  data,
  type,
}: {
  data: IMaterial[];
  type: MaterialType;
}) => {
  const { setMaterial } = useMaterialsLibrary();
  const { openModal, isModalOpen } = useModal();
  const [searchParams, setSearchParams] = useSearchParams();
  const cellStyle = {
    display: "flex",
    alignItems: "center",
  };

  const gridRef = useRef<AgGridReact<IMaterial>>(null);
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState<IMaterial[]>();
  const [columnDefs] = useState<ColDef[]>([
    {
      field: "url",
      headerName: "File",
      cellRenderer: ImageCell,
      maxWidth: 200,
    },
    {
      field: "title",
      headerName: "Title",
      floatingFilter: true,
      cellStyle,
      flex: 1,
    },
    {
      field: "size",
      headerName: "Size(Kb)",
      maxWidth: 150,
      cellStyle,
    },
    {
      field: "views",
      headerName: "Views",
      maxWidth: 150,
      cellStyle,
    },
    {
      field: `${type !== MaterialType.OTHER ? "downloads" : "copies"}`,
      headerName: `${type !== MaterialType.OTHER ? "Downloads" : "Copies"}`,
      maxWidth: 150,
      cellStyle,
    },
    {
      colId: "actions",
      cellRenderer: ActionsCell,
      filter: false,
      minWidth: type === MaterialType.OTHER ? 300 : 350,
    },
  ]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      sortable: true,
      resizable: true,
      filter: true,
      // floatingFilter: true,
      // flex: 1,
      minWidth: 100,
    };
  }, []);
  const paginationNumberFormatter = useCallback(
    (params: PaginationNumberFormatterParams) => {
      return "[" + params.value.toLocaleString() + "]";
    },
    []
  );

  const onGridReady = useCallback(() => {
    setRowData(data);
  }, [data]);

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    gridRef.current!.api.paginationGoToPage(0);
    gridRef.current!.api.paginationSetPageSize(20);
  }, []);

  const onPageSizeChanged = useCallback(() => {
    var value = (document.getElementById("page-size") as HTMLInputElement)
      .value;
    gridRef.current!.api.paginationSetPageSize(Number(value));
  }, []);

  return (
    <>
      <Container>
        <ButtonWrap>
          <CreateButton
            onClick={() => {
              searchParams.set(SearchParamsKey.FormMode, FormMode.Create);
              searchParams.set(SearchParamsKey.MaterialType, type);
              setSearchParams(searchParams);
              openModal(ModalKey.MATERIALS_LIBRARY_CREATE);
            }}
          >
            <ButtonText>Create Material</ButtonText>
          </CreateButton>
        </ButtonWrap>

        <div style={containerStyle}>
          <div
            className="example-wrapper"
            style={{ width: "100%", height: "100%", position: "relative" }}
          >
            <div style={gridStyle} className="ag-theme-alpine">
              <AgGridReact<IMaterial>
                ref={gridRef}
                rowData={rowData}
                rowHeight={type === MaterialType.IMAGE ? 150 : 75}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                pivotPanelShow={"always"}
                pagination={true}
                paginationPageSize={10}
                paginationNumberFormatter={paginationNumberFormatter}
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                onCellClicked={(params) => {
                  setMaterial(params.data as IMaterial);
                }}
              ></AgGridReact>
            </div>

            <PageSizeContainer className="example-header">
              Page Size:{" "}
              <select onChange={onPageSizeChanged} id="page-size">
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
              </select>
            </PageSizeContainer>
          </div>
        </div>
      </Container>
      {(isModalOpen(ModalKey.MATERIALS_LIBRARY_CREATE) ||
        isModalOpen(ModalKey.MATERIALS_LIBRARY_UPDATE)) && (
        <MaterialCreateModal />
      )}
      {isModalOpen(ModalKey.MATERIALS_LIBRARY_DELETE) && <ActionConfirmModal />}
    </>
  );
};
export default React.memo(MaterialsTable);

const Container = styled.div`
  width: 100%;
  height: 75vh;
  border-radius: 10px;
  position: relative;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;
  gap: 1rem;
`;

const CreateButton = styled.div`
  padding: 8px 24px;
  background: #00a6ff;
  color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  :hover {
    filter: brightness(1.1);
  }
`;
const FakeButton = styled(CreateButton)`
  background-color: #587700 !important;
`;

const ButtonText = styled.span`
  font-size: 18px;
`;
const PageSizeContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: -50px;
`;
