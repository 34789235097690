import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Api from "../../service";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/auth-context";
import { useNavigate } from "react-router";
import { Alert } from "@mui/material";
import { LoginResponse } from "../../constants/interface";

export default function Login() {
  const authCtx = useContext(AuthContext);
  const { isLogin } = authCtx;
  const navigate = useNavigate();
  const [error, setError] = useState(false);

  useEffect(() => {
    if (isLogin) {
      navigate("/");
    }
  }, [isLogin]);
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = data.get("username")?.toString();
    const password = data.get("password")?.toString();
    try {
      if (username && password) {
        const response: any = await Api.Auth.login({ username, password });
        if (response) {
          authCtx.setName(response.data.profile.name);
          authCtx.login(response.data);
          navigate("/");
        }
      } else {
        setError(true);
        return;
      }
    } catch (error) {
      setError(true);
      console.log("error:", error);
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          px: 4,
          py: 6,
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            onFocus={() => setError(false)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onFocus={() => setError(false)}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          {error && (
            <Alert severity="error">
              <strong>Authentication failed!</strong>
            </Alert>
          )}
          <Grid container>
            <Grid item xs>
              {/* <Link href="#" variant="body2">
                Forgot password?
              </Link> */}
            </Grid>
            <Grid item>
              {/* <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link> */}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
