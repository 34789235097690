import React, { createContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Order } from "../../../constants/interface";
import { toast } from "react-toastify";
import { LangType } from "../../../constants/types";
import Api from "../../../service";
import { ISupportRequest } from "../constants";

interface IModal {
  isOpen: boolean;
  onConfirm?: () => void | Promise<void>;
  title?: string;
  body?: string;
  disable?: boolean;
  inputText?: string;
  label?: string;
  setInputText?: React.Dispatch<React.SetStateAction<string>>;
  responseRef?: React.MutableRefObject<any>;
}
export enum FormMode {
  Edit = "edit",
  View = "view",
}
interface Props {
  children: React.ReactNode;
}
interface IUpdateSupportRequestCancelOrder {
  admin_response?: string | undefined;
  admin_reject?: string | undefined;
}
// Define the context interface
interface OrderContextType {
  isReloadList: boolean;
  reloadList: () => void;

  isReloadSupportRequestList: boolean;
  setIsReloadSupportRequestList: React.Dispatch<React.SetStateAction<boolean>>;

  formMode: FormMode;
  setFormMode: React.Dispatch<React.SetStateAction<FormMode>>;

  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;

  modal: IModal | null;
  setModal: React.Dispatch<React.SetStateAction<IModal | null>>;
  onCloseModal: () => void;

  order: Order | null;
  setOrder: React.Dispatch<React.SetStateAction<Order | null>>;

  supportRequestCancelOrder: ISupportRequest | null;
  setSupportRequestCancelOrder: React.Dispatch<
    React.SetStateAction<ISupportRequest | null>
  >;

  node: any;
  setNode: React.Dispatch<any>;

  onRejectCancel: (orderId: string) => Promise<void>;
  onApproveCancel: (orderId: string) => Promise<void>;
  updateSupportRequestCancelOrder: ({
    admin_response,
    admin_reject,
  }: IUpdateSupportRequestCancelOrder) => Promise<void>;

  onApproveSupportRequestCancelOrder: (
    supportRequestId: string
  ) => Promise<void>;
  isLoading: boolean;
}

// Create the context
const OrderContext = createContext<OrderContextType>({
  /*eslint-disable */
  isReloadList: false,
  reloadList: () => {},
  isReloadSupportRequestList: false,
  setIsReloadSupportRequestList: () => {},
  formMode: FormMode.View,
  setFormMode: () => {},
  isOpenModal: false,
  setIsOpenModal: () => {},
  modal: null,
  setModal: () => {},
  onCloseModal: () => {},
  order: null,
  setOrder: () => {},
  supportRequestCancelOrder: null,
  setSupportRequestCancelOrder: () => {},
  node: "",
  setNode: () => {},
  onRejectCancel: () => Promise.resolve(),
  onApproveCancel: () => Promise.resolve(),
  updateSupportRequestCancelOrder: () => Promise.resolve(),
  onApproveSupportRequestCancelOrder: () => Promise.resolve(),
  isLoading: false,
});

// Custom Provider component
export const OrderContextProvider = (props: Props) => {
  const [isReloadList, setIsReloadList] = useState<boolean>(false);
  const [isReloadSupportRequestList, setIsReloadSupportRequestList] =
    useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode>(FormMode.View);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const [order, setOrder] = useState<Order | null>(null);
  const [supportRequestCancelOrder, setSupportRequestCancelOrder] =
    useState<ISupportRequest | null>(null);
  const [node, setNode] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [modal, setModal] = useState<IModal | null>(null);

  const closeModal = () => {
    setModal(null);
  };

  const reloadList = () => {
    setIsReloadList((prev) => !prev);
  };

  const onRejectCancel = async (orderId: string) => {
    setIsLoading(true);
    const response = await Api.Order.rejectCancel({ orderId });
    if (response?.data) {
      toast.success("Order Status is updated successfully");
      reloadList();
    }
    setIsLoading(false);
  };
  const onApproveCancel = async (orderId: string) => {
    setIsLoading(true);
    const response = await Api.Order.approveCancel({ orderId });
    if (response?.data) {
      toast.success("Order Status is updated successfully");
      reloadList();
    }
    setIsLoading(false);
  };

  const onApproveSupportRequestCancelOrder = async (
    supportRequestId: string
  ) => {
    setIsLoading(true);
    const response = await Api.SupportRequest.approveSupportRequest({
      supportRequestId,
    });
    if (response?.data) {
      toast.success("Order Status is updated successfully");
      setIsReloadSupportRequestList((prev) => !prev);
      setIsOpenModal(false);
    }
    setIsLoading(false);
  };

  const updateSupportRequestCancelOrder = async ({
    admin_response,
    admin_reject,
  }: IUpdateSupportRequestCancelOrder) => {
    setIsLoading(true);
    if (!supportRequestCancelOrder?._id) {
      setIsLoading(false);
      return;
    }
    const dataToPost = {
      supportRequestId: supportRequestCancelOrder?._id,
      admin_response,
      admin_reject,
    };

    const response = await Api.SupportRequest.updateSupportRequest(dataToPost);
    if (response?.data) {
      toast.success("Order Status is updated successfully");
      reloadList();
      setIsReloadSupportRequestList((prev) => !prev);
      setIsOpenModal(false);
    }
    setIsLoading(false);
  };

  return (
    <OrderContext.Provider
      value={{
        isReloadList,
        reloadList,
        isReloadSupportRequestList,
        setIsReloadSupportRequestList,
        formMode,
        setFormMode,
        isOpenModal,
        setIsOpenModal,
        modal,
        setModal,
        onCloseModal: closeModal,
        order,
        setOrder,
        supportRequestCancelOrder,
        setSupportRequestCancelOrder,
        node,
        setNode,
        onRejectCancel,
        onApproveCancel,
        updateSupportRequestCancelOrder,
        onApproveSupportRequestCancelOrder,
        isLoading,
      }}
    >
      {props.children}
    </OrderContext.Provider>
  );
};

export const OrderOutlet = (props: any) => {
  return (
    <OrderContextProvider>
      {props.children ? props.children : <Outlet />}
    </OrderContextProvider>
  );
};
export default OrderContext;
