import { AgGridReact } from "@ag-grid-community/react";
import { Button } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";
import React, { useContext, useRef } from "react";
import styled from "styled-components";
import Api from "../../../service";
import CommunityContext from "../context/community-context";
import ButtonCRUD from "../../../components/buttonCRUD";
import { CRUDType } from "../../../components/buttonCRUD";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const Wrap = styled.div``;

function ActionsCell(props: ICellRendererParams) {
  const communityCtx = useContext(CommunityContext);
  const { setIsOpenModal, setNode, setPost } = communityCtx;
  const onClickDetailBtn = async () => {
    setIsOpenModal(true);
    setNode(props.node);
    setPost(props.data);
  };
  return (
    <Container>
      <Wrap onClick={onClickDetailBtn}>
        <ButtonCRUD type={CRUDType.View} size="medium" />
      </Wrap>
    </Container>
  );
}

export default ActionsCell;
