import React, { useState } from "react";
import ConfirmModalV4 from "../../../../components/modal/ConfirmModal_V4";
import useProductKey from "../../ProductKey.hooks";
import Api from "../../../../service";
import { toast } from "react-toastify";
import LoadingPage from "../../../../components/LoadingPage";
import { useModal } from "../../../../hooks/use-modal";
import { ModalKey } from "../../../../context/modal-context";

const ProductKeyConfirmModal = () => {
  const { productKey, setIsReloadList } = useProductKey();
  const { closeModal, isModalOpen } = useModal();
  const [loading, setLoading] = useState(false);

  const onDeactivateHandler = async () => {
    try {
      if (!productKey) return;
      const response = await Api.ProductKey.deactivateProductKey({
        code: productKey.code,
        device_id: productKey.device_id,
        application: productKey.application,
      });
      if (response.data) {
        toast.success(`${productKey.code} is deactivated.`);
        closeModal(ModalKey.PRODUCT_KEY_DEACTIVATE);
        setIsReloadList((prev) => !prev);
      }
    } catch (error) {
      toast.error(error as string);
    } finally {
      setLoading(false);
    }
  };

  const onReactiveHandler = async () => {
    try {
      if (!productKey) return;
      const response = await Api.ProductKey.reactiveProductKey({
        code: productKey.code,
        device_id: productKey.device_id,
        application: productKey.application,
      });
      if (response.data) {
        toast.success(`${productKey.code} is reactivated.`);
        closeModal(ModalKey.PRODUCT_KEY_REACTIVATE);
        setIsReloadList((prev) => !prev);
      }
    } catch (error) {
      toast.error(error as string);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <LoadingPage />;

  return (
    <>
      <ConfirmModalV4
        isOpen={isModalOpen(ModalKey.PRODUCT_KEY_DEACTIVATE)}
        body="Are you sure you want to DEACTIVATE this code?"
        onClose={() => closeModal(ModalKey.PRODUCT_KEY_DEACTIVATE)}
        onConfirm={onDeactivateHandler}
      />
      <ConfirmModalV4
        isOpen={isModalOpen(ModalKey.PRODUCT_KEY_REACTIVATE)}
        body="Are you sure you want to REACTIVATE this code?"
        onClose={() => closeModal(ModalKey.PRODUCT_KEY_REACTIVATE)}
        onConfirm={onReactiveHandler}
      />
    </>
  );
};

export default ProductKeyConfirmModal;
