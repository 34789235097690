import { OrderOutlet } from "./context/order-context";
import Order from "./components/Order";

function OrderPage() {
  return (
    <OrderOutlet>
      <Order />
    </OrderOutlet>
  );
}

export default OrderPage;
