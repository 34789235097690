import { Box, Button, Modal } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";

export const styleModal = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};
interface IProps {
  isOpen: boolean;
  body?: string;
  title?: string;
  disable?: boolean;
  onClose: () => void;
  onConfirm?: () => void | Promise<void>;
  setIsDeleteAllChildren: React.Dispatch<React.SetStateAction<boolean>>;
}
const ConfirmModalCheckBox = ({
  isOpen,
  body,
  title,
  disable,
  onClose,
  onConfirm,
  setIsDeleteAllChildren,
}: IProps) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <CustomBox sx={{ ...styleModal, minWidth: 400, maxWidth: 700 }}>
        <h2 id="parent-modal-title">{title ? title : "Confirmation"}</h2>
        <p id="parent-modal-description">{body}</p>
        <div>
          <input
            id="isDeleteAllChildren"
            type="checkbox"
            onChange={(e) => setIsDeleteAllChildren(e.target.checked)}
          />
          <label htmlFor="isDeleteAllChildren">
            Delete all children of collection?
          </label>
        </div>
        <div>
          <Button
            onClick={onClose}
            size="small"
            color="error"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            size="small"
            color="success"
            variant="contained"
            disabled={disable}
          >
            Confirm
          </Button>
        </div>
      </CustomBox>
    </Modal>
  );
};

export default ConfirmModalCheckBox;
export const CustomBox = styled(Box)`
  > h2 {
    font-size: 2rem;
    font-weight: bold;
    color: #212121;
    text-align: center;
  }
  > p {
    margin: 1rem 0 2rem 0;
    font-size: 1.4rem;
    font-weight: 500;
    color: rgb(119, 142, 168);

    > span {
      color: rgb(60, 111, 168);
      font-style: italic;
    }
  }
  > div {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;
    > button {
      font-size: 1.2rem;
      text-transform: capitalize;
    }
  }
`;
