import { ICellRendererParams } from "ag-grid-community";
import React from "react";

enum Type {
  Google = "Google",
  Apple = "Apple",
  DeviceId = "DeviceId",
  Email = "Email/Password",
}
function LoginTypeCell(props: ICellRendererParams) {
  const data = props.data;
  const type = () => {
    if (data.provider === "google") {
      return Type.Google;
    } else {
      return Type.Email;
    }
  };
  return <div>{type()}</div>;
}

export default LoginTypeCell;
