import React from "react";
import useMaterialsLibrary from "../../MaterialsLibrary.hooks";
import LoadingPage from "../../../../components/LoadingPage";
import useMaterialsLibraryCollection from "./MaterialsLibraryCollection.hooks";
import MaterialsCollectionTable from "./MaterialsCollectionTable";
import { MaterialType } from "../../../../constants/types";

const MaterialsLibraryCollection = () => {
  const { isReloadList } = useMaterialsLibrary();
  const { data: collectionsData, isLoading } = useMaterialsLibraryCollection({
    refresh: isReloadList,
  });

  if (isLoading) return <LoadingPage />;
  if (!collectionsData) return null;

  return (
    <MaterialsCollectionTable
      data={collectionsData}
      type={MaterialType.COLLECTION}
    />
  );
};

export default MaterialsLibraryCollection;
