import { ICellRendererParams } from "ag-grid-community";
import styled from "styled-components";
import { saveAs } from "file-saver";

import ButtonCRUD, { CRUDType } from "../../../../components/buttonCRUD";
import React from "react";
import { useModal } from "../../../../hooks/use-modal";
import { ModalKey } from "../../../../context/modal-context";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { FormMode, SearchParamsKey } from "../../../../constants/types";
import { IMaterial } from "../../../../constants/interface";
import axios from "axios";

function ActionsCell(props: ICellRendererParams) {
  const { openModal } = useModal();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const isOtherPage = pathname.includes("others");

  const material = props.data as IMaterial;
  const handleDownload = async (url: string, filename: string) => {
    const link = document.createElement("a");
    link.href = url;
    // Append the link to the document body
    document.body.appendChild(link);
    // Click the link to download the file
    link.click();
    // Remove the link from the document body
    document.body.removeChild(link);
    // Revoke the temporary URL
    URL.revokeObjectURL(url);
  };
  return (
    <Container>
      {!isOtherPage ? (
        <ButtonCRUD
          type={CRUDType.Download}
          size="medium"
          onClick={() => {
            if (!material?.url) return;
            handleDownload(material.url, material?.title || material.type);
          }}
        />
      ) : (
        <ButtonCRUD
          type={CRUDType.Copy}
          size="medium"
          onClick={() => {
            if (material?.otherContent) {
              navigator.clipboard.writeText(material.otherContent);
            }
          }}
        />
      )}

      <ButtonCRUD
        type={CRUDType.Update}
        size="medium"
        onClick={() => {
          searchParams.set(SearchParamsKey.FormMode, FormMode.Edit);
          searchParams.set(SearchParamsKey.MaterialType, props.data.type);
          setSearchParams(searchParams);
          openModal(ModalKey.MATERIALS_LIBRARY_CREATE);
        }}
      />
      <ButtonCRUD
        type={CRUDType.Delete}
        size="medium"
        onClick={() => {
          searchParams.set(SearchParamsKey.MaterialType, props.data.type);
          setSearchParams(searchParams);
          openModal(ModalKey.MATERIALS_LIBRARY_DELETE);
        }}
      />
    </Container>
  );
}

export default React.memo(ActionsCell);

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
`;
