import React from "react";
import { CommentOutlet } from "./comment-context";
import Comments from "./Comments";

function Comment() {
  return (
    <div>
      <CommentOutlet>
        <Comments />
      </CommentOutlet>
    </div>
  );
}

export default Comment;
