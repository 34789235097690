import { useEffect, useState } from 'react'
import { IMaterialCollection } from '../../../../constants/interface';
import Api from '../../../../service';
import { toast } from 'react-toastify';

const useMaterialsLibraryCollection = ({ refresh }: { refresh?: boolean }) => {
    const [data, setData] = useState<IMaterialCollection[]>()
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async () => {
        try {
            setIsLoading(true)
            const res = await Api.MaterialsLib.getCollections()
            if (res.data) {
                setData(res.data)
            }
        } catch (error) {
            toast.error(error as string);
        } finally {
            setIsLoading(false)
        }

    }

    useEffect(() => {
        fetchData();
    }, [refresh])

    return {
        data,
        isLoading
    }
}

export default useMaterialsLibraryCollection