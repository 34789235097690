import { ICellRendererParams } from "ag-grid-community";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import OrderContext from "../../context/order-context";
import Api from "../../../../service";
import ButtonCRUD, {
  ButtonDisplayType,
  CRUDType,
} from "../../../../components/buttonCRUD";
import { SummaryKeys } from "../../constants";
import ConfirmModal from "../../../../components/modal/ConfirmModal";

export const ActionsCellContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  //overflow: auto;
`;
export const ActionsWrapper = styled.div`
  min-width: 150px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const ActionsWrap = styled.div``;

function ActionsCell(props: ICellRendererParams) {
  const data = props.data;
  const orderCtx = useContext(OrderContext);
  const { setIsOpenModal, setNode, setOrder, reloadList } = orderCtx;
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get("tab") || "All";
  const clickedBtn = searchParams.get("btn");

  const onClickEditBtn = async () => {
    searchParams.set("orderId", data._id);
    setSearchParams(searchParams);
    setIsOpenModal(true);
    setNode(props.node);
    setOrder(data);
  };

  const onProgress = async () => {
    const response = await Api.Order.inProgress({ orderId: data._id });
    if (response.data) {
      toast.success("Order Status is updated successfully");
      reloadList();
    }
  };
  const onConfirmOrder = async () => {
    const response = await Api.Order.confirm({ orderId: data._id });
    if (response.data) {
      toast.success("Order Status is updated successfully");
      reloadList();
    }
  };
  const onPack = async () => {
    const response = await Api.Order.pack({ orderId: data._id });
    if (response.data) {
      toast.success("Order Status is updated successfully");
      reloadList();
    }
  };
  const onShip = async () => {
    const response = await Api.Order.ship({ orderId: data._id });
    if (response.data) {
      toast.success("Order Status is updated successfully");
      reloadList();
    }
  };
  const onRejectCancel = async () => {
    const response = await Api.Order.rejectCancel({ orderId: data._id });
    if (response.data) {
      toast.success("Order Status is updated successfully");
      reloadList();
    }
  };
  const onApproveCancel = async () => {
    const response = await Api.Order.approveCancel({ orderId: data._id });
    if (response.data) {
      toast.success("Order Status is updated successfully");
      reloadList();
    }
  };
  const onRefund = async () => {
    const response = await Api.Order.refund({ orderId: data._id });
    if (response.data) {
      toast.success("Order is refunded successfully");
      reloadList();
    }
  };

  const onDelivered = async () => {
    const response = await Api.Order.delivered({ orderId: data._id });
    if (response.data) {
      toast.success("Order is delivered successfully");
      reloadList();
    }
  };

  const onClickBtn = async (type: CRUDType) => {
    searchParams.set("btn", type);
    setSearchParams(searchParams);
    setOpenModalConfirm(true);
  };

  const onConfirm = async () => {
    setDisableBtn(true);
    switch (clickedBtn) {
      case CRUDType.InProgress:
        await onProgress();
        break;
      case CRUDType.Confirm:
        await onConfirmOrder();
        break;
      case CRUDType.Pack:
        await onPack();
        break;
      case CRUDType.Ship:
        await onShip();
        break;
      case CRUDType.Reject:
        await onRejectCancel();
        break;
      case CRUDType.Approve:
        await onApproveCancel();
        break;
      case CRUDType.Refund:
        await onRefund();
        break;
      case CRUDType.Delivered:
        await onDelivered();
        break;
      default:
        break;
    }
    setDisableBtn(false);
  };
  const renderStatus = () => {
    let status = clickedBtn as string;
    switch (clickedBtn) {
      case CRUDType.Reject:
        status = CRUDType.InProgress;
        break;
      case CRUDType.Approve:
        status = "CANCELED";
        break;
      default:
        break;
    }
    return status;
  };
  return (
    <>
      <ActionsCellContainer>
        <ActionsWrapper>
          <ActionsWrap onClick={onClickEditBtn}>
            <ButtonCRUD
              displayType={ButtonDisplayType.Text}
              type={CRUDType.View}
              size="medium"
            />
          </ActionsWrap>
          {currentTab === SummaryKeys.InProgress && (
            <ActionsWrap onClick={() => onClickBtn(CRUDType.Confirm)}>
              <ButtonCRUD
                displayType={ButtonDisplayType.Text}
                type={CRUDType.Confirm}
                size="medium"
              />
            </ActionsWrap>
          )}
          {/* {currentTab === SummaryKeys.Confirmed && (
            <>
              <Wrap onClick={() => onClickBtn(CRUDType.InProgress)}>
                <ButtonCRUD
                  displayType={ButtonDisplayType.Text}
                  type={CRUDType.InProgress}
                  size="medium"
                />
              </Wrap>
              <Wrap onClick={() => onClickBtn(CRUDType.Pack)}>
                <ButtonCRUD
                  displayType={ButtonDisplayType.Text}
                  type={CRUDType.Pack}
                  size="medium"
                />
              </Wrap>
            </>
          )} */}
          {/* {currentTab === SummaryKeys.Packing && (
            <>
              <Wrap onClick={() => onClickBtn(CRUDType.Confirm)}>
                <ButtonCRUD
                  displayType={ButtonDisplayType.Text}
                  type={CRUDType.Confirm}
                  size="medium"
                />
              </Wrap>
              <Wrap onClick={() => onClickBtn(CRUDType.Ship)}>
                <ButtonCRUD
                  displayType={ButtonDisplayType.Text}
                  type={CRUDType.Ship}
                  size="medium"
                />
              </Wrap>
            </>
          )} */}
          {currentTab === SummaryKeys.RequestCancel && (
            <>
              <ActionsWrap onClick={() => onClickBtn(CRUDType.Reject)}>
                <ButtonCRUD
                  displayType={ButtonDisplayType.Text}
                  type={CRUDType.Reject}
                  size="medium"
                />
              </ActionsWrap>
              <ActionsWrap onClick={() => onClickBtn(CRUDType.Approve)}>
                <ButtonCRUD
                  displayType={ButtonDisplayType.Text}
                  type={CRUDType.Approve}
                  size="medium"
                />
              </ActionsWrap>
            </>
          )}
          {currentTab === SummaryKeys.Canceled && (
            <ActionsWrap onClick={() => onClickBtn(CRUDType.Refund)}>
              <ButtonCRUD
                displayType={ButtonDisplayType.Text}
                type={CRUDType.Refund}
                size="medium"
              />
            </ActionsWrap>
          )}

          {currentTab === SummaryKeys.Shipping && (
            <ActionsWrap onClick={() => onClickBtn(CRUDType.Delivered)}>
              <ButtonCRUD
                displayType={ButtonDisplayType.Text}
                type={CRUDType.Delivered}
                size="medium"
              />
            </ActionsWrap>
          )}
        </ActionsWrapper>

        <ConfirmModal
          isOpen={openModalConfirm}
          setIsOpen={setOpenModalConfirm}
          onConfirm={onConfirm}
          status={renderStatus()}
          disabled={disableBtn}
        />
      </ActionsCellContainer>
    </>
  );
}

export default ActionsCell;
