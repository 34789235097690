import ListEvent from "./components/Events";
import { EventOutlet } from "./context/event-context";

function Event() {
  return (
    <div>
      <EventOutlet>
        <ListEvent />
      </EventOutlet>
    </div>
  );
}

export default Event;
