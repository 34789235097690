import { useContext } from 'react'
import MaterialsLibraryContext from './context/materials-lib-context';

const useMaterialsLibrary = () => {
    const materialsCtx = useContext(MaterialsLibraryContext);
    if (!materialsCtx) throw new Error('useMaterialsLibrary must be used within a MaterialsLibraryProvider');

    return materialsCtx;
}

export default useMaterialsLibrary