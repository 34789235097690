import styled from "styled-components";
import TextField from "@mui/material/TextField";
import ReactQuill from "react-quill";
import { useMemo, useRef, useState } from "react";
import Api from "../../../service";
import { TabPanel } from "@mui/lab";

const CustomTabPanel = styled(TabPanel)<{ height?: string }>`
  width: 100%;
  background-color: #fff;
  padding: 24px 0 !important;
  ${({ height }) => height && { height: height }}
`;

const ColumnWrap = styled.div<{ widthPercent?: number }>`
  display: flex;
  flex-direction: column;
  width: ${({ widthPercent }) => (widthPercent ? `${widthPercent}%` : "100%")};
  gap: 16px;
  .MuiFormHelperText-root {
    color: #d32f2f;
  }
`;

const ErrorText = styled.p`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 4px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
  color: rgb(211, 47, 47);
`;

interface ILang {
  lang: string;
  title: string;
  description: string;
  body: string;
}

function CustomTabComponent({
  value,
  lang,
  setLang,
  setIsLoading,
  isCheckerror,
  setIsCheckerror,
  isShowError,
}: {
  value: string;
  lang: ILang;
  setLang: React.Dispatch<React.SetStateAction<ILang>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isCheckerror: boolean;
  setIsCheckerror: React.Dispatch<React.SetStateAction<boolean>>;
  isShowError?: boolean;
}) {
  const refEditor = useRef<ReactQuill>(null);

  const handleUploadImages = async (file: any) => {
    if (!file) return "";
    let formData = new FormData();
    formData.append("image", file);
    try {
      const responsive = await Api.Media.uploadImage(formData);
      return responsive.data;
    } catch (error) {
      console.log("Upload file error:", error);
    }
  };

  const handleImageEditor = async () => {
    console.log("handleImageEditor");
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      setIsLoading(true);
      try {
        var file: any = input && input.files ? input.files[0] : null;
        const imageUrl = await handleUploadImages(file);
        const range = refEditor.current?.getEditorSelection();
        refEditor.current
          ?.getEditor()
          .insertEmbed(range?.index || 0, "image", imageUrl);
      } catch (error) {
        console.log("handle Image Editor has error !!", error);
      } finally {
        setIsLoading(false);
      }
    };
  };

  const modulesEditor = useMemo(() => {
    return {
      toolbar: {
        container: [
          [{ header: "1" }, { header: "2" }, { font: [] }],
          [{ size: [] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image"],
          ["clean"],
        ],
        handlers: {
          image: handleImageEditor,
        },
      },
    };
  }, []);

  return (
    <CustomTabPanel value={value}>
      <ColumnWrap
        style={{ alignItems: "center", position: "relative" }}
        widthPercent={100}
      >
        <ColumnWrap widthPercent={100}>
          <TextField
            name="title"
            helperText={
              isShowError && isCheckerror && !lang.title && "is required"
            }
            value={lang.title}
            onFocus={() => setIsCheckerror(false)}
            onChange={(e) => setLang({ ...lang, title: e.target.value })}
            fullWidth
            id="outlined"
            label="Title"
            size="small"
          />
        </ColumnWrap>
        <ColumnWrap widthPercent={100}>
          <TextField
            name="description"
            helperText={
              isShowError && isCheckerror && !lang.description && "is required"
            }
            value={lang.description}
            onFocus={() => setIsCheckerror(false)}
            onChange={(e) =>
              setLang({
                ...lang,
                description: e.target.value,
              })
            }
            fullWidth
            multiline={true}
            rows={2}
            id="outlined"
            label="Description"
            size="small"
          />
        </ColumnWrap>
        <ColumnWrap widthPercent={100}>
          <div style={{ height: 600 }}>
            <ReactQuill
              ref={refEditor}
              modules={modulesEditor}
              style={{ height: 530 }}
              id="body"
              theme="snow"
              value={lang.body}
              onFocus={() => setIsCheckerror(false)}
              onChange={(e) => setLang({ ...lang, body: e })}
            />
          </div>
          {isShowError &&
            isCheckerror &&
            (lang.body === "<p><br></p>" || !lang.body) && (
              <ErrorText style={{ marginTop: -40 }}>is required</ErrorText>
            )}
        </ColumnWrap>
      </ColumnWrap>
    </CustomTabPanel>
  );
}

export default CustomTabComponent;
