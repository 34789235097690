import React, { useContext, useEffect, useState } from "react";

import Api from "../../../service";
import OrderContext from "../context/order-context";
import { ISummary } from "../components/summary/Summary";

const useSummary = () => {
  const [summariesData, setSummariesData] = useState<ISummary | null>(null);
  const { isReloadList } = useContext(OrderContext);
  useEffect(() => {
    Api.Order.getSummaryOrders()
      .then((response) => {
        if (response.data) {
          setSummariesData(response.data);
        }
      })
      .catch((error) => {
        console.log("error:", error);
      });
  }, [isReloadList]);

  return { summariesData };
};

export default useSummary;
