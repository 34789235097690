import React, { useState } from "react";
import { IModalProps } from "../../../../constants/interface";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import styled from "styled-components";
import { LoadingButton } from "@mui/lab";
import Api from "../../../../service";
import { toast } from "react-toastify";
import useProductKey from "../../ProductKey.hooks";
import { ProductKeyApplication } from "../../constants";
import { useModal } from "../../../../hooks/use-modal";
import { ModalKey } from "../../../../context/modal-context";

const FakeInputCodeModal = () => {
  const { isModalOpen, closeModal } = useModal();
  const [code, setCode] = useState<string>("");
  const [applicationName, setApplicationName] = useState(
    ProductKeyApplication.FriendifyAI
  );
  const [loading, setLoading] = useState(false);
  const { setIsReloadList } = useProductKey();

  const onApplyCodeHandler = async () => {
    setLoading(true);
    try {
      const response = await Api.ProductKey.activeProductKey({
        code,
        device_id: "Test123",
        application: applicationName,
      });
      if (response.data) {
        toast.success(`${code} is activated successfully`);
        closeModal(ModalKey.PRODUCT_KEY_FAKE_INPUT);
        setCode("");
        setIsReloadList((prev) => !prev);
      }
    } catch (error) {
      console.log("error:", error);
      toast.error(error as string);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    setApplicationName(event.target.value as ProductKeyApplication);
  };

  return (
    <CustomModal
      open={isModalOpen(ModalKey.PRODUCT_KEY_FAKE_INPUT)}
      onClose={() => closeModal(ModalKey.PRODUCT_KEY_FAKE_INPUT)}
    >
      <Container>
        <h1>Apply code</h1>
        <TextField
          label="Code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
        <FormControl fullWidth>
          <InputLabel id="application-name">Application Name</InputLabel>
          <Select
            labelId="application-name"
            value={applicationName}
            label="Application Name"
            onChange={handleChange}
          >
            <MenuItem value={ProductKeyApplication.PoolsWallet}>
              {ProductKeyApplication.PoolsWallet}
            </MenuItem>
            <MenuItem value={ProductKeyApplication.FriendifyAI}>
              {ProductKeyApplication.FriendifyAI}
            </MenuItem>
          </Select>
        </FormControl>

        <CustomButton
          variant="outlined"
          loading={loading}
          loadingPosition="start"
          onClick={onApplyCodeHandler}
        >
          Apply
        </CustomButton>
      </Container>
    </CustomModal>
  );
};

export default FakeInputCodeModal;

const Container = styled.div`
  padding: 2rem 4rem;
  max-width: 40rem;
  width: 30rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  border-radius: 4px;
`;

const CustomModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomButton = styled(LoadingButton)`
  margin-top: 2rem !important;
  background-color: #00a6ff !important;
  color: #fff !important;
`;
