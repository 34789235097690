import { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import Modal from "@mui/material/Modal";
import {
  FormControl,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import "react-quill/dist/quill.snow.css";
import { Box } from "@mui/material";
import { useModal } from "../../../../hooks/use-modal";
import { ModalKey } from "../../../../context/modal-context";
import { useSearchParams } from "react-router-dom";
import {
  FormMode,
  MaterialType,
  SearchParamsKey,
  VideoRatio,
} from "../../../../constants/types";
import Api from "../../../../service";
import { toast } from "react-toastify";
import useMaterialsLibrary from "../../MaterialsLibrary.hooks";
import Video from "../../../../components/video/Video";
import PreviewText from "../../../../components/preview/PreviewText";
import useMaterialsLibraryCollection from "../../sub-pages/collection/MaterialsLibraryCollection.hooks";

interface IPhoto {
  urlPreview?: string;
  formDataValue: string;
  fileName?: string;
}
interface IMaterialToPost {
  title: string;
  url?: string;
  size: number;
  type: MaterialType;
  collection_id: string;
  thumbnail?: string;
  tags?: string;
  otherContent?: string;
  ratio?: VideoRatio;
}
interface IFormError {
  message: string;
  field: string;
}
enum FormField {
  title = "title",
  tags = "tags",
  file = "file",
  thumbnail = "thumbnail",
  collection = "collection",
  content = "content",
}
const videoRatios = ["1:1", "16:9", "9:16"];

function MaterialCreateModal() {
  const { isModalOpen, closeModal } = useModal();
  const { data: collectionData } = useMaterialsLibraryCollection({});
  const { material, setMaterial, refreshTable } = useMaterialsLibrary();
  const [searchParams, setSearchParams] = useSearchParams();

  const materialType = searchParams.get(
    SearchParamsKey.MaterialType
  ) as MaterialType;

  const formMode = searchParams.get(SearchParamsKey.FormMode);
  const isCreateMode = formMode === FormMode.Create;

  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState<string[]>([]);

  const [chosenFile, setChosenFile] = useState<IPhoto>();
  const [chosenThumbnail, setChosenThumbnail] = useState<IPhoto>();
  const [title, setTitle] = useState("");
  const [tags, setTags] = useState("");
  const [content, setContent] = useState("");

  const [selectedCollection, setSelectedCollection] = useState<string>("");
  const [selectedRatio, setSelectedRatio] = useState<VideoRatio>("1:1");

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedCollection(event.target.value);
  };
  const handleRatioChange = (event: SelectChangeEvent) => {
    setSelectedRatio(event.target.value as VideoRatio);
  };

  const validateForm = () => {
    let isValid = true;
    if (!title && !formError.includes(FormField.title)) {
      setFormError((prev) => [...prev, FormField.title]);
      isValid = false;
    } else {
      setFormError((prev) => prev.filter((item) => item !== FormField.title));
    }

    if (tags) {
      const tagsArr = tags.split(",");
      if (tagsArr.length > 10 && !formError.includes(FormField.tags)) {
        setFormError((prev) => [...prev, FormField.tags]);
        isValid = false;
      } else {
        setFormError((prev) => prev.filter((item) => item !== FormField.tags));
      }
    }

    if (materialType === MaterialType.OTHER) {
      if (!content && !formError.includes(FormField.content)) {
        setFormError((prev) => [...prev, FormField.content]);
        isValid = false;
      }
    } else {
      if (
        formMode !== FormMode.Edit &&
        !chosenFile?.urlPreview &&
        !formError.includes(FormField.file)
      ) {
        setFormError((prev) => [...prev, FormField.file]);
        isValid = false;
      } else {
        setFormError((prev) => prev.filter((item) => item !== FormField.file));
      }

      if (
        formMode !== FormMode.Edit &&
        materialType === MaterialType.VIDEO &&
        !chosenThumbnail?.urlPreview &&
        !formError.includes(FormField.thumbnail)
      ) {
        setFormError((prev) => [...prev, FormField.thumbnail]);
        isValid = false;
      } else {
        setFormError((prev) =>
          prev.filter((item) => item !== FormField.thumbnail)
        );
      }
    }
    return isValid;
  };

  const handleCapture = (event: any) => {
    const file = event.target.files[0];
    if (!file) return;
    const url = URL.createObjectURL(file);
    setChosenFile({
      urlPreview: url,
      formDataValue: file,
      fileName: file.name,
    });
  };

  const handleCaptureThumbnail = (event: any) => {
    const file = event.target.files[0];
    if (!file) return;
    const url = URL.createObjectURL(file);
    setChosenThumbnail({
      urlPreview: url,
      formDataValue: file,
      fileName: file.name,
    });
  };
  const handleUploadImage = async () => {
    if (!chosenFile?.formDataValue) return;
    let formData = new FormData();
    formData.append("image", chosenFile.formDataValue);
    try {
      const response = await Api.MaterialsLib.uploadImage(formData);
      return response.data;
    } catch (error) {
      console.log("Upload file error:", error);
    }
  };
  const handleUploadThumbnail = async () => {
    if (!chosenThumbnail?.formDataValue) return;
    let formData = new FormData();
    formData.append("image", chosenThumbnail.formDataValue);
    try {
      const response = await Api.MaterialsLib.uploadImage(formData);
      return response.data;
    } catch (error) {
      console.log("Upload file error:", error);
    }
  };
  const handleUploadVideo = async () => {
    if (!chosenFile?.formDataValue) return;
    let formData = new FormData();
    formData.append("video", chosenFile.formDataValue);
    try {
      const response = await Api.MaterialsLib.uploadVideo(formData);
      return response.data;
    } catch (error) {
      console.log("Upload file error:", error);
    }
  };
  const handleUploadOther = async () => {
    if (!chosenFile?.formDataValue) return;
    let formData = new FormData();
    formData.append("file", chosenFile.formDataValue);
    try {
      const response = await Api.MaterialsLib.uploadOther(formData);
      return response.data;
    } catch (error) {
      console.log("Upload file error:", error);
    }
  };
  const handleUploadFile = async () => {
    switch (materialType) {
      case MaterialType.VIDEO:
        return handleUploadVideo();
      case MaterialType.IMAGE:
        return handleUploadImage();
      // case MaterialType.OTHER:
      //   return handleUploadOther();
    }
  };
  const handleCloseModal = () => {
    closeModal(ModalKey.MATERIALS_LIBRARY_CREATE);
    searchParams.delete(SearchParamsKey.FormMode);
    setSearchParams(searchParams);
    setMaterial(null);
    setChosenFile(undefined);
  };

  const handleSubmitForm = async (e: any) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const isValidForm = validateForm();
      if (!isValidForm) {
        return;
      }

      let materialToPost: IMaterialToPost = {
        title,
        size: 0,
        type: materialType,
        collection_id: selectedCollection,
        tags,
      };

      if (materialType === MaterialType.OTHER) {
        materialToPost.otherContent = content;
      } else {
        const uploadedFile = chosenFile?.formDataValue
          ? await handleUploadFile()
          : { url: chosenFile?.urlPreview, size: 0 };

        if (!uploadedFile) return;
        if (!materialType) return toast.error("Material type not found");
        materialToPost.url = uploadedFile?.url;
        materialToPost.size = +uploadedFile?.size;
      }
      if (materialType === MaterialType.VIDEO) {
        const uploadedThumbnail = chosenThumbnail?.formDataValue
          ? await handleUploadThumbnail()
          : { url: chosenThumbnail?.urlPreview, size: 0 };

        materialToPost.thumbnail = uploadedThumbnail?.url;
        materialToPost.ratio = selectedRatio;
      }

      const response = isCreateMode
        ? await Api.MaterialsLib.createMaterial(materialToPost)
        : await Api.MaterialsLib.updateMaterial({
            ...materialToPost,
            material_id: material?._id || "",
          });
      if (response.data) {
        toast.success(
          material?._id
            ? "Material is updated successfully !"
            : "Create success !"
        );
        refreshTable();
        handleCloseModal();
      }
    } catch (error) {
      console.log("error:", error);
      toast.error(error as string);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBindingFormData = useCallback(() => {
    setChosenFile({
      urlPreview: material?.url || "",
      formDataValue: "",
      fileName: "",
    });
    setChosenThumbnail({
      urlPreview: material?.thumbnail || "",
      formDataValue: "",
      fileName: "",
    });
    setTitle(material?.title || "");
    if (material?.materials_collection) {
      setSelectedCollection(material?.materials_collection);
    }
    if (material?.ratio) {
      setSelectedRatio(material?.ratio);
    }
    setTags(material?.tags || "");
    setContent(material?.otherContent || "");
  }, [material]);

  useEffect(() => {
    handleBindingFormData();
  }, [handleBindingFormData]);

  return (
    <>
      <Modal
        open={isModalOpen(ModalKey.MATERIALS_LIBRARY_CREATE)}
        onClose={handleCloseModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box component="form">
          <FormWrap>
            <Form>
              <CloseWrap>
                <IconWrap fontSize="large" onClick={handleCloseModal}>
                  <CloseBtn />
                </IconWrap>
              </CloseWrap>
              <TitleWrap>
                <Title>Material</Title>
              </TitleWrap>
              <RowWrap>
                <ColumnWrap>
                  <TextField
                    name="title"
                    fullWidth
                    id="outlined"
                    label="Title *"
                    size="small"
                    value={title}
                    onBlur={() => {
                      if (formError.includes(FormField.title)) {
                        setFormError((prev) =>
                          prev.filter((item) => item !== FormField.title)
                        );
                      }
                    }}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  />
                  {formError.includes(FormField.title) && (
                    <ErrorInput>Can't be empty!</ErrorInput>
                  )}
                </ColumnWrap>
              </RowWrap>
              <RowWrap>
                <ColumnWrap>
                  <TextField
                    name="tags"
                    fullWidth
                    id="outlined"
                    label="Tags"
                    size="small"
                    value={tags}
                    onBlur={() => {
                      if (formError.includes(FormField.tags)) {
                        setFormError((prev) =>
                          prev.filter((item) => item !== FormField.tags)
                        );
                      }
                    }}
                    onChange={(e) => {
                      setTags(e.target.value);
                    }}
                  />
                  {formError.includes(FormField.tags) && (
                    <ErrorInput>Tags is limited to 10</ErrorInput>
                  )}
                </ColumnWrap>
              </RowWrap>
              <RowWrap>
                <FormControl sx={{ minWidth: 200 }} size="small">
                  <InputLabel id="demo-select-small-label">
                    Collection
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    displayEmpty
                    value={selectedCollection}
                    label="Collection"
                    onChange={handleChange}
                  >
                    {collectionData?.map((collectionItem) => (
                      <MenuItem
                        key={collectionItem._id}
                        value={collectionItem._id}
                      >
                        {collectionItem.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </RowWrap>
              {/* image materials */}
              {materialType === MaterialType.IMAGE && (
                <RowWrap>
                  <ColumnWrap>
                    <FieldTitle>
                      Image{" "}
                      {!chosenFile?.urlPreview && (
                        <RequiredStart>*</RequiredStart>
                      )}
                    </FieldTitle>
                    <AndroidIOSWrap style={{ alignItems: "flex-start" }}>
                      <ColumnWrap style={{ maxWidth: 300 }}>
                        <ColumnWrap style={{ maxWidth: 300 }}>
                          <input
                            name="thumbnail"
                            onChange={handleCapture}
                            // accept="image/*"
                            id="icon-button-photo-android"
                            //multiple
                            type="file"
                            style={{ display: "none" }}
                          />
                          <label htmlFor="icon-button-photo-android">
                            <UploadWrap>
                              <IconWrap fontSize="large">
                                <CameraBtn />
                              </IconWrap>
                              <UploadText>Select image to upload</UploadText>
                              <UploadTextNote>
                                (ratio: 2x3 - pixel: 800 x 1200)
                              </UploadTextNote>
                            </UploadWrap>
                          </label>
                          {formError.includes(FormField.file) && (
                            <ErrorText>is required</ErrorText>
                          )}
                        </ColumnWrap>
                      </ColumnWrap>
                      <ColumnWrap widthPercent={50}>
                        {chosenFile?.urlPreview && (
                          <AndroidIOSWrap>
                            <PhotoWrap>
                              <Photo
                                src={chosenFile.urlPreview}
                                alt="thumbnail"
                                loading="lazy"
                              />
                            </PhotoWrap>
                          </AndroidIOSWrap>
                        )}
                      </ColumnWrap>
                    </AndroidIOSWrap>
                  </ColumnWrap>
                </RowWrap>
              )}
              {/* video materials */}
              {materialType === MaterialType.VIDEO && (
                <>
                  <RowWrap>
                    <FormControl sx={{ minWidth: 200 }} size="small">
                      <InputLabel id="video-ratio">Ratio</InputLabel>
                      <Select
                        labelId="video-ratio"
                        id="video-ratio"
                        displayEmpty
                        value={selectedRatio}
                        label="Ratio"
                        onChange={handleRatioChange}
                      >
                        {videoRatios?.map((ratio) => (
                          <MenuItem key={ratio} value={ratio}>
                            {ratio}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </RowWrap>
                  <RowWrap>
                    <ColumnWrap>
                      <FieldTitle>
                        Thumbnail{" "}
                        {!chosenThumbnail?.urlPreview && (
                          <RequiredStart>*</RequiredStart>
                        )}
                      </FieldTitle>
                      <AndroidIOSWrap style={{ alignItems: "flex-start" }}>
                        <ColumnWrap style={{ maxWidth: 300 }}>
                          <ColumnWrap style={{ maxWidth: 300 }}>
                            <input
                              name="thumbnail"
                              onChange={handleCaptureThumbnail}
                              accept="image/*"
                              id="thumbnail"
                              //multiple
                              type="file"
                              style={{ display: "none" }}
                            />
                            <label htmlFor="thumbnail">
                              <UploadWrap>
                                <IconWrap fontSize="large">
                                  <CameraBtn />
                                </IconWrap>
                                <UploadText>Select image to upload</UploadText>
                                <UploadTextNote>
                                  (ratio: 2x3 - pixel: 800 x 1200)
                                </UploadTextNote>
                              </UploadWrap>
                            </label>
                            {formError.includes(FormField.file) && (
                              <ErrorText>is required</ErrorText>
                            )}
                          </ColumnWrap>
                        </ColumnWrap>
                        <ColumnWrap widthPercent={50}>
                          {chosenThumbnail?.urlPreview && (
                            <AndroidIOSWrap>
                              <PhotoWrap>
                                <Photo
                                  src={chosenThumbnail.urlPreview}
                                  alt="thumbnail"
                                  loading="lazy"
                                />
                              </PhotoWrap>
                            </AndroidIOSWrap>
                          )}
                        </ColumnWrap>
                      </AndroidIOSWrap>
                    </ColumnWrap>
                  </RowWrap>
                  <RowWrap>
                    <ColumnWrap>
                      <FieldTitle>
                        Video{" "}
                        {!chosenFile?.urlPreview && (
                          <RequiredStart>*</RequiredStart>
                        )}
                      </FieldTitle>
                      <AndroidIOSWrap style={{ alignItems: "flex-start" }}>
                        <ColumnWrap style={{ maxWidth: 300 }}>
                          <ColumnWrap style={{ maxWidth: 300 }}>
                            <input
                              name="video"
                              onChange={handleCapture}
                              accept="video/*"
                              id="video"
                              //multiple
                              type="file"
                              style={{ display: "none" }}
                            />
                            <label htmlFor="video">
                              <UploadWrap>
                                <IconWrap fontSize="large">
                                  <CameraBtn />
                                </IconWrap>
                                <UploadText>Select video to upload</UploadText>
                              </UploadWrap>
                            </label>
                            {formError.includes(FormField.thumbnail) && (
                              <ErrorText>is required</ErrorText>
                            )}
                          </ColumnWrap>
                        </ColumnWrap>
                        <ColumnWrap widthPercent={50}>
                          {chosenFile?.urlPreview && (
                            <>
                              <Video src={chosenFile.urlPreview} />
                              <span>{chosenFile.fileName}</span>
                            </>
                          )}
                        </ColumnWrap>
                      </AndroidIOSWrap>
                    </ColumnWrap>
                  </RowWrap>
                </>
              )}

              {/* other file materials */}
              {materialType === MaterialType.OTHER && (
                <RowWrap>
                  <ColumnWrap>
                    <TextField
                      name="content"
                      fullWidth
                      id="outlined"
                      label="Content"
                      size="small"
                      multiline
                      rows={10}
                      value={content}
                      onBlur={() => {
                        if (formError.includes(FormField.content)) {
                          setFormError((prev) =>
                            prev.filter((item) => item !== FormField.content)
                          );
                        }
                      }}
                      onChange={(e) => {
                        setContent(e.target.value);
                      }}
                    />
                    {formError.includes(FormField.content) && (
                      <ErrorInput>Content must be not empty</ErrorInput>
                    )}
                  </ColumnWrap>
                </RowWrap>
                // <RowWrap>
                //   <ColumnWrap>
                //     <FieldTitle>
                //       File{" "}
                //       {!chosenFile?.urlPreview && (
                //         <RequiredStart>*</RequiredStart>
                //       )}
                //     </FieldTitle>
                //     <AndroidIOSWrap style={{ alignItems: "flex-start" }}>
                //       <ColumnWrap style={{ maxWidth: 300 }}>
                //         <ColumnWrap style={{ maxWidth: 300 }}>
                //           <input
                //             name="thumbnail"
                //             onChange={handleCapture}
                //             accept=".doc, .docx, .pdf, .ppt, .pptx, .xls, .xlsx, .txt"
                //             id="icon-button-photo-android"
                //             //multiple
                //             type="file"
                //             style={{ display: "none" }}
                //           />
                //           <label htmlFor="icon-button-photo-android">
                //             <UploadWrap>
                //               <IconWrap fontSize="large">
                //                 <CameraBtn />
                //               </IconWrap>
                //               <UploadText>Select file to upload</UploadText>
                //             </UploadWrap>
                //           </label>
                //           {formError && <ErrorText>is required</ErrorText>}
                //         </ColumnWrap>
                //       </ColumnWrap>
                //       <ColumnWrap widthPercent={50}>
                //         {chosenFile?.urlPreview && (
                //           <>
                //             {!chosenFile.formDataValue && (
                //               <PreviewText src={chosenFile.urlPreview} />
                //             )}
                //             <span>{chosenFile.fileName}</span>
                //           </>
                //         )}
                //       </ColumnWrap>
                //     </AndroidIOSWrap>
                //   </ColumnWrap>
                // </RowWrap>
              )}

              <ButtonWrap>
                <CreateButton
                  loading={isLoading}
                  disabled={isLoading}
                  loadingPosition="start"
                  startIcon={<></>}
                  onClick={handleSubmitForm}
                >
                  <ButtonText>{!isCreateMode ? "Update" : "Create"}</ButtonText>
                </CreateButton>
              </ButtonWrap>
            </Form>
          </FormWrap>
        </Box>
      </Modal>
    </>
  );
}

export default MaterialCreateModal;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
`;
const CreateButton = styled(LoadingButton)`
  padding: 8px 32px !important;
  background: rgb(85, 177, 71) !important;
  color: #ffffff !important;
  border-radius: 8px !important;
  cursor: pointer;
`;
const ButtonText = styled.span`
  font-size: 20px;
`;

const FormWrap = styled.div`
  overflow: hidden auto;
  height: 100vh;
`;

const Form = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  min-height: 400px;
  background: #fff;
  border-radius: 24px;
  width: 70%;
  margin: 5% auto;
`;

const RowWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 0 24px;
  width: 100%;
`;

const AndroidIOSWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 24px;
  gap: 24px;
`;

const ColumnWrap = styled.div<{ widthPercent?: number }>`
  display: flex;
  flex-direction: column;
  width: ${({ widthPercent }) => (widthPercent ? `${widthPercent}%` : "100%")};
  gap: 16px;
  .MuiFormHelperText-root {
    color: #d32f2f;
  }
`;

const TitleWrap = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  margin: 0;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.0025em;
  text-transform: uppercase;
  color: #222222;
`;

const RequiredStart = styled.span`
  vertical-align: middle;
  font-size: 20px;
  font-weight: 700;
  color: red;
`;

const ErrorText = styled.p`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 4px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
  color: rgb(211, 47, 47);
`;
const ErrorInput = styled.p`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  color: rgb(211, 47, 47);
`;

const UploadWrap = styled.div`
  cursor: pointer;
  width: 200px;
  height: 300px;
  border-radius: 8px;
  background-color: "rgba(0,0,0,.02)";
  border: 1px dashed #d9d9d9;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const UploadText = styled.span`
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #222222;
`;

const UploadTextNote = styled(UploadText)`
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #cccccc;
`;

const CameraBtn = styled(CloudUploadIcon)`
  width: 100% !important;
  height: 100% !important;
`;

const FieldTitle = styled.span`
  min-width: 200px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 35px;
  letter-spacing: -0.0025em;
  color: #222222;
`;

const PhotoWrap = styled.div`
  max-width: 300px;
  height: 200px;
  max-height: 200px;
`;

const Photo = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
`;

const CloseWrap = styled.div<{ isCloseLang?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: ${({ isCloseLang }) => (isCloseLang ? "0" : "0 24px")};

  ${({ isCloseLang }) =>
    isCloseLang && {
      position: "absolute",
      right: "0",
      top: "0",
    }};
`;

const IconWrap = styled(Icon)`
  cursor: pointer;
`;

const CloseBtn = styled(CloseIcon)`
  width: 100% !important;
  height: 100% !important;
`;
