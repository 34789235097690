import * as XLSX from "xlsx-js-style";

interface styleDataOfColumn {
    column: string;
    style: any;
}
interface colsWidth {
    width?: number;
}

export const handleExportToExcel = ({
    data,
    fileName,
    headers,
    styleDataOfColumns,
}: {
    data: any;
    fileName: string;
    headers: any[];
    styleDataOfColumns?: styleDataOfColumn[];
}) => {
    //Had to create a new workbook and then add the header
    const wb = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, headers);

    //Starting in the second row to avoid overriding and skipping headers
    XLSX.utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });

    const wsColsWidth: colsWidth[] = [];

    const range = XLSX.utils.decode_range(ws["!ref"] || "");
    for (let C = range.s.c; C <= range.e.c; ++C) {
        const column = XLSX.utils.encode_col(C);
        const cellHeader = column + "1"; // <-- first row, column number C

        //style header title
        ws[cellHeader] &&
            (ws[cellHeader].s = {
                font: {
                    sz: 14,
                    bold: true,
                    color: { rgb: "222222" },
                },
            });

        //style for data row
        const defaultCellStyle = {
            alignment: {
                wrapText: true,
            },
        };

        for (let row = range.s.r + 2; row <= range.e.r + 2; ++row) {
            const dataCell = column + row;
            if (!ws[dataCell]) continue;

            const styleOfColumn =
                Array.isArray(styleDataOfColumns) &&
                styleDataOfColumns?.find((item) => item.column === column);

            ws[dataCell].s =
                !!styleOfColumn && styleOfColumn?.style
                    ? styleOfColumn?.style
                    : defaultCellStyle;
        }
        //end style for data row

        //add width of column
        wsColsWidth.push({ width: 40 });
    }

    ws["!cols"] = wsColsWidth;
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.writeFile(wb, fileName);
};