import React from "react";
import { AgentOutlet } from "./context/agent-context";
import ListAgent from "./components/ListAgent";

function UserAgent() {
  return (
    <div>
      <ListAgent />
    </div>
  );
}

export default UserAgent;
