import { ICellRendererParams } from "ag-grid-community";
import { IProductKey, ProductKeyStatus } from "../../constants";

const StatusCell = (props: ICellRendererParams) => {
  const data = props.data as IProductKey;

  return (
    <span
      style={{
        color: data.status === ProductKeyStatus.Active ? "#4dd305" : "red",
        fontWeight: "700",
      }}
    >
      {data.status}
    </span>
  );
};

export default StatusCell;
