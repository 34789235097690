import { IRowNode } from "ag-grid-community";
import React, { createContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Post } from "../../../constants/interface";
import Api from "../../../service";
import { toast } from "react-toastify";

interface Props {
  children: React.ReactNode;
}
// Define the context interface
interface CommunityContextType {
  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;

  isOpenConfirmModal: boolean;
  setIsOpenConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;

  isReloadList: boolean;
  setIsReloadList: React.Dispatch<React.SetStateAction<boolean>>;

  typeSubmit: string;
  setTypeSubmit: React.Dispatch<React.SetStateAction<string>>;

  post: Post | null;
  setPost: React.Dispatch<React.SetStateAction<Post | null>>;

  node: any;
  setNode: React.Dispatch<any>;
}

// Create the context
const CommunityContext = createContext<CommunityContextType>({
  /*eslint-disable */
  isOpenModal: false,
  setIsOpenModal: () => {},
  isOpenConfirmModal: false,
  setIsOpenConfirmModal: () => {},
  isReloadList: false,
  setIsReloadList: () => {},
  typeSubmit: "",
  setTypeSubmit: () => {},
  post: null,
  setPost: () => {},
  node: "",
  setNode: () => {},
  /*eslint-disable */
});

// Custom Provider component
export const CommunityContextProvider = (props: Props) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
  const [isReloadList, setIsReloadList] = useState<boolean>(false);
  const [typeSubmit, setTypeSubmit] = useState<string>("");
  const [post, setPost] = useState<Post | null>(null);
  const [node, setNode] = useState<any | null>(null);

  return (
    <CommunityContext.Provider
      value={{
        isOpenModal,
        setIsOpenModal,
        isOpenConfirmModal,
        setIsOpenConfirmModal,
        isReloadList,
        setIsReloadList,
        typeSubmit,
        setTypeSubmit,
        post,
        setPost,
        node,
        setNode,
      }}
    >
      {props.children}
    </CommunityContext.Provider>
  );
};

export const CommunityOutlet = (props: any) => {
  return (
    <CommunityContextProvider>
      {props.children ? props.children : <Outlet />}
    </CommunityContextProvider>
  );
};
export default CommunityContext;
