import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import AuthContext from "../../context/auth-context";

function ProtectedRoute(props: any) {
  const authCtx = useContext(AuthContext);
  const { isLogin } = authCtx;

  if (!isLogin) {
    return <Navigate to={props.redirect} replace />;
  }
  return props.children ? props.children : <Outlet />;
}

export default ProtectedRoute;
