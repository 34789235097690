import React, { useEffect, useState } from "react";
import { Settings } from "../../../constants/interface";
import { Button, Switch, TextField } from "@mui/material";
import styled from "styled-components";
import Api from "../../../service";
import { toast } from "react-toastify";
import { ApplicationSettingTypes } from "../../../constants/types";

const CustomTextFiled = styled(TextField)<{ isNumber?: boolean }>`
  width: ${(p) => (p.isNumber ? "100px" : "300px")};
  input {
    font-size: 20px;
    padding: 4px !important;
    direction: ${(p) => (p.isNumber ? "rtl" : "ltr")} !important;
  }
`;
const Column = styled.div`
  font-size: 16px;
  padding: 5px 0;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
`;

const RowTable = styled.tr`
  width: 100%;
  display: grid;
  /* grid-template-columns: repeat(3, 1fr); */
  grid-template-columns: 2fr 2fr 1fr;
  align-items: center;
  padding: 0 10px;
  :not(:last-child) {
    border-bottom: 1px solid rgba(83, 83, 83, 0.1);
  }
`;

const EditBtn = styled(Button)`
  background-color: rgba(255, 134, 4, 0.7) !important;
  font-weight: 700 !important;
  color: #fff !important;
  transition: all 0.3s !important;
  :hover {
    filter: brightness(1.1);
    transform: scale(1.1);
  }
`;
const SaveBtn = styled(EditBtn)`
  background-color: rgba(0, 163, 0, 0.8) !important;
`;
const CancelBtn = styled(EditBtn)`
  background-color: rgba(255, 213, 0, 0.8) !important;
  color: #313131 !important;
`;
const CustomSwitch = styled(Switch)`
  margin-left: -10px !important;
`;

function SettingItem({ item }: { item: Settings }) {
  const [editMode, setEditMode] = useState(false);
  const [disableSwitch, setDisableSwitch] = useState(true);
  const [valueContent, setValueContent] = useState<any>(item.value);

  const onSaveHandler = async () => {
    const response = await Api.Admin.updateSettings({
      key: item.key,
      value: valueContent.toString(),
    });
    if (response) {
      toast.success(`${item.description} is updated successfully.`);
      setEditMode(false);
    }
  };

  useEffect(() => {
    // console.log("valueContent:", valueContent);
  }, [editMode]);

  return (
    <RowTable>
      <Column>{item.description}</Column>

      <Column>
        {!editMode ? (
          item.type !== ApplicationSettingTypes.Boolean ? (
            <span>{valueContent}</span>
          ) : (
            <CustomSwitch
              defaultChecked={valueContent === "true" ? true : false}
              checked={valueContent === "true" ? true : false}
              disabled={disableSwitch}
              onChange={(e: any) =>
                setValueContent(e.target.checked.toString())
              }
            />
          )
        ) : item.type !== ApplicationSettingTypes.Boolean ? (
          <CustomTextFiled
            id="outlined-basic"
            variant="outlined"
            type={item.type}
            value={valueContent}
            onChange={(e: any) => setValueContent(e.target.value)}
            isNumber={
              item.type === ApplicationSettingTypes.Number ? true : false
            }
          />
        ) : (
          <CustomSwitch
            defaultChecked={valueContent === "true" ? true : false}
            checked={valueContent === "true" ? true : false}
            disabled={disableSwitch}
            onChange={(e: any) => {
              setValueContent(e.target.checked.toString());
            }}
          />
        )}
      </Column>

      <Column>
        {!editMode ? (
          <EditBtn
            onClick={() => {
              setEditMode(true);
              setDisableSwitch(false);
            }}
          >
            Edit
          </EditBtn>
        ) : (
          <>
            <SaveBtn onClick={onSaveHandler}>Save</SaveBtn>
            <CancelBtn
              onClick={() => {
                setEditMode(false);
                setDisableSwitch(true);
              }}
            >
              Cancel
            </CancelBtn>
          </>
        )}
      </Column>
    </RowTable>
  );
}

export default SettingItem;
