import React from "react";
import { AgentOutlet } from "../../context/agent-context";
import PendingRequestList from "./components/PendingRequestList";

const PendingRequest = () => {
  return (
    <div>
      <PendingRequestList />
    </div>
  );
};

export default PendingRequest;
