import React, { useState } from "react";
import { IModalProps } from "../../../../constants/interface";
import { Modal, TextField } from "@mui/material";
import styled from "styled-components";
import { LoadingButton } from "@mui/lab";
import Api from "../../../../service";
import { toast } from "react-toastify";
import useProductKey from "../../ProductKey.hooks";
import { useModal } from "../../../../hooks/use-modal";
import { ModalKey } from "../../../../context/modal-context";

const CreateCodeModal = () => {
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(false);
  const { setIsReloadList } = useProductKey();
  const { isModalOpen, closeModal } = useModal();

  const onCreateHandler = async () => {
    setLoading(true);
    try {
      const response = await Api.ProductKey.createProductKeys({ quantity });
      if (response.data) {
        toast.success(
          `${quantity} code${quantity !== 1 && "s"} created successfully`
        );
        closeModal(ModalKey.PRODUCT_KEY_CREATE);
        setQuantity(1);
        setIsReloadList((prev) => !prev);
      }
    } catch (error) {
      console.log("error:", error);
      toast.error(error as string);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <CustomModal
        open={isModalOpen(ModalKey.PRODUCT_KEY_CREATE)}
        onClose={() => closeModal(ModalKey.PRODUCT_KEY_CREATE)}
      >
        <Container>
          <h1>Create Code</h1>
          <TextField
            label="Quantity"
            value={quantity}
            onChange={(e) => setQuantity(Number(e.target.value))}
          />
          <CustomButton
            variant="outlined"
            loading={loading}
            loadingPosition="start"
            onClick={onCreateHandler}
          >
            Create
          </CustomButton>
        </Container>
      </CustomModal>
    </>
  );
};

export default CreateCodeModal;

const Container = styled.div`
  padding: 2rem 4rem;
  max-width: 40rem;
  width: 30rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  border-radius: 4px;
`;
const CustomModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CustomButton = styled(LoadingButton)`
  margin-top: 2rem !important;
  background-color: #00a6ff !important;
  color: #fff !important;
`;
