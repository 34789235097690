import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "@ag-grid-community/react";
import {
  ColDef,
  FirstDataRenderedEvent,
  PaginationNumberFormatterParams,
} from "@ag-grid-community/core";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import styled from "styled-components";
import {
  AgentStatusHistory,
  NewsfeedComment,
} from "../../../../../../../constants/interface";

import { convertDateToYYYYMMDD_HHmm } from "../../../../../../../utils";
import Api from "../../../../../../../service";
import StatusField from "./StatusField";
import AgentContext from "../../../../../context/agent-context";

const Container = styled.div`
  width: 100%;
  height: 55vh;
  /* box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.2); */
  border-radius: 10px;
  position: relative;
  .ag-row {
    cursor: pointer !important;
  }
`;

const PageSizeContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: -50px;
`;

// Register the required feature modules with the Grid
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const RegistrationTracking = () => {
  const agentCtx = useContext(AgentContext);
  const { agentPending } = agentCtx;
  const gridRef = useRef<AgGridReact<AgentStatusHistory>>(null);
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState<AgentStatusHistory[]>();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    {
      field: "time",
      headerName: "Time",
      filter: false,
      cellStyle: { "font-weight": "600" },
    },
    {
      field: "code",
      headerName: "Application ID",
      cellStyle: { "font-weight": "600" },
    },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: StatusField,
    },
    {
      field: "note",
      headerName: "Note",
    },
    // {
    //   colId: "action",
    //   headerName: "",
    //   floatingFilter: false,
    //   cellRenderer: ActionsCell,
    //   filter: false,
    // },
  ]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      // editable: true,
      // enableRowGroup: true,

      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      // flex: 1,
      minWidth: 100,
    };
  }, []);
  const paginationNumberFormatter = useCallback(
    (params: PaginationNumberFormatterParams) => {
      return "[" + params.value.toLocaleString() + "]";
    },
    []
  );

  const onGridReady = useCallback(() => {
    Api.Agent.getAgentStatusHistories(agentPending?._id || "").then(
      (histories: any) => {
        const formattedDateListHistory = histories?.data?.map(
          (history: AgentStatusHistory) => {
            return {
              ...history,
              code: history?.code,
              status: history?.status,
              note: history?.note,
              time: `${convertDateToYYYYMMDD_HHmm(history.createdAt)}`,
            };
          }
        );
        setRowData(formattedDateListHistory);
        gridRef?.current?.api.sizeColumnsToFit({
          defaultMinWidth: 50,
          columnLimits: [
            { key: "time", maxWidth: 150 },
            { key: "code", maxWidth: 150 },
            { key: "status", maxWidth: 190 },
            // { key: "note", maxWidth: 150 },
            // { key: "action", maxWidth: 250 },
          ],
        });
      }
    );
  }, []);

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    gridRef.current!.api.paginationGoToPage(0);
    gridRef.current!.api.paginationSetPageSize(20);
  }, []);

  const onPageSizeChanged = useCallback(() => {
    var value = (document.getElementById("page-size") as HTMLInputElement)
      .value;
    gridRef.current!.api.paginationSetPageSize(Number(value));
  }, []);

  useEffect(() => {
    onGridReady();
  }, []);

  return (
    <Container>
      <div style={containerStyle}>
        <div
          className="example-wrapper"
          style={{ width: "100%", height: "100%", position: "relative" }}
        >
          <div style={gridStyle} className="ag-theme-alpine">
            <AgGridReact<AgentStatusHistory>
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows={true}
              pivotPanelShow={"always"}
              pagination={true}
              paginationPageSize={10}
              paginationNumberFormatter={paginationNumberFormatter}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
          </div>

          <PageSizeContainer className="example-header">
            Page Size:{" "}
            <select onChange={onPageSizeChanged} id="page-size">
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="500">500</option>
            </select>
          </PageSizeContainer>
        </div>
      </div>
    </Container>
  );
};
export default RegistrationTracking;
