import { AgGridReact } from "@ag-grid-community/react";
import { Button } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";
import React, { useContext, useRef } from "react";
import styled from "styled-components";
import Api from "../../../service";
import AgentContext from "../context/agent-context";
import ButtonCRUD from "../../../components/buttonCRUD";
import { CRUDType } from "../../../components/buttonCRUD";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
const AddBtn = styled(Button)`
  background-color: rgba(1, 1, 218, 0.5) !important;
  color: #fff !important;
`;

const DetailBtn = styled(Button)``;
const Wrap = styled.div``;

function ActionsCell(props: ICellRendererParams) {
  const agentCtx = useContext(AgentContext);
  const { setIsOpenModal, setIsOpenDetailModal, setNode, setAgent } = agentCtx;
  const onClickAddBtn = async () => {
    setIsOpenModal(true);
    setNode(props.node);
    setAgent(props.data);
  };
  const onClickDetailBtn = async () => {
    setIsOpenDetailModal(true);
    setNode(props.node);
    setAgent(props.data);
  };
  return (
    <Container>
      <Wrap onClick={onClickDetailBtn}>
        <ButtonCRUD type={CRUDType.View} size="medium" />
      </Wrap>
    </Container>
  );
}

export default ActionsCell;
