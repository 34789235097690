import styled from "styled-components";
import OrderTable from "./table/OrderTable";
import Summary from "./summary/Summary";
import { useSearchParams } from "react-router-dom";
import { SummaryKeys } from "../constants";
import SupportRequestCancelTable from "./support-request-cancel/SupportRequestCancelTable";
import { useContext } from "react";
import OrderContext from "../context/order-context";
import OrderMenu from "./modal/OrderMenu";
import ConfirmModalV3 from "../../../components/modal/ConfirmModal_V3";

function Order() {
  const [searchParams] = useSearchParams();
  const currentTab = searchParams.get("tab");
  const orderCtx = useContext(OrderContext);
  const { isOpenModal, setIsOpenModal, modal, onCloseModal } = orderCtx;
  if (modal) {
    var { onConfirm, title, body, disable, inputText, label, setInputText } =
      modal;
  }

  return (
    <Container>
      <SummaryContainer>
        <Summary />
      </SummaryContainer>
      <TableContainer>
        {currentTab === SummaryKeys.SupportRequestCancel ? (
          <SupportRequestCancelTable />
        ) : (
          <OrderTable />
        )}
      </TableContainer>
      {isOpenModal && (
        <OrderMenu isOpen={isOpenModal} setIsOpen={setIsOpenModal} />
      )}
      {modal && (
        <ConfirmModalV3
          isOpen={modal.isOpen}
          onClose={onCloseModal}
          onConfirm={onConfirm}
          title={title}
          body={body}
          disable={disable}
          inputText={inputText}
          label={label}
        />
      )}
    </Container>
  );
}

export default Order;

const TableContainer = styled.div`
  width: 100%;
  margin-top: 2rem;
`;
const SummaryContainer = styled.div`
  display: flex;
  gap: 1.2rem;
  padding: 1rem 1rem 2rem 1rem;
  overflow-x: auto;
  overflow-y: hidden;
`;

const Container = styled.div`
  width: 100%;
  padding: 2rem 0;
`;
