import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import UserContext from "../../../context/withdraw-context";
import styled from "styled-components";
import ButtonCRUD from "../../../../../components/buttonCRUD";
import { CRUDType } from "../../../../../components/buttonCRUD";
import { UserType } from "../../../../../constants/types";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import Api from "../../../../../service";
import { toast } from "react-toastify";
import WithdrawContext from "../../../context/withdraw-context";
import { convertDateToYYYYMMDD_HHmm } from "../../../../../utils";

const Container = styled.div`
  height: 100%;
  margin-top: 50px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 200px;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 32px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

const AvatarWrap = styled.div`
  min-width: 200px;
  max-width: 200px;
`;

const Avatar = styled.img`
  object-fit: cover;
  width: 100%;
  max-height: 200px;
  border-radius: 50%;
`;

const WrapText = styled.div`
  min-width: 250px;
`;

const TextStyle = styled.span`
  line-height: 40px;
  font-size: 20px;
  font-weight: 600;
  font-family: "Quicksand";
  font-style: normal;
  text-transform: capitalize;
  color: #222222;
`;

const TitleStyle = styled(TextStyle)`
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
  color: gray;
`;

const FullName = styled(TitleStyle)`
  font-size: 24px;
  font-weight: 700;
  color: #222222;
`;

const Email = styled(TextStyle)`
  font-weight: 600;
  font-size: 20px;
  text-transform: none;
  color: gray;
`;

const Wrap = styled.div``;

const WrapButton = styled.div`
  display: flex;
  gap: 24px;
`;

const CustomButton = styled(Button)<{ bgColor: string; width?: number }>`
  display: inline-block !important;
  vertical-align: middle !important;
  /* padding: 0 !important; */
  padding: 0 16px !important;
  margin: 0 !important;
  width: ${({ width }) => (width ? `${width}px` : "auto")};
  min-width: 35px !important;
  height: 35px !important;
  line-height: unset !important;
  background-color: ${({ bgColor }) =>
    bgColor ? `${bgColor} !important` : "#54B4D3 !important"};
  color: #fff !important;

  .MuiIcon-root {
    height: auto;
  }
`;

function History() {
  const { node, withdraw, setWithdraw } = useContext(WithdrawContext);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  return (
    <Container>
      <Wrapper>
        <Row style={{ gap: 160 }}>
          <Column>
            <Row>
              <WrapText>
                <TitleStyle>Full Name</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>{withdraw?.agent?.fullname}</TextStyle>
              </WrapText>
            </Row>
            <Row>
              <WrapText>
                <TitleStyle>Type</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>{withdraw?.withdraw_type}</TextStyle>
              </WrapText>
            </Row>
            <Row>
              <WrapText>
                <TitleStyle>Withdraw Account</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>{withdraw?.withdraw_account}</TextStyle>
              </WrapText>
            </Row>
            <Row>
              <WrapText>
                <TitleStyle>Status</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>{withdraw?.status}</TextStyle>
              </WrapText>
            </Row>
            <Row>
              <WrapText>
                <TitleStyle>Amount</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>{withdraw?.amount}$</TextStyle>
              </WrapText>
            </Row>
            <Row>
              <WrapText>
                <TitleStyle>Create</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>
                  {convertDateToYYYYMMDD_HHmm(withdraw?.createdAt)}
                </TextStyle>
              </WrapText>
            </Row>
          </Column>
        </Row>
      </Wrapper>
    </Container>
  );
}

export default History;
