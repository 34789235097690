import axios from "axios";
import type { AxiosInstance, AxiosResponse } from "axios";
import { toast } from "react-toastify";

export class RequestService {
  private instance: AxiosInstance;

  constructor() {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_DOMAIN_API,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    instance.interceptors.request.use(
      async (config) => {
        const accessToken = localStorage.getItem("x-access-token");
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }

        return config;
      },
      (error: any) => {
        toast.error(error.message);
        Promise.reject(error);
      }
    );

    instance.interceptors.response.use(
      (response: AxiosResponse) => {
        if (response && response.data) {
          return response.data;
        }
        return response;
      },
      (error) => {
        console.log("error:", error);
        if (error?.response?.status === 403) {
          localStorage.removeItem("x-access-token");
          return window.location.replace(`${window.location.origin}/login`);
        }
        toast.error(error?.response?.data?.message);
        return error?.response?.data;
      }
    );

    this.instance = instance;
  }

  get = (
    url: string,
    optional?: {
      params?: any;
      headers?: any;
    }
  ) => {
    return this.instance.get(url, {
      params: optional?.params,
      headers: optional?.headers,
    });
  };

  post = (
    url: string,
    optional?: {
      data?: any;
      headers?: any;
    }
  ) => {
    return this.instance.post(url, optional?.data, {
      headers: optional?.headers,
    });
  };

  put = (url: string, data?: any) => {
    return this.instance.put(url, data);
  };

  patch = (url: string, data?: any) => {
    return this.instance.patch(url, data);
  };

  delete = (url: string) => {
    return this.instance.delete(url);
  };
}

export const Request = new RequestService();
