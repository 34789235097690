import React, { createContext, useState } from "react";
import { Outlet } from "react-router-dom";

interface Props {
  children: React.ReactNode;
}

export enum ModalKey {
  // PRODUCT KEY PAGE
  PRODUCT_KEY_CREATE = "PRODUCT_KEY_CREATE",
  PRODUCT_KEY_FAKE_INPUT = "PRODUCT_KEY_FAKE_INPUT",
  PRODUCT_KEY_DEACTIVATE = "PRODUCT_KEY_DEACTIVATE",
  PRODUCT_KEY_REACTIVATE = "PRODUCT_KEY_REACTIVATE",
  //   Materials library
  MATERIALS_LIBRARY_CREATE = "MATERIALS_LIBRARY_CREATE",
  MATERIALS_LIBRARY_UPDATE = "MATERIALS_LIBRARY_UPDATE",
  MATERIALS_LIBRARY_DELETE = "MATERIALS_LIBRARY_DELETE",
}

// Define the context interface
interface ModalContextType {
  modals: ModalKey[];
  closeModal: (modalKey: ModalKey) => void;
  openModal: (modalKey: ModalKey) => void;
  isModalOpen: (modalKey: ModalKey) => boolean;
}

// Create the context
const ModalContext = createContext<ModalContextType | undefined>(undefined);

// Custom Provider component
export const ModalContextProvider = (props: Props) => {
  const [modals, setModals] = useState<ModalKey[]>([]);

  const openModal = (modalKey: ModalKey) => {
    if (!modals.includes(modalKey)) {
      setModals((prevModals) => [...prevModals, modalKey]);
    }
  };

  const closeModal = (modalKey: ModalKey) => {
    setModals((prevModals) => prevModals.filter((id) => id !== modalKey));
  };

  const isModalOpen = (modalKey: ModalKey) => modals.includes(modalKey);
  return (
    <ModalContext.Provider
      value={{
        modals,
        closeModal,
        isModalOpen,
        openModal,
      }}
    >
      {props.children}
    </ModalContext.Provider>
  );
};

export const ModalOutlet = (props: any) => {
  return (
    <ModalContextProvider>
      {props.children ? props.children : <Outlet />}
    </ModalContextProvider>
  );
};
export default ModalContext;
