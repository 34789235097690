import React, { useContext, useEffect, useState } from 'react'
import OrderContext from '../context/order-context';
import { ISelect } from "../constants"
import Api from '../../../service';
import { ICountry, IDistrict, IState } from '../../../constants/interface';


const useAddress = () => {
    const { order } = useContext(OrderContext);
    const [countries, setCountries] = useState<ICountry[] | []>([]);
    const [states, setStates] = useState<IState[] | []>([]);
    const [districts, setDistricts] = useState<IDistrict[] | []>([]);


    const fetchCountries = async () => {
        const res = await Api.Address.getCountries();
        if (res.data) {
            setCountries(res.data);
        }
    }
    const fetchCities = async (country_id: number) => {
        const res = await Api.Address.getCities({ country_id });
        if (res.data) {
            setStates(res.data);
        }
    }

    const fetchDistricts = async (country_id: number, state_id: number) => {
        const res = await Api.Address.getDistricts({ country_id, state_id });
        if (res.data) {
            setDistricts(res.data);
        }
    }

    useEffect(() => {

        if (order?.country?.id) {
            fetchCities(order.country.id);
        }
        if (order?.country?.id && order?.state?.id) {
            fetchDistricts(order.country.id, order.state.id)
        }
    }, [order?.country?.id, order?.state?.id])

    useEffect(() => {
        fetchCountries();
    }, [])

    return { countries, states, districts };
}

export default useAddress