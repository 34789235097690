import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { breakPoints } from "../../../../../constants/device";
import { Input } from "@mui/material";
import OrderContext, { FormMode } from "../../../context/order-context";
import { Order } from "../../../../../constants/interface";

interface IProps {
  title: string;
  content: string;
  mode?: FormMode;
  keyOfOrder: keyof Order;
}
const InputText = ({ title, content, mode, keyOfOrder }: IProps) => {
  const { order, setOrder } = useContext(OrderContext);
  const value = order ? order[keyOfOrder] : "";
  return (
    <RowWrap>
      <WrapText>
        <TitleStyle>{title}</TitleStyle>
      </WrapText>
      <WrapText>
        {mode === FormMode.Edit ? (
          <Input
            value={value}
            onChange={(e) =>
              setOrder((prev: any) => ({
                ...prev,
                [keyOfOrder]: e.target.value,
              }))
            }
          />
        ) : (
          <TextStyle>{content}</TextStyle>
        )}
      </WrapText>
    </RowWrap>
  );
};

export default InputText;
export const RowWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 0 24px;
  width: 100%;
`;
export const WrapText = styled.div`
  min-width: 220px;
  align-self: flex-start;
  @media (max-width: ${breakPoints.exDesktop}) {
    min-width: 160px;
  }
`;
export const TextStyle = styled.span`
  line-height: 40px;
  font-size: 20px;
  font-weight: 600;
  font-family: "Quicksand";
  font-style: normal;
  /* text-transform: capitalize; */
  color: #222222;
  @media (max-width: ${breakPoints.exDesktop}) {
    font-size: 16px;
  }
`;
export const TitleStyle = styled(TextStyle)`
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
  color: gray;
  @media (max-width: ${breakPoints.exDesktop}) {
    font-size: 16px;
  }
`;
