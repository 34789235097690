import React, { useContext, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import DHLIcon from "../../icons/dhl.svg";
import { breakPoints } from "../../../../constants/device";
import OrderContext from "../../context/order-context";

const Container = styled.div`
  width: 80%;
`;
const BreakLine = styled.div`
  background: #b1b1b1;
  display: block;
  width: 100%;
  height: 0.1rem;
  margin: 1rem 0;
`;
const Row = styled.div`
  width: 100%;
  display: flex;

  > div > span {
    color: var(--gray-scale-black-60, #525252);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 2.71.5rem */
    text-transform: uppercase;
  }
  > div:nth-child(1) {
    width: 70%;
  }
  > div:nth-child(2) {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    span {
      display: block;
      text-align: center;
    }
  }
  > div:nth-child(3) {
    width: 15%;
    span {
      direction: rtl;
      display: block;
    }
  }
`;
const ItemsContainer = styled.div`
  margin: 2.4rem 0;
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
  @media (max-width: ${breakPoints.ipad}) {
    margin: 1.5rem 0;
  }
`;
const InfoItem = styled.div`
  display: flex;
  gap: 1.5rem;
  @media (max-width: ${breakPoints.ipad}) {
    align-items: flex-start;
    width: 90vw;
    img {
      max-width: 7rem;
    }
  }
`;

const Thumbnail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  background-color: #f2f2f2;
  border-radius: 0.4rem;
  aspect-ratio: 1/1;
  > img {
    width: 1.5rem;
  }

  @media (min-width: ${breakPoints.laptop}) {
    width: 8rem;
    height: 8rem;
    border-radius: 1.7rem;
    > img {
      width: 3rem;
    }
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  > h3 {
    color: var(--gray-scale-black-80, #2d2d2d);
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 50 0;
  }
  > div > label {
    color: var(--gray-scale-black-40, #8f8f8f);
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 3.06rem */
    letter-spacing: 0.015rem;
    margin-right: 0.8rem;
  }
  > p {
    color: var(--gray-scale-black-40, #8f8f8f);

    /* BODY 3 */
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 2.1rem */
    letter-spacing: 0.014rem;
  }

  @media (max-width: ${breakPoints.ipad}) {
    row-gap: 1.5rem;
    flex-grow: 1;
    > h3 {
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 2.24rem */
      letter-spacing: 0.016rem;
    }
    > label {
      font-size: 1.5rem;
      line-height: 150%; /* 2.1rem */
      letter-spacing: 0.014rem;
    }
  }
`;
const OriginalPrice = styled.span`
  color: var(--gray-scale-black-40, #8f8f8f);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 3.06rem */
  letter-spacing: 0.018rem;
  text-decoration: line-through;
  margin-right: 0.5rem;
  @media (max-width: ${breakPoints.ipad}) {
    font-size: 1.5rem;
    line-height: 150%; /* 2.1rem */
    letter-spacing: 0.014rem;
  }
`;
const SalePrice = styled(OriginalPrice)`
  font-weight: 600;
  background: var(
    --GRADIENT,
    linear-gradient(237deg, #1268e9 -15.33%, #b43bf0 45.3%, #f64f59 105.7%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: ${breakPoints.ipad}) {
    font-size: 1.5rem;
    line-height: 150%; /* 2.1rem */
    letter-spacing: 0.014rem;
  }
`;
const QuantityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  > span {
    margin-top: 1rem;
    color: var(--gray-scale-black-40, #8f8f8f);
    text-align: center;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 2.71.5rem */
    letter-spacing: 0.016rem;
    text-decoration-line: underline;
    cursor: pointer;
  }
  @media (max-width: ${breakPoints.ipad}) {
    flex-direction: row;
    > span {
      margin-top: 0;
      margin-left: 1.5rem;
    }
  }
`;
const QuantityContainer = styled.div`
  width: 100%;
  padding: 0.3rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.4rem;
  border: 1px solid #b3b3b3;
  user-select: none !important;
  > span {
    cursor: pointer;
  }
  > label {
    color: var(--gray-scale-black-80, #2d2d2d);
    text-align: center;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 2.71.5rem */
    letter-spacing: 0.016rem;
  }
  @media (max-width: ${breakPoints.ipad}) {
    width: 40%;
    padding: 0.1rem 0.5rem;
  }
`;
const TotalPrice = styled.p`
  color: var(--gray-scale-black-80, #2d2d2d) !important;
  text-align: right;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 3.4rem */
  letter-spacing: 0.01.5rem;
`;

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  margin-top: 2.4rem;
  @media (max-width: ${breakPoints.ipad}) {
    row-gap: 1.5rem;
  }
`;
const Row2 = styled.div`
  display: flex;
  justify-content: space-between;
  > label {
    color: var(--gray-scale-black-60, #525252);
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 2.71.5rem */
    letter-spacing: 0.016rem;
    text-transform: uppercase;
  }
  p {
    color: var(--gray-scale-black-80, #2d2d2d);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 3.4rem */
    letter-spacing: 0.01.5rem;
  }
  @media (max-width: ${breakPoints.ipad}) {
    > label {
      font-size: 1.4rem;
      text-transform: capitalize;
    }
  }
`;

const GiftLabel = styled.div`
  display: flex;
  padding: 0.6rem 0.8rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.4rem;
  border: 0.1rem solid #f64f59;
  width: fit-content;
  /* text */
  color: #f64f59;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.018rem;
  @media (min-width: ${breakPoints.laptop}) {
    font-size: 1.5rem;
  }
`;

const GiftQuantity = styled.div`
  width: 100%;
  flex-direction: column;
  align-items: center !important;
  justify-content: start !important;
  text-align: center;
  row-gap: 1.5rem;
  > p {
    color: #8f8f8f;
    text-align: center;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27.2px */
    letter-spacing: 0.016rem;
  }
`;

const ShippingFee = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const Cart = () => {
  const { order } = useContext(OrderContext);
  if (!order || !order?.details) {
    toast.error("Order data is empty.");
    return <p>No Data</p>;
  }
  const { details } = order;

  return (
    <Container>
      <ItemsContainer>
        {details.map((item) => (
          <>
            <Row>
              <div>
                <InfoItem>
                  <Thumbnail>
                    <img src={item.product?.images[0]} alt="phone" />
                  </Thumbnail>
                  <TextContainer>
                    <h3>{item.product.name} / RAM 12GB / 256GB </h3>
                    <div>
                      <label>Unit Price: </label>
                      <OriginalPrice>
                        ${item.product.origin_price}
                      </OriginalPrice>
                      <SalePrice>${item.product.sale_price}</SalePrice>
                    </div>
                  </TextContainer>
                </InfoItem>
              </div>

              <>
                <div>
                  <QuantityWrapper>
                    <QuantityContainer>
                      <label style={{ flex: 1, textAlign: "center" }}>
                        {item.quantity}
                      </label>
                    </QuantityContainer>
                  </QuantityWrapper>
                </div>
                <div>
                  <TotalPrice>${item.product.sale_price}</TotalPrice>
                </div>
              </>
            </Row>
            {/* gifts */}
            {item.gifts.length > 0 &&
              item.gifts.map((gift) => (
                <Row>
                  <div>
                    <InfoItem>
                      <Thumbnail>
                        <img src={gift.product.images[0]} alt="phone pouch" />
                      </Thumbnail>
                      <TextContainer>
                        <h3>{gift.product.name}</h3>
                        <GiftLabel>Gift</GiftLabel>
                      </TextContainer>
                    </InfoItem>
                  </div>
                  <>
                    <GiftQuantity>
                      <QuantityContainer>
                        <label style={{ flex: 1, textAlign: "center" }}>
                          {item.quantity}
                        </label>
                      </QuantityContainer>
                    </GiftQuantity>
                    <div>
                      <TotalPrice>${0}</TotalPrice>
                    </div>
                  </>
                </Row>
              ))}
          </>
        ))}
      </ItemsContainer>
      <BreakLine />
      <SummaryContainer>
        <Row2>
          <label>Subtotal</label>
          <p>${order.total_amount}</p>
        </Row2>
        <Row2>
          <label>Shipping Fee</label>
          <ShippingFee>
            {/* <img src={DHLIcon} alt="DHL" /> */}
            <p>${order?.shipment_amount}</p>
          </ShippingFee>
        </Row2>

        <Row2>
          <label>Referral</label>
          <p>${order?.discount_amount || 0}</p>
        </Row2>
        <Row2>
          <p>Total</p>
          <p>${order.final_amount}</p>
        </Row2>
      </SummaryContainer>
    </Container>
  );
};

export default Cart;
