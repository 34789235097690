import React, { createContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Popup } from "../../../constants/interface";
import { toast } from "react-toastify";
import { LangType } from "../../../constants/types";

interface Props {
  children: React.ReactNode;
}
// Define the context interface
interface PopupContextType {
  isReloadList: boolean;
  setIsReloadList: React.Dispatch<React.SetStateAction<boolean>>;

  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;

  popup: Popup | null;
  setPopup: React.Dispatch<React.SetStateAction<Popup | null>>;

  node: any;
  setNode: React.Dispatch<any>;
}

// Create the context
const PopupContext = createContext<PopupContextType>({
  /*eslint-disable */
  isReloadList: false,
  setIsReloadList: () => {},
  isOpenModal: false,
  setIsOpenModal: () => {},
  popup: null,
  setPopup: () => {},
  node: "",
  setNode: () => {},
});

// Custom Provider component
export const PopupContextProvider = (props: Props) => {
  const [isReloadList, setIsReloadList] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [popup, setPopup] = useState<Popup | null>(null);
  const [node, setNode] = useState<any | null>(null);

  return (
    <PopupContext.Provider
      value={{
        isReloadList,
        setIsReloadList,
        isOpenModal,
        setIsOpenModal,
        popup,
        setPopup,
        node,
        setNode,
      }}
    >
      {props.children}
    </PopupContext.Provider>
  );
};

export const PopupOutlet = (props: any) => {
  return (
    <PopupContextProvider>
      {props.children ? props.children : <Outlet />}
    </PopupContextProvider>
  );
};
export default PopupContext;
