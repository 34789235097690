import React, { useEffect, useState } from "react";
import Container from "../../components/container/Container";
import { Settings } from "../../constants/interface";
import styled from "styled-components";
import SettingItem from "./components/SettingItem";
import { toast } from "react-toastify";
import Api from "../../service";

const listSettings: Settings[] = [
  {
    key: "reward_amount",
    value: "5",
    type: "number",
    description: "Reward",
  },
  {
    key: "reward_amount 2",
    value: "true",
    type: "Boolean",
    description: "Reward_amount 2",
  },
  {
    key: "reward_amount 3",
    value: "false",
    type: "Boolean",
    description: "Reward_amount 3",
  },
  {
    key: "reward_amount 3",
    value: "test value",
    type: "text",
    description: "Reward_amount 4",
  },
];

const Title = styled.h1`
  text-align: center;
`;
export const RowTitleTable = styled.tr`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  background-color: #fff;
  font-style: italic;
  border-bottom: 1px solid rgba(34, 34, 34, .2);

  color: #222;
  padding: 10px;
  th {
    text-align: left;
  }
`;
const ListSettingContainer = styled.table`
  margin-top: 50px;
  width: 100%;
  border: 1px solid rgba(34, 34, 34, .2);

`;
function Setting() {
  const [listSettings, setListSettings] = useState([]);

  const fetchSettings = async () => {
    const response = await Api.Admin.getSettings();
    setListSettings(response.data);
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  return (
    <Container>
      <Title>Settings</Title>
      <ListSettingContainer>
        <RowTitleTable>
          <th>Key</th>
          <th>Value</th>
          <th>Actions</th>
        </RowTitleTable>
        {listSettings.map((item, index) => (
          <SettingItem item={item} key={index} />
        ))}
      </ListSettingContainer>
    </Container>
  );
}

export default Setting;
