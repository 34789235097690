import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useEffect, useMemo } from "react";
import HomeIcon from "@mui/icons-material/Home";
import { Icon } from "@mui/material";

const HeaderWrapper = styled.div`
  /* position: fixed;
  box-shadow: rgba(255, 255, 255, 0.9) 0rem 0rem 0.0625rem 0.0625rem inset,
    rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
  backdrop-filter: saturate(200%) blur(1.875rem);
  background-color: rgba(255, 255, 255, 0.8);
  /* margin-left: 250px; */
  /* left: 250px;
  top: 0;
  right: 0; */
  min-height: 100px;
  padding: 16px 32px;
  /* z-index: 999; */
  border: none;
`;

const PathWrap = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-end;
`;

const IconWrap = styled(Icon)`
  cursor: pointer;
`;

const PathsWrap = styled.div``;

const LogoutBtn = styled(HomeIcon)`
  color: #222222;
  width: 100% !important;
  height: 100% !important;
`;

const Path = styled.span<{ isActive: boolean }>`
  color: ${({ isActive }) => (isActive ? "rgb(115, 131, 149)" : "gray")};
  text-transform: uppercase;
  font-size: 24px;
  font-weight: ${({ isActive }) => (isActive ? "600" : "500")};
`;

const Line = styled.span`
  color: gray;
  margin: 0 5px;
`;

function Header() {
  const { pathname, hash, key } = useLocation();
  const elementPaths = useMemo(() => {
    const paths = pathname.split("/");
    const customPaths = paths.filter((p) => p !== "");
    if (customPaths.length === 0) return <Path isActive={true}>{"Home"}</Path>;

    return customPaths.map((path, index) => {
      return index === customPaths.length - 1 ? (
        <Path key={index} isActive={true}>
          {path}
        </Path>
      ) : (
        <Path key={index} isActive={false}>
          {path}
          <Line>/</Line>
        </Path>
      );
    });
  }, [pathname, hash, key]);

  return (
    <HeaderWrapper>
      <PathWrap>
        {/* <IconWrap fontSize="large">
          <LogoutBtn />
        </IconWrap> */}
        <PathsWrap>{elementPaths}</PathsWrap>
      </PathWrap>
    </HeaderWrapper>
  );
}

export default Header;
