import React from "react";
import { CommunityOutlet } from "./context/community-context";
import ListPost from "./components/ListPost";

function Community() {
  return (
    <div>
      <CommunityOutlet>
        <ListPost />
      </CommunityOutlet>
    </div>
  );
}

export default Community;
