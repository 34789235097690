import { ICellRendererParams } from "ag-grid-community";
import styled from "styled-components";
import ButtonCRUD, { CRUDType } from "../../../../components/buttonCRUD";
import React from "react";
import { useModal } from "../../../../hooks/use-modal";
import { ModalKey } from "../../../../context/modal-context";
import { useSearchParams } from "react-router-dom";
import {
  FormMode,
  MaterialType,
  SearchParamsKey,
} from "../../../../constants/types";

function ActionsCell(props: ICellRendererParams) {
  const { openModal } = useModal();
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Container>
      <ButtonCRUD
        type={CRUDType.Update}
        size="medium"
        onClick={() => {
          searchParams.set(SearchParamsKey.FormMode, FormMode.Edit);
          searchParams.set(
            SearchParamsKey.MaterialType,
            MaterialType.COLLECTION
          );
          setSearchParams(searchParams);
          openModal(ModalKey.MATERIALS_LIBRARY_CREATE);
        }}
      />
      <ButtonCRUD
        type={CRUDType.Delete}
        size="medium"
        onClick={() => {
          searchParams.set(
            SearchParamsKey.MaterialType,
            MaterialType.COLLECTION
          );
          setSearchParams(searchParams);
          openModal(ModalKey.MATERIALS_LIBRARY_DELETE);
        }}
      />
    </Container>
  );
}

export default React.memo(ActionsCell);

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
`;
