import React from "react";

const BoxIcon3 = () => {
  return (
    <svg
      fill="#fff"
      height="800px"
      width="800px"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 467.2 467.2"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <path
            d="M455.146,120.9l-91.7-116.3c-2.3-2.9-5.7-4.6-9.4-4.6h-240.8c-3.7,0-7.1,1.7-9.4,4.6l-91.7,116.3
			c-1.7,2.1-2.6,4.7-2.6,7.4v326.9c0,6.6,5.4,12,12,12h424.1c6.6,0,12-5.4,12-12V128.3C457.746,125.6,456.846,123,455.146,120.9z
			 M422.546,118.3h-176.9V24h102.6L422.546,118.3z M119.046,24h102.6v94.3h-176.9L119.046,24z M33.546,443.2V142.3h400.1v300.9
			L33.546,443.2L33.546,443.2z"
          />
          <path
            d="M282.046,311.8l-36.5,36.5V211.6c0-6.6-5.4-12-12-12s-12,5.4-12,12v136.7l-36.3-36.3c-4.7-4.7-12.3-4.7-17,0
			s-4.7,12.3,0,17l56.8,56.8l0,0c0.3,0.3,0.5,0.5,0.8,0.8c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.2,0.5,0.4,0.7,0.5c0.1,0,0.1,0.1,0.2,0.1
			c0.3,0.2,0.5,0.3,0.8,0.5c0.1,0,0.1,0,0.2,0.1c0.3,0.2,0.6,0.3,0.9,0.4h0.1c0.3,0.1,0.7,0.3,1,0.4h0.1c0.3,0.1,0.7,0.2,1,0.3
			c0.1,0,0.2,0,0.3,0c0.3,0,0.6,0.1,0.9,0.1c0.4,0,0.8,0.1,1.2,0.1s0.8,0,1.2-0.1c0.3,0,0.6-0.1,0.9-0.1c0.1,0,0.2,0,0.3,0
			c0.4-0.1,0.7-0.2,1-0.3h0.1c0.3-0.1,0.7-0.2,1-0.4h0.1c0.3-0.1,0.6-0.3,0.9-0.4c0,0,0.1,0,0.1-0.1c0.3-0.1,0.5-0.3,0.8-0.5
			c0.1,0,0.1-0.1,0.2-0.1c0.2-0.2,0.5-0.3,0.7-0.5c0.1-0.1,0.2-0.1,0.2-0.2c0.3-0.2,0.6-0.5,0.8-0.8l0,0l57-57
			c4.7-4.7,4.7-12.3,0-17C294.346,307.1,286.746,307.1,282.046,311.8z"
          />
        </g>
      </g>
    </svg>
  );
};

export default BoxIcon3;
