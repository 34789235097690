function BackIcon({ width }: { width?: number }) {
  return (
    <svg
      width={width ? width : 18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1284_19626"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18"
      >
        <rect
          x="18"
          y="18"
          width="18"
          height="18"
          transform="rotate(-180 18 18)"
          fill="#738395"
        />
      </mask>
      <g mask="url(#mask0_1284_19626)">
        <path
          d="M8.77344 4.9501L14.3984 11.1528L13.0859 12.6001L8.77344 7.84469L4.46094 12.6001L3.14844 11.1528L8.77344 4.9501Z"
          fill="#738395"
        />
      </g>
    </svg>
  );
}

export default BackIcon;
