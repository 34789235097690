import { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import Modal from "@mui/material/Modal";
import { Icon, Switch, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import Api from "../../../service";
import PopupContext from "../context/popup-context";
import { LangType } from "../../../constants/types";
import { toast } from "react-toastify";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import "react-quill/dist/quill.snow.css";
import { TabContext, TabList } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
`;
const CreateButton = styled(LoadingButton)`
  padding: 8px 32px !important;
  background: rgb(85, 177, 71) !important;
  color: #ffffff !important;
  border-radius: 8px !important;
  cursor: pointer;
`;
const ButtonText = styled.span`
  font-size: 20px;
`;

const FormWrap = styled.div`
  overflow: hidden auto;
  height: 100vh;
`;

const Form = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  min-height: 400px;
  background: #fff;
  border-radius: 24px;
  width: 70%;
  margin: 5% auto;
`;

const RowWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 0 24px;
  width: 100%;
`;

const AndroidIOSWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 24px;
  gap: 24px;
`;

const ColumnWrap = styled.div<{ widthPercent?: number }>`
  display: flex;
  flex-direction: column;
  width: ${({ widthPercent }) => (widthPercent ? `${widthPercent}%` : "100%")};
  gap: 16px;
  .MuiFormHelperText-root {
    color: #d32f2f;
  }
`;

const TitleWrap = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  margin: 0;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.0025em;
  text-transform: uppercase;
  color: #222222;
`;

const RequiredStart = styled.span`
  vertical-align: middle;
  font-size: 20px;
  font-weight: 700;
  color: red;
`;

const ErrorText = styled.p`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 4px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
  color: rgb(211, 47, 47);
`;

const UploadWrap = styled.div`
  cursor: pointer;
  width: 300px;
  height: 300px;
  border-radius: 8px;
  background-color: "rgba(0,0,0,.02)";
  border: 1px dashed #d9d9d9;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const UploadText = styled.span`
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #222222;
`;

const UploadTextNote = styled(UploadText)`
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #cccccc;
`;

const CameraBtn = styled(CloudUploadIcon)`
  width: 100% !important;
  height: 100% !important;
`;

const FieldTitle = styled.span`
  min-width: 200px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 35px;
  letter-spacing: -0.0025em;
  color: #222222;
`;

const WrapDatePicker = styled.div`
  .MuiTextField-root {
    width: 100%;
    .MuiOutlinedInput-input {
      padding: 9px 14px;
    }
  }
`;

const PhotoWrap = styled.div`
  max-width: 300px;
  height: 200px;
  max-height: 200px;
`;

const Photo = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
`;

const CloseWrap = styled.div<{ isCloseLang?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: ${({ isCloseLang }) => (isCloseLang ? "0" : "0 24px")};

  ${({ isCloseLang }) =>
    isCloseLang && {
      position: "absolute",
      right: "0",
      top: "0",
    }};
`;

const IconWrap = styled(Icon)`
  cursor: pointer;
`;

const CloseBtn = styled(CloseIcon)`
  width: 100% !important;
  height: 100% !important;
`;

const CustomTabContext = styled(TabContext)`
  width: 100%;
  height: 100%;
  background-color: #fff;
`;

const CustomTab = styled(Tab)`
  font-size: 18px !important;
  font-weight: 700 !important;
  font-family: "Quicksand" !important;
  font-style: normal !important;
  text-transform: capitalize !important;
  color: #222222 !important;
`;

interface IPhoto {
  urlPreview?: string;
  formDataValue: string;
}

export interface ILang {
  lang: string;
  title: string;
  shortDescription: string;
  description: string;
  banner: string;
  checkedUseBannerForAll: boolean;
}

interface Iprops {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export enum BroadcastActionType {
  Send = "SEND",
  Unsend = "UNSEND",
}

export interface IBroadcastBannerActionProps {
  actionType: BroadcastActionType.Send | BroadcastActionType.Unsend;
  lang: string;
  url: string;
}

function CreateFormModal({ isOpen, setIsOpen }: Iprops) {
  const popupCtx = useContext(PopupContext);
  const { popup, setPopup, isReloadList, setIsReloadList } = popupCtx;

  const [isLoading, setIsLoading] = useState(false);
  const [isCheckerror, setIsCheckerror] = useState(false);
  const [show, setShow] = useState<boolean>(true);
  const [redirectLink, setRedirectLink] = useState<string>("");

  const [photoSelected, setPhotoSelected] = useState<IPhoto>({
    urlPreview: "",
    formDataValue: "",
  });

  const [photoSelectedMobile, setPhotoSelectedMobile] = useState<IPhoto>({
    urlPreview: "",
    formDataValue: "",
  });

  const handleClose = () => {
    setIsOpen(false);
    popup?._id && clearPopupSelected();
  };

  const handleUploadImages = async (file: any) => {
    if (!file) return "";
    let formData = new FormData();
    formData.append("image", file);
    try {
      const responsive = await Api.Popup.uploadImage(formData);
      return responsive.data;
    } catch (error) {
      console.log("Upload file error:", error);
    }
  };

  const handleValidateForm = () => {
    const invalid =
      !photoSelected.urlPreview ||
      !photoSelectedMobile.urlPreview ||
      !redirectLink;

    return invalid;
  };

  const handleCapture = (event: any) => {
    const file = event.target.files[0];
    if (!file) return;
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (e) => {
      setPhotoSelected({
        urlPreview: e.target?.result?.toString() || "",
        formDataValue: file || "",
      });
    };
  };

  const handleCaptureMobile = (event: any) => {
    const file = event.target.files[0];
    if (!file) return;
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (e) => {
      setPhotoSelectedMobile({
        urlPreview: e.target?.result?.toString() || "",
        formDataValue: file || "",
      });
    };
  };

  const handleSubmitForm = async (e: any) => {
    e.preventDefault();

    setIsCheckerror(true);

    if (handleValidateForm()) return;

    try {
      setIsLoading(true);

      const thumbnail = photoSelected.formDataValue
        ? await handleUploadImages(photoSelected.formDataValue)
        : "";

      const thumbnailMobile = photoSelectedMobile.formDataValue
        ? await handleUploadImages(photoSelectedMobile.formDataValue)
        : "";

      const dataToPost = {
        ...(thumbnail && { thumbnail }),
        ...(thumbnailMobile && { thumbnailMobile }),
        isShow: show,
        redirectLink,
        ...(popup?._id && { popupId: popup?._id }),
      };

      const response = popup?._id
        ? await Api.Popup.updatePopup(dataToPost)
        : await Api.Popup.addPopup(dataToPost);
      if (response) {
        toast.success(popup?._id ? "Update succes !" : "Create success !");
        setIsReloadList(!isReloadList);
        handleClose();
      }
    } catch (error) {
      console.log("error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const clearPopupSelected = () => {
    setPopup(null);
  };

  const handleBindingFormData = async () => {
    bindingFormDataSelected();
  };

  const bindingFormDataSelected = () => {
    if (!popup?._id) return;

    setPhotoSelected({
      urlPreview: popup?.thumbnail || "",
      formDataValue: "",
    });

    setPhotoSelectedMobile({
      urlPreview: popup?.thumbnailMobile || "",
      formDataValue: "",
    });

    setRedirectLink(popup?.redirectLink);
    setShow(popup?.isShow);
  };

  useEffect(() => {
    handleBindingFormData();
  }, []);

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box component="form">
          <FormWrap>
            <Form>
              <CloseWrap>
                <IconWrap fontSize="large" onClick={handleClose}>
                  <CloseBtn />
                </IconWrap>
              </CloseWrap>
              <TitleWrap>
                <Title>Popup</Title>
              </TitleWrap>
              <RowWrap>
                <ColumnWrap>
                  <FieldTitle>
                    Thumbnail{" "}
                    {!photoSelected.urlPreview && (
                      <RequiredStart>*</RequiredStart>
                    )}
                  </FieldTitle>
                  <AndroidIOSWrap style={{ alignItems: "flex-start" }}>
                    <ColumnWrap style={{ maxWidth: 300 }}>
                      <ColumnWrap style={{ maxWidth: 300 }}>
                        <input
                          name="thumbnail"
                          onChange={handleCapture}
                          accept="image/*"
                          id="icon-button-photo-android"
                          //multiple
                          type="file"
                          style={{ display: "none" }}
                        />
                        <label htmlFor="icon-button-photo-android">
                          <UploadWrap>
                            <IconWrap fontSize="large">
                              <CameraBtn />
                            </IconWrap>
                            <UploadText>Select image to upload</UploadText>
                            <UploadTextNote>
                              (ratio: 1x1 - pixel: 800 x 800)
                            </UploadTextNote>
                          </UploadWrap>
                        </label>
                        {isCheckerror && !photoSelected.urlPreview && (
                          <ErrorText>is required</ErrorText>
                        )}
                      </ColumnWrap>
                    </ColumnWrap>
                    <ColumnWrap widthPercent={50}>
                      {photoSelected.urlPreview && (
                        <AndroidIOSWrap>
                          <PhotoWrap>
                            <Photo
                              src={photoSelected.urlPreview}
                              alt="thumbnail"
                              loading="lazy"
                            />
                          </PhotoWrap>
                        </AndroidIOSWrap>
                      )}
                    </ColumnWrap>
                  </AndroidIOSWrap>
                </ColumnWrap>
              </RowWrap>
              <RowWrap>
                <ColumnWrap>
                  <FieldTitle>
                    Thumbnail Mobile{" "}
                    {!photoSelectedMobile.urlPreview && (
                      <RequiredStart>*</RequiredStart>
                    )}
                  </FieldTitle>
                  <AndroidIOSWrap style={{ alignItems: "flex-start" }}>
                    <ColumnWrap style={{ maxWidth: 300 }}>
                      <ColumnWrap style={{ maxWidth: 300 }}>
                        <input
                          name="thumbnail-mobile"
                          onChange={handleCaptureMobile}
                          accept="image/*"
                          id="icon-button-photo-mobile"
                          //multiple
                          type="file"
                          style={{ display: "none" }}
                        />
                        <label htmlFor="icon-button-photo-mobile">
                          <UploadWrap>
                            <IconWrap fontSize="large">
                              <CameraBtn />
                            </IconWrap>
                            <UploadText>Select image to upload</UploadText>
                            <UploadTextNote>
                              (ratio: 1x1 - pixel: 800 x 800)
                            </UploadTextNote>
                          </UploadWrap>
                        </label>
                        {isCheckerror && !photoSelectedMobile.urlPreview && (
                          <ErrorText>is required</ErrorText>
                        )}
                      </ColumnWrap>
                    </ColumnWrap>
                    <ColumnWrap widthPercent={50}>
                      {photoSelectedMobile.urlPreview && (
                        <AndroidIOSWrap>
                          <PhotoWrap>
                            <Photo
                              src={photoSelectedMobile.urlPreview}
                              alt="thumbnail"
                              loading="lazy"
                            />
                          </PhotoWrap>
                        </AndroidIOSWrap>
                      )}
                    </ColumnWrap>
                  </AndroidIOSWrap>
                </ColumnWrap>
              </RowWrap>
              <RowWrap>
                <FieldTitle>Redirect Link</FieldTitle>
                <ColumnWrap>
                  <TextField
                    name="redirectLink"
                    helperText={isCheckerror && !redirectLink && "is required"}
                    value={redirectLink}
                    onFocus={() => setIsCheckerror(false)}
                    onChange={(e) => setRedirectLink(e.target.value)}
                    fullWidth
                    id="outlined"
                    label={!redirectLink ? "Redirect Link *" : "Redirect Link"}
                    size="small"
                  />
                </ColumnWrap>
              </RowWrap>
              <RowWrap>
                <FieldTitle>Show</FieldTitle>
                <ColumnWrap>
                  <Switch
                    checked={show}
                    onChange={(e: any) => setShow(e?.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </ColumnWrap>
              </RowWrap>
              <ButtonWrap>
                <CreateButton
                  loading={isLoading}
                  disabled={isLoading}
                  loadingPosition="start"
                  startIcon={<></>}
                  onClick={handleSubmitForm}
                >
                  <ButtonText>{popup?._id ? "Update" : "Create"}</ButtonText>
                </CreateButton>
              </ButtonWrap>
            </Form>
          </FormWrap>
        </Box>
      </Modal>
    </>
  );
}

export default CreateFormModal;
