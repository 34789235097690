import React, { createContext, useCallback, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Api from "../service";

interface Props {
  children: React.ReactNode;
}
// Define the context interface
interface AuthContextType {
  isLogin: boolean;
  // setIsLogin: React.Dispatch<React.SetStateAction<boolean>>;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;

  role: string;
  setRole: React.Dispatch<React.SetStateAction<string>>;

  accessToken: string | null;

  login: (data: any) => void;
  logout: () => void;
}

// Create the context
const AuthContext = createContext<AuthContextType>({
  /*eslint-disable */
  isLogin: false,
  // setIsLogin: () => {},
  name: "",
  setName: () => {},
  role: "",
  setRole: () => {},
  accessToken: "",
  login: () => {},
  logout: () => {},
  /*eslint-disable */
});

// Custom Provider component
export const AuthContextProvider = (props: Props) => {
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("x-access-token")
  );
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const isLogin = !!accessToken;
  const navigate = useNavigate();

  // const [isLogin, setIsLogin] = useState<boolean>(!!localStorage.getItem("x-access-token"));
  const fetchAdminProfile = async () => {
    try {
      const adminProfile = await Api.Admin.getProfile();
      setName(adminProfile.data.name);
      setRole(adminProfile.data.role);
    } catch (error) {
      console.log("error:", error);
    }
  };
  useEffect(() => {
    if (isLogin) {
      fetchAdminProfile();
    } else {
      navigate("/login");
    }
  }, []);

  const logoutHandler = useCallback(() => {
    setAccessToken(null);
    localStorage.removeItem("x-access-token");
    setRole("");
    navigate("/login");
  }, []);

  const loginHandler = (data: any) => {
    setAccessToken(data.accessToken);
    localStorage.setItem("x-access-token", data.accessToken);
    fetchAdminProfile();
  };

  return (
    <AuthContext.Provider
      value={{
        isLogin,
        // setIsLogin,
        name,
        setName,
        role,
        setRole,
        accessToken,
        login: loginHandler,
        logout: logoutHandler,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export const AuthOutlet = (props: any) => {
  return (
    <AuthContextProvider>
      {props.children ? props.children : <Outlet />}
    </AuthContextProvider>
  );
};
export default AuthContext;
