import React from "react";
import ListWithdraw from "./components/ListWithdraw";
import { WithdrawOutlet } from "./context/withdraw-context";

function Withdraw() {
  return (
    <div>
      <WithdrawOutlet>
        <ListWithdraw />
      </WithdrawOutlet>
    </div>
  );
}

export default Withdraw;
