function CloseIcon({
  width,
  onClick,
}: {
  width?: number;
  onClick?: () => void;
}) {
  return (
    <svg
      onClick={onClick}
      width={width ? width : 32}
      height={width ? width : 32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.4003 7.61314C24.2769 7.48954 24.1304 7.39148 23.9691 7.32457C23.8078 7.25766 23.6349 7.22322 23.4603 7.22322C23.2857 7.22322 23.1128 7.25766 22.9515 7.32457C22.7902 7.39148 22.6436 7.48954 22.5203 7.61314L16.0003 14.1198L9.4803 7.59981C9.35686 7.47637 9.21031 7.37845 9.04902 7.31164C8.88774 7.24483 8.71487 7.21045 8.5403 7.21045C8.36572 7.21045 8.19286 7.24483 8.03157 7.31164C7.87029 7.37845 7.72374 7.47637 7.6003 7.59981C7.47686 7.72325 7.37894 7.8698 7.31213 8.03109C7.24532 8.19237 7.21094 8.36524 7.21094 8.53981C7.21094 8.71438 7.24532 8.88725 7.31213 9.04853C7.37894 9.20982 7.47686 9.35637 7.6003 9.47981L14.1203 15.9998L7.6003 22.5198C7.47686 22.6433 7.37894 22.7898 7.31213 22.9511C7.24532 23.1124 7.21094 23.2852 7.21094 23.4598C7.21094 23.6344 7.24532 23.8072 7.31213 23.9685C7.37894 24.1298 7.47686 24.2764 7.6003 24.3998C7.72374 24.5233 7.87029 24.6212 8.03157 24.688C8.19286 24.7548 8.36572 24.7892 8.5403 24.7892C8.71487 24.7892 8.88774 24.7548 9.04902 24.688C9.21031 24.6212 9.35686 24.5233 9.4803 24.3998L16.0003 17.8798L22.5203 24.3998C22.6437 24.5233 22.7903 24.6212 22.9516 24.688C23.1129 24.7548 23.2857 24.7892 23.4603 24.7892C23.6349 24.7892 23.8077 24.7548 23.969 24.688C24.1303 24.6212 24.2769 24.5233 24.4003 24.3998C24.5237 24.2764 24.6217 24.1298 24.6885 23.9685C24.7553 23.8072 24.7897 23.6344 24.7897 23.4598C24.7897 23.2852 24.7553 23.1124 24.6885 22.9511C24.6217 22.7898 24.5237 22.6433 24.4003 22.5198L17.8803 15.9998L24.4003 9.47981C24.907 8.97314 24.907 8.11981 24.4003 7.61314Z"
        fill="#2D2D2D"
      />
    </svg>
  );
}

export default CloseIcon;
