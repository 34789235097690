import ListMedia from "./components/Medias";
import { MediaOutlet } from "./context/media-context";

function Media() {
  return (
    <div>
      <MediaOutlet>
        <ListMedia />
      </MediaOutlet>
    </div>
  );
}

export default Media;
