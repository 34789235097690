import React from "react";
import styled from "styled-components";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { SummaryKeys } from "../../constants";
const SummaryBlock = ({
  totalOrder,
  totalIncome,
  title,
  percent,
  isActive,
  onClick,
}: {
  totalOrder: number;
  totalIncome?: number;
  percent?: number;
  title: string;
  isActive?: boolean;
  onClick: () => void;
}) => {
  const renderLabel = (key: string) => {
    let label = key;
    switch (key) {
      case SummaryKeys.InProgress:
        label = "In Progress";
        break;
      case SummaryKeys.RequestCancel:
        label = "Request Cancel";
        break;
      case SummaryKeys.RejectRequestCancel:
        label = "Reject Cancel";
        break;
      case SummaryKeys.SupportRequestCancel:
        label = "Support Request";
        break;
      default:
        label = key;
    }
    return label;
  };
  return (
    <Container onClick={onClick} isActive={isActive}>
      <Left>
        <h5>{renderLabel(title)}</h5>
        <div>
          <span>
            {totalIncome !== undefined ? `$${totalIncome.toFixed(2)}` : ""}
          </span>
          {/* <span>{percent.toFixed(2)}%</span> */}
        </div>
      </Left>
      <Right>
        <span>{totalOrder}</span>
      </Right>

      {isActive && <ArrowDropDownIcon fontSize="large" />}
    </Container>
  );
};

export default SummaryBlock;

const Left = styled.div`
  > h5 {
    color: rgb(115, 131, 149);
    font-size: 1rem;
    white-space: nowrap;
    text-transform: uppercase;
  }
  > div {
    margin-top: 1rem;
    > span {
      font-weight: 600;
      :first-child {
        font-size: 1.2rem;
        font-style: italic;
        margin-right: 0.5rem;
      }
      /* :last-child {
        color: rgb(18, 206, 4);
        font-size: 0.8rem;
      } */
    }
  }
`;
const Right = styled.div`
  > span {
    font-size: 2.4rem;
    font-weight: 700;
  }
`;

const Container = styled.div<{ isActive?: boolean }>`
  padding: 1.2rem;
  min-width: 15rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  background-color: #fff;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  position: relative;

  ${({ isActive }) =>
    isActive &&
    ` transform: scale(1.05);
    background-color: rgba(105, 108, 255, .3);
    `}

  :hover {
    transform: scale(1.05);
  }

  > svg {
    position: absolute;
    bottom: -1.6rem;
    left: 50%;
    transform: translateX(-50%);
  }
`;
