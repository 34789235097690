import Header from "./Header";
import Navbar from "./Navbar";
import Footer from "./Footer";
import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LayoutWrap = styled.div`
  position: relative;
`;

const ContainerWrapper = styled.div`
  /* margin-left: 250px; */
`;

const Container = styled.div`
  min-height: calc(100vh - 400px);
  padding: 0 32px;
`;

const MainWrapper = styled.div<{ isLogin: boolean }>`
  margin-left: ${(p) => (p.isLogin ? "250px" : "0")};
`;
const CustomToast = styled(ToastContainer)`
  position: fixed;
  right: 0;
  top: 50px;
`;

function Layout(props: any) {
  return (
    <LayoutWrap>
      <CustomToast />
      {props.isLogin && <Navbar />}
      <MainWrapper isLogin={props.isLogin}>
        {props.isLogin && <Header />}
        <ContainerWrapper>
          <Container>{props.children}</Container>
        </ContainerWrapper>
        {/* <Footer /> */}
      </MainWrapper>
    </LayoutWrap>
  );
}

export default Layout;
