import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { breakPoints } from "../../../../../constants/device";
import { Input } from "@mui/material";
import OrderContext, { FormMode } from "../../../context/order-context";
import { Order } from "../../../../../constants/interface";
import { RowWrap, TextStyle, TitleStyle, WrapText } from "./InputText";
import { OrderStatusType } from "../../../constants";

interface IProps {
  title: string;
  content: string;
  mode?: FormMode;
  keyOfOrder: keyof Order;
}
const StatusInputText = ({ title, content, mode, keyOfOrder }: IProps) => {
  const { order, setOrder } = useContext(OrderContext);
  const value = order ? order[keyOfOrder] : "";
  return (
    <RowWrap>
      <WrapText>
        <TitleStyle>{title}</TitleStyle>
      </WrapText>
      <WrapText>
        {mode === FormMode.Edit ? (
          <Input
            value={value}
            onChange={(e) =>
              setOrder((prev: any) => ({
                ...prev,
                [keyOfOrder]: e.target.value,
              }))
            }
          />
        ) : (
          renderStatus(content)
        )}
      </WrapText>
    </RowWrap>
  );
};

const renderStatus = (status: string) => {
  if (
    status === OrderStatusType.InProgress ||
    status === OrderStatusType.Packing ||
    status === OrderStatusType.Shipping ||
    status === OrderStatusType.Delivered ||
    status === OrderStatusType.Confirmed
  ) {
    return <TextStyle style={{ color: "#1268e9" }}>{status}</TextStyle>;
  }
  return <TextStyle style={{ color: "#F64F59" }}>{status}</TextStyle>;
};

export default StatusInputText;
