import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import UserContext from "../../../context/user-context";
import styled from "styled-components";
import ButtonCRUD from "../../../../../components/buttonCRUD";
import { CRUDType } from "../../../../../components/buttonCRUD";
import { UserType } from "../../../../../constants/types";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import Api from "../../../../../service";
import { toast } from "react-toastify";

const Container = styled.div`
  height: 100%;
  margin-top: 50px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 200px;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 32px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

const AvatarWrap = styled.div`
  min-width: 200px;
  max-width: 200px;
`;

const Avatar = styled.img`
  object-fit: cover;
  width: 100%;
  max-height: 200px;
  border-radius: 50%;
`;

const WrapText = styled.div`
  min-width: 150px;
`;

const TextStyle = styled.span`
  line-height: 40px;
  font-size: 20px;
  font-weight: 600;
  font-family: "Quicksand";
  font-style: normal;
  text-transform: capitalize;
  color: #222222;
`;

const TitleStyle = styled(TextStyle)`
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
  color: gray;
`;

const FullName = styled(TitleStyle)`
  font-size: 24px;
  font-weight: 700;
  color: #222222;
`;

const Email = styled(TextStyle)`
  font-weight: 600;
  font-size: 20px;
  text-transform: none;
  color: gray;
`;

const Wrap = styled.div``;

const WrapButton = styled.div`
  display: flex;
  gap: 24px;
`;

const CustomButton = styled(Button)<{ bgColor: string; width?: number }>`
  display: inline-block !important;
  vertical-align: middle !important;
  /* padding: 0 !important; */
  padding: 0 16px !important;
  margin: 0 !important;
  width: ${({ width }) => (width ? `${width}px` : "auto")};
  min-width: 35px !important;
  height: 35px !important;
  line-height: unset !important;
  background-color: ${({ bgColor }) =>
    bgColor ? `${bgColor} !important` : "#54B4D3 !important"};
  color: #fff !important;

  .MuiIcon-root {
    height: auto;
  }
`;

function Profile() {
  const userCtx = useContext(UserContext);
  const { node, user, setUser } = userCtx;

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<string>(
    user?.type?.toUpperCase() || UserType.Trial
  );

  const handleChangeSelect = (event: SelectChangeEvent) => {
    setSelectedType(event.target.value as string);
  };

  const handleOpenEdit = () => {
    setIsEdit(!isEdit);
  };

  const handleUpdateProfile = async () => {
    if (!selectedType || !user?._id) {
      toast.error("cannot update !");
      return;
    }

    try {
      const dataToPost = {
        // fullname: user?.fullname || "",
        // lang: user?.lang || "en",
        type: selectedType,
        userId: user?._id,
      };

      const response = await Api.Admin.updateUserProfile(dataToPost);
      if (response) {
        setUser(response.data);
        node.setData({ ...user, type: response?.data?.type || UserType.Trial });
        setIsEdit(false);
        toast.success("Update success !");
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <Container>
      <Wrapper>
        <Row style={{ gap: 160 }}>
          <Column>
            <Row>
              <WrapText>
                <TitleStyle>Full Name</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>{user?.fullname}</TextStyle>
              </WrapText>
            </Row>
            <Row>
              <WrapText>
                <TitleStyle>Email</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>{user?.email}</TextStyle>
              </WrapText>
            </Row>
            <Row>
              <WrapText>
                <TitleStyle>Login Type</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>
                  {user?.googleId
                    ? "Google"
                    : user?.appleId
                    ? "Apple"
                    : "Email/Password"}
                </TextStyle>
              </WrapText>
            </Row>
          </Column>
          {/* <Column>
            <Row>
              <WrapText style={{ minWidth: 100 }}>
                <TitleStyle>RefCode</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>{user?.refCode}</TextStyle>
              </WrapText>
            </Row>
            <Row>
              <WrapText style={{ minWidth: 100 }}>
                <TitleStyle>Point</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>{user?.point}</TextStyle>
              </WrapText>
            </Row>
            <Row>
              <WrapText style={{ minWidth: 100 }}>
                <TitleStyle>Lang</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>{user?.lang}</TextStyle>
              </WrapText>
            </Row>
            <Row>
              <WrapText style={{ minWidth: 100 }}>
                <TitleStyle>Status</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>{user?.status}</TextStyle>
              </WrapText>
            </Row>
          </Column> */}
        </Row>
        {/* <Row style={{ justifyContent: "center" }}>
          {!isEdit ? (
            <CustomButton width={90} onClick={handleOpenEdit} bgColor="#E4A11B">
              Update
            </CustomButton>
          ) : (
            <>
              <WrapButton>
                <CustomButton
                  width={90}
                  onClick={handleUpdateProfile}
                  bgColor="#14A44D"
                >
                  Save
                </CustomButton>
                <CustomButton
                  width={90}
                  onClick={handleOpenEdit}
                  bgColor="gray"
                  // width={50}
                >
                  Cancel
                </CustomButton>
              </WrapButton>
            </>
          )}
        </Row> */}
      </Wrapper>
    </Container>
  );
}

export default Profile;
