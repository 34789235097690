import { Chip } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";
import React from "react";
import styled from "styled-components";

export enum UserAgentIdentifyStatus {
  Pending = "PENDING",
  Approved = "APPROVED",
  Submitted = "SUBMITTED",
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;
const StatusText = styled.span<{ color: string }>`
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  color: ${({ color }) => (color ? color : "#0b7bf4")};
`;

function IdentifyStatus(props: ICellRendererParams) {
  const identifyStatus = props.data.identifyStatus;

  return (
    <Container>
      {identifyStatus === UserAgentIdentifyStatus.Approved ? (
        <StatusText color="#2e7d32">{identifyStatus}</StatusText>
      ) : (
        <StatusText color="#ed6c02">{identifyStatus}</StatusText>
      )}
    </Container>
  );
}

export default IdentifyStatus;
