import { Box, Button, Modal, TextField } from "@mui/material";
import React, { useContext } from "react";
import styled from "styled-components";
import OrderContext from "../../pages/order/context/order-context";

export const styleModal = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};
interface IProps {
  isOpen: boolean;
  body?: string;
  disable?: boolean;
  inputText?: string;
  title?: string;
  label?: string;
  onClose?: () => void;
  onConfirm?: () => void | Promise<void>;
}
const ConfirmModalV3 = ({
  isOpen,
  body,
  disable,
  inputText,
  title,
  label,
  onClose,
  onConfirm,
}: IProps) => {
  const { setModal } = useContext(OrderContext);
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <CustomBox sx={{ ...styleModal, minWidth: 400, maxWidth: 400 }}>
        {title && <h2 id="parent-modal-title">{title}</h2>}
        {body && <p id="parent-modal-description">{body}</p>}
        {inputText !== undefined && (
          <TextField
            id="input-modal-nvd"
            value={inputText}
            onChange={(e) => {
              setModal((prev) => ({
                ...prev,
                isOpen: prev?.isOpen || false,
                inputText: e.target.value,
              }));
            }}
            label={label ? label : null}
            variant="standard"
          />
        )}
        <div>
          <Button
            onClick={onClose}
            size="small"
            color="error"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            size="small"
            color="success"
            variant="contained"
            disabled={disable}
          >
            Confirm
          </Button>
        </div>
      </CustomBox>
    </Modal>
  );
};

export default ConfirmModalV3;
export const CustomBox = styled(Box)`
  > h2 {
    font-size: 2rem;
    font-weight: bold;
    color: #212121;
    text-align: center;
  }
  > p {
    margin: 1rem 0 2rem 0;
    font-size: 1.4rem;
    font-weight: 500;
    color: rgb(119, 142, 168);

    > span {
      color: rgb(60, 111, 168);
      font-style: italic;
    }
  }
  > div {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;
    > button {
      font-size: 1.2rem;
      text-transform: capitalize;
    }
  }
`;
