import { Box, Button, Modal } from "@mui/material";
import React from "react";
import styled from "styled-components";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

const ConfirmModal = ({
  isOpen,
  status,
  disabled,
  setIsOpen,
  onConfirm,
}: {
  isOpen: boolean;
  status: string;
  disabled?: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: () => void;
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <CustomBox sx={{ ...style, minWidth: 400 }}>
        <h2 id="parent-modal-title">Confirmation</h2>
        <p id="parent-modal-description">
          Are you sure you want to move to <span>{status}</span> state?
        </p>
        <div>
          <Button
            onClick={() => setIsOpen(false)}
            size="small"
            color="error"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            size="small"
            color="success"
            variant="contained"
            disabled={disabled}
          >
            Confirm
          </Button>
        </div>
      </CustomBox>
    </Modal>
  );
};

export default ConfirmModal;
const CustomBox = styled(Box)`
  > h2 {
    font-size: 2rem;
    font-weight: bold;
    color: rgb(115, 131, 149);
    text-align: center;
  }
  > p {
    margin: 1rem 0 2rem 0;
    font-size: 1.4rem;
    font-weight: 500;
    > span {
      color: rgb(60, 111, 168);
      font-style: italic;
    }
  }
  > div {
    display: flex;
    justify-content: center;
    gap: 1rem;
    > button {
      font-size: 1.2rem;
      text-transform: capitalize;
    }
  }
`;
