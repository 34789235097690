import styled, { keyframes } from "styled-components";
import TextField from "@mui/material/TextField";
import ReactQuill from "react-quill";
import { useMemo, useRef, useState } from "react";
import Api from "../../../service";
import { TabPanel } from "@mui/lab";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Checkbox, Icon } from "@mui/material";
import {
  BroadcastActionType,
  IBroadcastBannerActionProps,
  ILang,
} from "./CreateFormModal";

const CustomTabPanel = styled(TabPanel)<{ height?: string }>`
  width: 100%;
  background-color: #fff;
  padding: 24px 0 !important;
  ${({ height }) => height && { height: height }}
`;

const ColumnWrap = styled.div<{ widthPercent?: number; loading?: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ widthPercent }) => (widthPercent ? `${widthPercent}%` : "100%")};
  gap: 16px;
  .MuiFormHelperText-root {
    color: #d32f2f;
  }

  .label-upload-image {
    ${({ loading }) => !!loading && "cursor: not-allowed;"}
    ${({ loading }) => !!loading && "pointer-events: none;opacity: 0.8;"}
  }
`;

const ErrorText = styled.p`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 4px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
  color: rgb(211, 47, 47);
`;

const ImageWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 24px;
  gap: 24px;
`;

const FieldTitle = styled.span`
  //min-width: 200px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 35px;
  letter-spacing: -0.0025em;
  color: #222222;
`;

const CheckBoxTitle = styled(FieldTitle)`
  font-size: 18px;
`;

const RequiredStart = styled.span`
  vertical-align: middle;
  font-size: 20px;
  font-weight: 700;
  color: red;
`;

const UploadWrap = styled.div`
  position: relative;
  cursor: pointer;
  width: 300px;
  height: 200px;
  border-radius: 8px;
  background-color: "rgba(0,0,0,.02)";
  border: 1px dashed #d9d9d9;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const SpinLoading = styled.div`
  position: absolute;
  border: 8px solid #f3f3f3;
  border-left: 8px solid #1976d2;
  border-right: 8px solid #1976d2;
  border-radius: 50%;
  opacity: 0.8;
  width: 40px;
  height: 40px;
  animation: ${spin} 2s linear infinite;
  z-index: 99;
`;

const UploadText = styled.span`
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #222222;
`;

const UploadTextNote = styled(UploadText)`
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #cccccc;
`;

const IconWrap = styled(Icon)`
  cursor: pointer;
`;

const CameraBtn = styled(CloudUploadIcon)`
  width: 100% !important;
  height: 100% !important;
`;

const PhotoWrap = styled.div`
  max-width: 300px;
  height: 200px;
  max-height: 200px;
`;

const Photo = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
`;

function CustomTabComponent({
  value,
  lang,
  setLang,
  setIsLoading,
  isCheckerror,
  setIsCheckerror,
  isShowError,
  broadcastBanner,
}: {
  value: string;
  lang: ILang;
  setLang: React.Dispatch<React.SetStateAction<ILang>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isCheckerror: boolean;
  setIsCheckerror: React.Dispatch<React.SetStateAction<boolean>>;
  isShowError?: boolean;
  broadcastBanner: (props: IBroadcastBannerActionProps) => void;
}) {
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const refEditor = useRef<ReactQuill>(null);

  const handleUploadImages = async (file: any) => {
    if (!file) return "";
    let formData = new FormData();
    formData.append("image", file);
    try {
      const responsive = await Api.Event.uploadImage(formData);
      return responsive.data;
    } catch (error) {
      console.log("Upload file error:", error);
    }
  };

  const handleCaptureBanner = async (event: any) => {
    const file = event.target.files[0];
    if (!file) return;
    // const fileReader = new FileReader();
    // fileReader.readAsDataURL(file);
    // fileReader.onload = (e) => {
    //   setCoverImageSelected({
    //     urlPreview: e.target?.result?.toString() || "",
    //     formDataValue: file || "",
    //   });
    // };
    setImageLoading(true);
    try {
      const bannerUrl = await handleUploadImages(file);
      setLang({ ...lang, banner: bannerUrl });
    } catch (error) {
      console.log("upload banner error: ", error);
    } finally {
      setImageLoading(false);
    }
  };

  const handleImageEditor = async () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      setIsLoading(true);
      try {
        var file: any = input && input.files ? input.files[0] : null;
        const imageUrl = await handleUploadImages(file);
        const range = refEditor.current?.getEditorSelection();
        refEditor.current
          ?.getEditor()
          .insertEmbed(range?.index || 0, "image", imageUrl);
      } catch (error) {
        console.log("handle Image Editor has error !!", error);
      } finally {
        setIsLoading(false);
      }
    };
  };

  const modulesEditor = useMemo(() => {
    return {
      toolbar: {
        container: [
          [{ header: "1" }, { header: "2" }, { font: [] }],
          [{ size: [] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image"],
          ["clean"],
        ],
        handlers: {
          image: handleImageEditor,
        },
      },
    };
  }, []);

  const handleCopyBannerForAllLanguage = () => {
    setLang({ ...lang, checkedUseBannerForAll: !lang.checkedUseBannerForAll });
    if (!lang.banner) return;

    broadcastBanner({
      actionType: lang.checkedUseBannerForAll
        ? BroadcastActionType.Unsend
        : BroadcastActionType.Send,
      lang: lang.lang,
      url: lang.banner,
    });
  };

  return (
    <CustomTabPanel value={value}>
      <ColumnWrap
        style={{ alignItems: "center", position: "relative" }}
        widthPercent={100}
      >
        <ColumnWrap widthPercent={100}>
          <ImageWrap>
            <FieldTitle>
              Banner{" "}
              {isShowError && !lang.banner && <RequiredStart>*</RequiredStart>}
            </FieldTitle>
            <ImageWrap>
              <Checkbox
                checked={lang.checkedUseBannerForAll}
                onChange={handleCopyBannerForAllLanguage}
                inputProps={{ "aria-label": "controlled" }}
              />
              <CheckBoxTitle>Use for all language</CheckBoxTitle>
            </ImageWrap>
          </ImageWrap>
          <ImageWrap style={{ alignItems: "flex-start" }}>
            <ColumnWrap style={{ maxWidth: 300 }}>
              <ColumnWrap loading={imageLoading} style={{ maxWidth: 300 }}>
                <input
                  name="banner"
                  onChange={handleCaptureBanner}
                  accept="image/*"
                  id="icon-button-banner"
                  //multiple
                  type="file"
                  style={{ display: "none" }}
                />
                <label
                  className="label-upload-image"
                  htmlFor="icon-button-banner"
                >
                  <UploadWrap>
                    <IconWrap fontSize="large">
                      <CameraBtn />
                    </IconWrap>
                    <UploadText>Select image to upload</UploadText>
                    <UploadTextNote>
                      (ratio: 2x3 - pixel: 800 x 1200)
                    </UploadTextNote>
                    {imageLoading && <SpinLoading />}
                  </UploadWrap>
                </label>
                {isShowError && isCheckerror && !lang.banner && (
                  <ErrorText>is required</ErrorText>
                )}
              </ColumnWrap>
            </ColumnWrap>
            <ColumnWrap widthPercent={50}>
              {lang.banner && (
                <ImageWrap>
                  <PhotoWrap>
                    <Photo src={lang.banner} alt="cover image" loading="lazy" />
                  </PhotoWrap>
                </ImageWrap>
              )}
            </ColumnWrap>
          </ImageWrap>
        </ColumnWrap>
        <ColumnWrap widthPercent={100}>
          <TextField
            name="title"
            helperText={
              isShowError && isCheckerror && !lang.title && "is required"
            }
            value={lang.title}
            onFocus={() => setIsCheckerror(false)}
            onChange={(e) => setLang({ ...lang, title: e.target.value })}
            fullWidth
            id="outlined"
            label={isShowError ? "Title *" : "Title"}
            size="small"
          />
        </ColumnWrap>
        <ColumnWrap widthPercent={100}>
          <TextField
            name="shortDescription"
            helperText={
              isShowError &&
              isCheckerror &&
              !lang.shortDescription &&
              "is required"
            }
            value={lang.shortDescription}
            onFocus={() => setIsCheckerror(false)}
            onChange={(e) =>
              setLang({
                ...lang,
                shortDescription: e.target.value,
              })
            }
            fullWidth
            multiline={true}
            rows={2}
            id="outlined"
            label={isShowError ? "Short Description *" : "Short Description"}
            size="small"
          />
        </ColumnWrap>
        <ColumnWrap widthPercent={100}>
          <div style={{ height: 600 }}>
            <ReactQuill
              ref={refEditor}
              modules={modulesEditor}
              style={{ height: 530 }}
              id="description"
              theme="snow"
              value={lang.description}
              onFocus={() => setIsCheckerror(false)}
              onChange={(e) => setLang({ ...lang, description: e })}
            />
          </div>
          {isShowError &&
            isCheckerror &&
            (lang.description === "<p><br></p>" || !lang.description) && (
              <ErrorText style={{ marginTop: -40 }}>is required</ErrorText>
            )}
        </ColumnWrap>
      </ColumnWrap>
    </CustomTabPanel>
  );
}

export default CustomTabComponent;
