import React, { useRef, useState } from "react";

import PauseIcon from "../../icons/PauseIcon";
import PlayIcon from "../../icons/PlayIcon";
import styled from "styled-components";

interface VideoProps {
  src: string;
  width?: number;
  height?: number;
}

const Video: React.FC<VideoProps> = ({ src, width = 640, height = 360 }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <Container>
      <video ref={videoRef} src={src} width={width} height={height} controls />
      {/* <button onClick={togglePlay} type="button">
        {isPlaying ? <PauseIcon /> : <PlayIcon />}
      </button> */}
    </Container>
  );
};

export default Video;

const Container = styled.div`
  position: relative;
  > button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: #ffffff; /* Customize the color */
  }
`;
