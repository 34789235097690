import React, { useEffect, useState } from "react";

import Api from "../../../service";
import { utcToGtm } from "../../../utils";

interface IShipmentCheckpoint {
  tag: string;
  subtag: string;
  message: string;
  checkpointTime: string;
}
export interface ITrackingV2 {
  orders_code: string;
  status: string;
  transaction_id: string;
  shipment_tracking_number: string;
  shipment_tracking_url: string;
  shipment_error: string;
  shipment_type: string;
  delivered_at: string;
  warehouse_order_code: string;
  warehouse_error: string;
  warehouse_status: string;
  shipment_order_code: string;
  shipment_status: string;
  shipment_checkpoints: IShipmentCheckpoint[];
}
export interface ITracking {
  time: string;
  status: string;
}
type ITrackingOrderResponse = {
  createdAt: string;
  status: string;
}

const useTrackingOrder = ({ orderId }: { orderId: string }) => {
  const [data, setData] = useState<ITracking[] | null>(null);
  useEffect(() => {
    Api.Order.getTrackingOrder(orderId)
      .then((response) => {
        if (response.data) {
          const formattedData = response.data.map((item: ITrackingOrderResponse) => ({
            time: utcToGtm(item.createdAt),
            status: item.status
          }))

          setData(formattedData);
        }
      })
      .catch((error) => {
        console.log("error:", error);
      });
  }, []);

  return { data };
};
export const useTrackingOrderV2 = ({ orderId }: { orderId: string }) => {
  const [data, setData] = useState<ITrackingV2 | null>(null);
  const [shipmentCheckpoints, setShipmentCheckpoints] = useState<ITracking[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Api.Order.getTrackingOrderV2(orderId)
      .then((response) => {
        if (response.data) {
          setData(response.data);
          const formattedShipmentCheckpoints = response.data.shipment_checkpoints.map((item: IShipmentCheckpoint) => ({
            time: utcToGtm(item.checkpointTime),
            status: item.message
          }))
          setShipmentCheckpoints(formattedShipmentCheckpoints.reverse());
        }
      })
      .catch((error) => {
        console.log("error:", error);
      })
      .finally(() => {
        setLoading(false);
      });

  }, []);

  return { data, shipmentCheckpoints, loading };
};

export default useTrackingOrder;
