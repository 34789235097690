import React from "react";
import ListUser from "./components/ListUser";
import { UserOutlet } from "./context/user-context";

function User() {
  return (
    <div>
      <UserOutlet>
        <ListUser />
      </UserOutlet>
    </div>
  );
}

export default User;
