import React, { useContext } from "react";
import {
  ColumnWrap,
  RowWrap,
  SectionTitle,
  TitleStyle,
  WrapText,
} from "./CreateForm";
import { TextStyle } from "./InputText/InputText";
import OrderContext from "../../context/order-context";
import styled from "styled-components";
import ButtonCRUD, {
  ButtonDisplayType,
  CRUDType,
} from "../../../../components/buttonCRUD";
import { useSearchParams } from "react-router-dom";

const SupportRequestForm = () => {
  const {
    supportRequestCancelOrder,
    setSupportRequestCancelOrder,
    onApproveSupportRequestCancelOrder,
    updateSupportRequestCancelOrder,
    setModal,
    onCloseModal,
  } = useContext(OrderContext);

  const [searchParams] = useSearchParams();
  const supportRequestId = searchParams.get("supportRequestId");

  const onConfirmResponse = async () => {
    // @ts-ignore
    const response = document.getElementById("input-modal-nvd")?.value;
    setSupportRequestCancelOrder((prev) => ({
      ...prev,
      admin_response: response,
    }));
    await updateSupportRequestCancelOrder({ admin_response: response });
    onCloseModal();
  };

  const onConfirmReject = async () => {
    // @ts-ignore
    const reject = document.getElementById("input-modal-nvd")?.value;
    setSupportRequestCancelOrder((prev) => ({
      ...prev,
      admin_reject: reject,
    }));
    await updateSupportRequestCancelOrder({ admin_reject: reject });
    onCloseModal();
  };

  return (
    <ColumnWrap>
      <SectionTitle>Support Request Cancel Order</SectionTitle>
      <RowWrap>
        <WrapText>
          <TitleStyle>Full Name</TitleStyle>
        </WrapText>
        <WrapText>
          <TextStyle>{supportRequestCancelOrder?.full_name}</TextStyle>
        </WrapText>
      </RowWrap>
      <RowWrap>
        <WrapText>
          <TitleStyle>Email</TitleStyle>
        </WrapText>
        <WrapText>
          <TextStyle>{supportRequestCancelOrder?.email}</TextStyle>
        </WrapText>
      </RowWrap>
      <RowWrap>
        <WrapText>
          <TitleStyle>Phone Number</TitleStyle>
        </WrapText>
        <WrapText>
          <TextStyle>{supportRequestCancelOrder?.phone_number}</TextStyle>
        </WrapText>
      </RowWrap>
      <RowWrap>
        <WrapText>
          <TitleStyle>Reason</TitleStyle>
        </WrapText>
        <WrapText>
          <TextStyle>{supportRequestCancelOrder?.reason}</TextStyle>
        </WrapText>
      </RowWrap>
      {supportRequestCancelOrder?.payment_service_name && (
        <>
          <RowWrap>
            <WrapText>
              <TitleStyle>Payment Service Name</TitleStyle>
            </WrapText>
            <WrapText>
              <TextStyle>
                {supportRequestCancelOrder?.payment_service_name}
              </TextStyle>
            </WrapText>
          </RowWrap>
          <RowWrap>
            <WrapText>
              <TitleStyle>Account information</TitleStyle>
            </WrapText>
            <WrapText>
              <TextStyle>
                {supportRequestCancelOrder?.address_to_refund}
              </TextStyle>
            </WrapText>
          </RowWrap>
        </>
      )}

      <ButtonContainer>
        <div
          onClick={() => {
            // setIsOpenModalResponse(true);
            setModal((prev) => ({
              isOpen: true,
              body: "Response result to client",
              label: "Response input",
              inputText: "",
              onConfirm: onConfirmResponse,
            }));
          }}
        >
          <ButtonCRUD
            displayType={ButtonDisplayType.Text}
            type={CRUDType.Response}
            size="medium"
            //   width={2000}
          />
        </div>
        <div
          onClick={() => {
            // setIsOpenModalReject(true);
            setModal((prev) => ({
              isOpen: true,
              body: "Are you sure you want to reject this request?",
              label: "Reject Reason",
              inputText: "",
              onConfirm: onConfirmReject,
            }));
          }}
        >
          <ButtonCRUD
            displayType={ButtonDisplayType.Text}
            type={CRUDType.Reject}
            size="medium"
          />
        </div>
        <div
          onClick={() => {
            if (!supportRequestId) return;
            setModal((prev) => ({
              isOpen: true,
              title: "Confirmation",
              body: "Are you sure this order has been refunded?",
              onConfirm: () => {
                onApproveSupportRequestCancelOrder(supportRequestId);
              },
            }));
          }}
        >
          <ButtonCRUD
            displayType={ButtonDisplayType.Text}
            type={CRUDType.Refunded}
            size="medium"
          />
        </div>
      </ButtonContainer>
    </ColumnWrap>
  );
};

export default SupportRequestForm;

const ButtonContainer = styled.div`
  margin-top: 4rem;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1rem;
`;
