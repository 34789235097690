import React from "react";
import useMaterialsLibrary from "../../MaterialsLibrary.hooks";
import useMaterialsLibraryOther from "./MaterialsLibraryOther.hooks";
import LoadingPage from "../../../../components/LoadingPage";
import MaterialsTable from "../../components/MaterialsTable";
import { MaterialType } from "../../../../constants/types";

const MaterialsLibraryOther = () => {
  const { isReloadList } = useMaterialsLibrary();
  const { othersData, isLoading } = useMaterialsLibraryOther({
    refresh: isReloadList,
  });

  if (isLoading) return <LoadingPage />;
  if (!othersData) return null;

  return <MaterialsTable data={othersData} type={MaterialType.OTHER} />;
};

export default MaterialsLibraryOther;
