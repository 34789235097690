export interface UpdateUserAgentProfile {
  agentId: string;
  phone: string;
  // dob: string;
  // gender: string;
  country: string;
  address_detail: string;
  // image_card_identify_front: string;
  // image_card_identify_back: string;
  // image_selfie: string;
  main_channel?: string;
  communication_channel?: string;
  fullname: string;
  currently_job: string;
  sale_experience: string;
  sales_volume: string;
  areas_of_bussiness: string;
  outline_reason: string;
  as_an_agent: string;
  what_do_you_think: string;
}

export const CURRENT_JOB_LIST_DEFAULT = [
  {
    value: 'Teacher',
    label: 'Teacher'
  },
  {
    value: 'Student',
    label: 'Student'
  },
  {
    value: 'Accountant',
    label: 'Accountant'
  },
  {
    value: 'Software Developer',
    label: 'Software Developer'
  },
  {
    value: 'Sales Representative',
    label: 'Sales Representative'
  },
  {
    value: 'Customer Service Representative',
    label: 'Customer Service Representative'
  },
  {
    value: 'Administrative Assistant',
    label: 'Administrative Assistant'
  },
  {
    value: 'Graphic Designer',
    label: 'Graphic Designer'
  },
  {
    value: 'Marketing Specialist',
    label: 'Marketing Specialist'
  },
  {
    value: 'Retail Sales Associate',
    label: 'Retail Sales Associate'
  },
  {
    value: 'Restaurant Server or Chef',
    label: 'Restaurant Server or Chef'
  },
  {
    value: 'Construction Worker',
    label: 'Construction Worker'
  },
  {
    value: 'Financial Analyst',
    label: 'Financial Analyst'
  },
  {
    value: 'Translator or Interpreter',
    label: 'Translator or Interpreter'
  },
  {
    value: 'Tour Guide',
    label: 'Tour Guide'
  },
  {
    value: 'Banking Professional',
    label: 'Banking Professional'
  },
  {
    value: 'Motorbike Courier or Delivery Driver',
    label: 'Motorbike Courier or Delivery Driver'
  },
  {
    value: 'Factory Worker',
    label: 'Factory Worker'
  },
  {
    value: 'Others',
    label: 'Others'
  }
]

export const SALE_EXPERIENCE_LIST = [
  {
    value: 'Less than 1 year',
    label: 'Less than 1 year'
  },
  {
    value: '1-3 years',
    label: '1-3 years'
  },
  {
    value: '3-5 years',
    label: '3-5 years'
  },
  {
    value: 'Over 5 years',
    label: 'Over 5 years'
  }
]

export const SALE_VOLUME_LIST = [
  {
    value: "Less than $1,000",
    label: "Less than $1,000",
  },
  {
    value: "$1,000 - $5,000",
    label: "$1,000 - $5,000",
  },
  {
    value: "$5,000 - $10,000",
    label: "$5,000 - $10,000",
  },
  {
    value: "$10,000 - $20,000",
    label: "$10,000 - $20,000",
  },
  {
    value: "Over $20,000",
    label: "Over $20,000",
  },
]

export const BUSINESS_AREA_LIST_DEFAULT = [
  {
    value: 'Technology and Software Development',
    label: 'Technology and Software Development'
  },
  {
    value: 'Healthcare and Medical Services',
    label: 'Healthcare and Medical Services'
  },
  {
    value: 'Finance and Banking',
    label: 'Finance and Banking'
  },
  {
    value: 'Retail and E-commerce',
    label: 'Retail and E-commerce'
  },
  {
    value: 'Real Estate and Property Management',
    label: 'Real Estate and Property Management'
  },
  {
    value: 'Education and E-Learning',
    label: 'Education and E-Learning'
  },
  {
    value: 'Hospitality and Tourism',
    label: 'Hospitality and Tourism'
  },
  {
    value: 'Food and Beverages',
    label: 'Food and Beverages'
  },
  {
    value: 'Manufacturing and Production',
    label: 'Manufacturing and Production'
  },
  {
    value: 'Transportation and Logistics',
    label: 'Transportation and Logistics'
  },
  {
    value: 'Entertainment and Media',
    label: 'Entertainment and Media'
  },
  {
    value: 'Advertising and Marketing',
    label: 'Advertising and Marketing'
  },
  {
    value: 'Legal and Consulting Services',
    label: 'Legal and Consulting Services'
  },
  {
    value: 'Government and Public Administration',
    label: 'Government and Public Administration'
  },
  {
    value: 'Fashion and Apparel',
    label: 'Fashion and Apparel'
  },
  {
    value: 'Automotive and Automotive Services',
    label: 'Automotive and Automotive Services'
  },
  {
    value: 'Telecommunications',
    label: 'Telecommunications'
  },
  {
    value: 'Sports and Recreation',
    label: 'Sports and Recreation'
  },
  {
    value: 'Others',
    label: 'Others'
  }
]