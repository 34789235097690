import React, { useEffect, useState } from 'react';
import Api from '../service';
import { Country, SelectCountry } from '../constants/interface';


const useCountry = () => {
    const [listCountries, setListCountries] = useState<SelectCountry[]>([]);
    const getCountriesAndPhoneCode = async () => {
        try {
            const response = await Api.Admin.getCountries();
            if (response?.data) {
                const data = response.data;
                const mappedData = data.map((item: Country) => ({
                    id: item.id,
                    phoneCode: item.phone_code,
                    name: item.name,
                    emoji: item.emoji,
                    _id: item.id,
                }))
                setListCountries(mappedData);
            }
        } catch (error) {
            console.log('error:', error)
        }
    };
    useEffect(() => {
        getCountriesAndPhoneCode();
    }, [])
    return { listCountries }
}

export default useCountry