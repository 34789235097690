import { IRowNode } from "ag-grid-community";
import React, { createContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { User, WithdrawHistoryI } from "../../../constants/interface";
import Api from "../../../service";
import { toast } from "react-toastify";

export enum WithdrawStatus {
  Pending = "PENDING",
  Success = "SUCCESS",
  Cancel = "CANCEL",
}
interface Props {
  children: React.ReactNode;
}
// Define the context interface
interface WithdrawContextType {
  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;

  isOpenDetailModal: boolean;
  setIsOpenDetailModal: React.Dispatch<React.SetStateAction<boolean>>;

  withdraw: WithdrawHistoryI | null;
  setWithdraw: React.Dispatch<React.SetStateAction<WithdrawHistoryI | null>>;

  node: any;
  setNode: React.Dispatch<any>;
}

// Create the context
const WithdrawContext = createContext<WithdrawContextType>({
  /*eslint-disable */
  isOpenModal: false,
  setIsOpenModal: () => {},
  isOpenDetailModal: false,
  setIsOpenDetailModal: () => {},
  withdraw: null,
  setWithdraw: () => {},
  node: "",
  setNode: () => {},
  /*eslint-disable */
});

// Custom Provider component
export const WithdrawContextProvider = (props: Props) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenDetailModal, setIsOpenDetailModal] = useState<boolean>(false);
  const [withdraw, setWithdraw] = useState<WithdrawHistoryI | null>(null);
  const [node, setNode] = useState<any | null>(null);

  return (
    <WithdrawContext.Provider
      value={{
        isOpenModal,
        setIsOpenModal,
        isOpenDetailModal,
        setIsOpenDetailModal,
        withdraw,
        setWithdraw,
        node,
        setNode,
      }}
    >
      {props.children}
    </WithdrawContext.Provider>
  );
};

export const WithdrawOutlet = (props: any) => {
  return (
    <WithdrawContextProvider>
      {props.children ? props.children : <Outlet />}
    </WithdrawContextProvider>
  );
};
export default WithdrawContext;
