function PlusIcon({
  width,
  onClick,
}: {
  width?: number;
  onClick?: () => void;
}) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 6.5C12.1989 6.5 12.3897 6.57902 12.5303 6.71967C12.671 6.86032 12.75 7.05109 12.75 7.25V11.75H17.25C17.4489 11.75 17.6397 11.829 17.7803 11.9697C17.921 12.1103 18 12.3011 18 12.5C18 12.6989 17.921 12.8897 17.7803 13.0303C17.6397 13.171 17.4489 13.25 17.25 13.25H12.75V17.75C12.75 17.9489 12.671 18.1397 12.5303 18.2803C12.3897 18.421 12.1989 18.5 12 18.5C11.8011 18.5 11.6103 18.421 11.4697 18.2803C11.329 18.1397 11.25 17.9489 11.25 17.75V13.25H6.75C6.55109 13.25 6.36032 13.171 6.21967 13.0303C6.07902 12.8897 6 12.6989 6 12.5C6 12.3011 6.07902 12.1103 6.21967 11.9697C6.36032 11.829 6.55109 11.75 6.75 11.75H11.25V7.25C11.25 7.05109 11.329 6.86032 11.4697 6.71967C11.6103 6.57902 11.8011 6.5 12 6.5Z"
        fill="gray"
      />
    </svg>
  );
}

export default PlusIcon;
