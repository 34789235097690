import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React, { useState, useContext } from "react";
import styled from "styled-components";
import History from "./history/history";
import UserContext, { WithdrawStatus } from "../../context/withdraw-context";
import WithdrawContext from "../../context/withdraw-context";
import Api from "../../../../service";
import { toast } from "react-toastify";
import ConfirmModal from "../../../../components/modal/ConfirmModal";
import ConfirmModalV2 from "../../../../components/modal/ConfirmModal_V2";
import LoadingPage from "../../../../components/LoadingPage";

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-width: 1000px;
  min-height: 820px;
  background-color: #fff;
  padding: 20px;
`;
const Header = styled.div`
  padding: 8px 16px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 24px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const AvatarWrap = styled.div`
  min-width: 80px;
  max-width: 80px;
  max-height: 80px;
`;

const Avatar = styled.img`
  object-fit: cover;
  width: 100%;
  max-height: 80px;
  border-radius: 50%;
`;

const TextStyle = styled.span`
  font-size: 22px;
  font-weight: 600;
  font-family: "Quicksand";
  font-style: normal;
  text-transform: capitalize;
  color: #222222;
`;

const TitleStyle = styled(TextStyle)`
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
  color: gray;
`;

const FullName = styled(TitleStyle)`
  font-size: 26px;
  font-weight: 700;
  color: #222222;
`;

const Email = styled(TextStyle)`
  font-weight: 600;
  font-size: 16px;
  text-transform: none;
  color: #0b7bf4;
  text-decoration: underline;
`;

export const Type = styled.div`
  padding: 4px 8px;
  background: rgba(14, 203, 129, 0.1);
  border-radius: 3px;

  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Dot = styled.span`
  background: #0ecb81;
  border-radius: 50%;
  min-width: 8px;
  width: 8px;
  height: 8px;
`;

export const TypeText = styled.span`
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0ecb81;
  text-transform: capitalize;
`;

const CustomTabContext = styled(TabContext)`
  width: 100%;
  height: 100%;
  background-color: #fff;
`;
const CustomTabPanel = styled(TabPanel)<{ height?: string }>`
  width: 100%;
  background-color: #fff;
  padding: 24px 0 !important;
  ${({ height }) => height && { height: height }}
`;

const CustomTab = styled(Tab)`
  font-size: 18px !important;
  font-weight: 700 !important;
  font-family: "Quicksand" !important;
  font-style: normal !important;
  text-transform: capitalize !important;
  color: #222222 !important;
`;

const LineColumn = styled.span`
  display: block;
  height: 50px;
  width: 2px;
  background-color: gray;
`;
const Status = styled(TextStyle)<{ color: string }>`
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  color: ${({ color }) => (color ? color : "#0b7bf4")};
`;
const CustomButton = styled(LoadingButton)<{ bgColor: string; width?: number }>`
  display: inline-block !important;
  vertical-align: middle !important;
  /* padding: 0 !important; */
  padding: 0 24px !important;
  margin: 0 !important;
  width: ${({ width }) => (width ? `${width}px` : "auto")};
  min-width: 35px !important;
  height: 35px !important;
  line-height: unset !important;
  background-color: ${({ bgColor }) =>
    bgColor ? `${bgColor} !important` : "#54B4D3 !important"};
  color: #fff !important;

  .MuiIcon-root {
    height: auto;
  }
`;
const BtnContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

function Detail() {
  const { node, withdraw, setWithdraw } = useContext(WithdrawContext);
  const [openModalConfirmApprove, setOpenModalConfirmApprove] = useState(false);
  const [openModalConfirmReject, setOpenModalConfirmReject] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [rejectReason, setRejectReason] = useState("");

  const [tab, setTab] = useState(1);
  const handleChange = (e: any, value: number) => {
    setTab(value);
  };

  const handleApprove = async () => {
    setDisableBtn(true);
    const response = await Api.Admin.approvePayout({
      withdrawId: withdraw?._id,
    });
    if (response.data) {
      const newData = response.data;
      node.setData({ ...withdraw, status: newData.status });
      setWithdraw({ ...withdraw, status: newData.status });
      toast.success("Approve succeed.");
    }
    setDisableBtn(false);
    setOpenModalConfirmApprove(false);
  };
  const handleReject = async () => {
    setDisableBtn(true);
    if (rejectReason === "") {
      setDisableBtn(false);
      toast.error("Please enter reject reason");
      return;
    }
    const response = await Api.Admin.rejectPayout({
      withdrawId: withdraw?._id,
      reject_reason: rejectReason,
    });
    if (response.data) {
      const newData = response.data;
      node.setData({ ...withdraw, status: newData.status });
      setWithdraw({ ...withdraw, status: newData.status });
      toast.success("Reject succeed.");
    }
    setDisableBtn(false);
    setOpenModalConfirmReject(false);
  };

  return (
    <>
      {disableBtn && <LoadingPage />}
      <Container>
        <Header>
          <Row>
            <Column>
              <Row>
                <AvatarWrap>
                  <Avatar src={withdraw?.agent?.avatar} />
                </AvatarWrap>
                <Column>
                  <FullName>{withdraw?.agent?.fullname}</FullName>
                  <Row>
                    <TitleStyle>Email :</TitleStyle>
                    <Email>{withdraw?.agent?.email}</Email>
                  </Row>
                </Column>
              </Row>
            </Column>
            <LineColumn />
            <Column>
              <Row>
                <TitleStyle>Withdraw Status :</TitleStyle>
                <Status
                  color={
                    withdraw?.status === WithdrawStatus.Success
                      ? "#2e7d32"
                      : withdraw?.status === WithdrawStatus.Pending
                      ? "#ed6c02"
                      : "#d32f2f"
                  }
                >
                  {withdraw?.status}
                </Status>
                <div>
                  {withdraw?.status === WithdrawStatus.Pending && (
                    <BtnContainer>
                      <CustomButton
                        onClick={() => setOpenModalConfirmApprove(true)}
                        bgColor="#2e7d32"
                      >
                        Approve
                      </CustomButton>
                      <CustomButton
                        onClick={() => setOpenModalConfirmReject(true)}
                        bgColor="#f01f1f"
                      >
                        Reject
                      </CustomButton>
                    </BtnContainer>
                  )}
                </div>
              </Row>
            </Column>
          </Row>
        </Header>
        <CustomTabContext value={tab.toString()}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <CustomTab label="Withdraw Information" value="1" />
            </TabList>
          </Box>
          <CustomTabPanel height="calc(100% - 150px)" value="1">
            <History />
          </CustomTabPanel>
        </CustomTabContext>
      </Container>
      {/* confirm modal */}
      <ConfirmModalV2
        isOpen={openModalConfirmApprove}
        disable={disableBtn}
        body="Are you sure you want to approve this withdraw?"
        setIsOpen={setOpenModalConfirmApprove}
        onConfirm={handleApprove}
      />
      <ConfirmModalV2
        isOpen={openModalConfirmReject}
        disable={disableBtn}
        body="Are you sure you want to reject this withdraw?"
        inputText={rejectReason}
        setInputText={setRejectReason}
        setIsOpen={setOpenModalConfirmReject}
        onConfirm={handleReject}
      />
    </>
  );
}

export default Detail;
