import { useEffect, useState } from 'react'
import { IMaterial } from '../../../../constants/interface';
import Api from '../../../../service';
import { MaterialType } from '../../../../constants/types';
import { toast } from 'react-toastify';

const useMaterialsLibraryOther = ({ refresh }: { refresh?: boolean }) => {
    const [othersData, setOthersData] = useState<IMaterial[]>()
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async () => {
        try {
            setIsLoading(true)
            const res = await Api.MaterialsLib.getMaterials({ type: MaterialType.OTHER })
            if (res.data) {
                setOthersData(res.data.materials)
            }
        } catch (error) {
            toast.error(error as string);
        } finally {
            setIsLoading(false)
        }

    }

    useEffect(() => {
        fetchData();
    }, [refresh])

    return {
        othersData,
        isLoading
    }
}

export default useMaterialsLibraryOther