import { AgGridReact } from "@ag-grid-community/react";
import { Button, Modal } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";
import React, { useContext, useRef, useState } from "react";
import styled from "styled-components";
import Api from "../../../../../service";
import CommentContext from "./comment-context";
import LoadingButton from "@mui/lab/LoadingButton";
import ButtonCRUD from "../../../../../components/buttonCRUD";
import { CRUDType } from "../../../../../components/buttonCRUD";
import { NewfeedCommentStatus } from "../../../../../constants/types";
import { toast } from "react-toastify";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ConfirmChangeStatus = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 16px;
  color: #222222;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const ConfirmHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const ConfirmTitle = styled.span`
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
`;

const ConfirmButton = styled.div<{ background: string }>`
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  background: ${({ background }) =>
    background ? background : "rgb(36, 107, 212)"};
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
`;

const CustomModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  width: 30%;
  height: 20vh;
  max-height: 20vh;
  overflow-y: hidden;
`;

const CustomButton = styled(LoadingButton)<{ bgColor: string; width?: number }>`
  display: inline-block !important;
  vertical-align: middle !important;
  /* padding: 0 !important; */
  padding: 0 24px !important;
  margin: 0 !important;
  width: ${({ width }) => (width ? `${width}px` : "auto")};
  min-width: 120px !important;
  height: 35px !important;
  line-height: unset !important;
  background-color: ${({ bgColor }) =>
    bgColor ? `${bgColor} !important` : "#54B4D3 !important"};
  color: #fff !important;

  .MuiIcon-root {
    height: auto;
  }
`;

const DetailBtn = styled(Button)``;
const Wrap = styled.div``;

export enum TypePostComment {
  Approve = "APPROVE",
  CancelApprove = "CANCEL_APPROVE",
  Delete = "DELETE",
}

function ActionsCell(props: ICellRendererParams) {
  const commentCtx = useContext(CommentContext);
  const [typeSubmit, setTypeSubmit] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
  const { node, setNode, comment, setComment, isReloadList, setIsReloadList } =
    commentCtx;

  const onClickApproveBtn = async () => {
    console.log("approve comment !");
  };

  const handleUpdateStatus = async () => {
    if (!typeSubmit || !props?.data?._id) {
      toast.error("Cannot update !");
      return;
    }

    // if (typeSubmit === TypePostComment.CancelApprove && !reason) {
    //   setIsCheckError(true);
    //   return;
    // }

    try {
      setLoading(true);

      const response =
        typeSubmit === TypePostComment.Approve
          ? await Api.NewsfeedComment.approveComment(props?.data?._id)
          : await Api.NewsfeedComment.cancelApproveComment(props?.data?._id);

      if (response) {
        setComment(response.data);
        node?.setData({
          ...props?.data,
          status: response?.data?.status || NewfeedCommentStatus.Pending,
        });
        toast.success(
          typeSubmit === TypePostComment.Approve
            ? "Approve success !"
            : "Cancel success !"
        );
        //setReason("");
        //setIsCheckError(false);
        handleCloseConfirmModal();
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseConfirmModal = () => {
    setIsOpenConfirmModal(false);
    //setIsCheckError(false);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);

      const response = await Api.NewsfeedComment.deleteComment(
        props?.data?._id
      );
      if (response) {
        setIsReloadList(!isReloadList);
        toast.success("Deleted !");
      }
    } catch (error) {
      console.log("error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmChangeStatus = (
    // event: React.MouseEvent<HTMLButtonElement>,
    typeSubmit: string
  ) => {
    setIsOpenConfirmModal(true);
    setTypeSubmit(typeSubmit);
    setNode(props.node);
    setComment(props.data);
  };

  return (
    <Container>
      <Wrap>
        {props?.data?.status === NewfeedCommentStatus.Pending && (
          <CustomButton
            onClick={() => handleConfirmChangeStatus(TypePostComment.Approve)}
            bgColor="#2e7d32"
          >
            Approve
          </CustomButton>
        )}
        {props?.data?.status === NewfeedCommentStatus.Approved && (
          <CustomButton
            onClick={() =>
              handleConfirmChangeStatus(TypePostComment.CancelApprove)
            }
            bgColor="gray"
          >
            Cancel
          </CustomButton>
        )}
      </Wrap>
      <Wrap onClick={() => handleConfirmChangeStatus(TypePostComment.Delete)}>
        <ButtonCRUD type={CRUDType.Delete} size="medium" />
      </Wrap>

      <CustomModal
        open={isOpenConfirmModal}
        onClose={() => handleCloseConfirmModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContainer>
          <ConfirmChangeStatus>
            <ConfirmHeader>
              <ConfirmTitle>
                {typeSubmit === TypePostComment.Approve
                  ? "Are you sure APPROVE?"
                  : typeSubmit === TypePostComment.CancelApprove
                  ? "Are you sure CANCEL?"
                  : "Are you sure DELETE?"}
              </ConfirmTitle>
            </ConfirmHeader>

            <CustomButton
              loading={loading}
              disabled={loading}
              loadingPosition="start"
              startIcon={<></>}
              onClick={() =>
                typeSubmit === TypePostComment.Delete
                  ? handleDelete()
                  : handleUpdateStatus()
              }
              bgColor="rgb(36, 107, 212)"
            >
              Confirm
            </CustomButton>
          </ConfirmChangeStatus>
        </ModalContainer>
      </CustomModal>
    </Container>
  );
}

export default ActionsCell;
