import ListSubscriber from "./components/Subscribers";

function Subscribers() {
  return (
    <>
      <ListSubscriber />
    </>
  );
}

export default Subscribers;
