import { ICellRendererParams } from "ag-grid-community";
import styled from "styled-components";
import useProductKey from "../../ProductKey.hooks";
import ButtonCRUD, { CRUDType } from "../../../../components/buttonCRUD";
import { IProductKey, ProductKeyStatus } from "../../constants";
import React from "react";
import { useModal } from "../../../../hooks/use-modal";
import { ModalKey } from "../../../../context/modal-context";

function ActionsCell(props: ICellRendererParams) {
  const data = props.data as IProductKey;
  const { openModal } = useModal();

  return (
    <Container>
      {data.status === ProductKeyStatus.Active && (
        <>
          <ButtonCRUD
            type={CRUDType.Deactivate}
            size="medium"
            onClick={() => openModal(ModalKey.PRODUCT_KEY_DEACTIVATE)}
          />
        </>
      )}
      {data.status === ProductKeyStatus.InActive && data.deactivate_date && (
        <>
          <ButtonCRUD
            type={CRUDType.Reactive}
            size="medium"
            onClick={() => openModal(ModalKey.PRODUCT_KEY_REACTIVATE)}
          />
        </>
      )}
    </Container>
  );
}

export default React.memo(ActionsCell);

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
`;
