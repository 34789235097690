import { Chip } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
`;
const StatusText = styled.span<{ color: string }>`
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  color: ${({ color }) => (color ? color : "#0b7bf4")};
`;

enum Status {
  Pending = "PENDING",
  Approved = "APPROVED",
  Block = "BLOCK",
}
function StatusField(props: ICellRendererParams) {
  const status = props.data.status.toUpperCase();

  const renderStatus = () => {
    switch (status) {
      case Status.Pending:
        return <StatusText color="#ed6c02">{Status.Pending}</StatusText>;
      case Status.Approved:
        return <StatusText color="#2e7d32">{Status.Approved}</StatusText>;
      case Status.Block:
        return <StatusText color="#d32f2f">{Status.Block}</StatusText>;
    }
  };

  return <Container>{renderStatus()}</Container>;
}

export default StatusField;
