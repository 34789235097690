import { IRowNode } from "ag-grid-community";
import React, { createContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { User } from "../../../constants/interface";
import Api from "../../../service";
import { toast } from "react-toastify";

interface Props {
  children: React.ReactNode;
}
// Define the context interface
interface UserContextType {
  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;

  isOpenDetailModal: boolean;
  setIsOpenDetailModal: React.Dispatch<React.SetStateAction<boolean>>;

  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;

  node: any;
  setNode: React.Dispatch<any>;
}

// Create the context
const UserContext = createContext<UserContextType>({
  /*eslint-disable */
  isOpenModal: false,
  setIsOpenModal: () => {},
  isOpenDetailModal: false,
  setIsOpenDetailModal: () => {},
  user: null,
  setUser: () => {},
  node: "",
  setNode: () => {},
  /*eslint-disable */
});

// Custom Provider component
export const UserContextProvider = (props: Props) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenDetailModal, setIsOpenDetailModal] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [node, setNode] = useState<any | null>(null);

  return (
    <UserContext.Provider
      value={{
        isOpenModal,
        setIsOpenModal,
        isOpenDetailModal,
        setIsOpenDetailModal,
        user,
        setUser,
        node,
        setNode,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export const UserOutlet = (props: any) => {
  return (
    <UserContextProvider>
      {props.children ? props.children : <Outlet />}
    </UserContextProvider>
  );
};
export default UserContext;
