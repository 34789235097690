import React, { useState } from "react";
import { useModal } from "../../../../../hooks/use-modal";
import ConfirmModalV4 from "../../../../../components/modal/ConfirmModal_V4";
import { ModalKey } from "../../../../../context/modal-context";
import { toast } from "react-toastify";
import LoadingPage from "../../../../../components/LoadingPage";
import useMaterialsLibrary from "../../../MaterialsLibrary.hooks";
import Api from "../../../../../service";
import ConfirmModalCheckBox from "../../../../../components/modal/ConfirmModalCheckBox";

const ActionConfirmModal = () => {
  const { isModalOpen, closeModal } = useModal();
  const { materialCollection, refreshTable } = useMaterialsLibrary();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteAllChildren, setIsDeleteAllChildren] = useState(false);

  const onDeleteMaterialHandler = async () => {
    setIsLoading(true);
    try {
      if (!materialCollection?._id) return;

      const response = await Api.MaterialsLib.deleteCollection({
        collection_id: materialCollection?._id,
        delete_all_children: isDeleteAllChildren,
      });

      if (response.data) {
        toast.success(`${materialCollection.title} is deleted!`);
        closeModal(ModalKey.MATERIALS_LIBRARY_DELETE);
        refreshTable();
      }
    } catch (error) {
      toast.error(error as string);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <LoadingPage />;

  return (
    <>
      <ConfirmModalCheckBox
        isOpen={isModalOpen(ModalKey.MATERIALS_LIBRARY_DELETE)}
        body="Are you sure you want to DELETE this material?"
        onClose={() => closeModal(ModalKey.MATERIALS_LIBRARY_DELETE)}
        onConfirm={onDeleteMaterialHandler}
        setIsDeleteAllChildren={setIsDeleteAllChildren}
      />
    </>
  );
};

export default ActionConfirmModal;
