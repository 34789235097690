import React from "react";
import styled from "styled-components";
import * as XLSX from "xlsx-js-style";

const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;
`;

const CreateButton = styled.div`
  padding: 8px 24px;
  background: rgb(85, 177, 71);
  color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
`;

const ButtonText = styled.span`
  font-size: 18px;
`;

interface colsWidth {
  width?: number;
}

export interface styleDataOfColumn {
  column: string;
  style: any;
}

const ExportExcelV2 = (props: any) => {
  return (
    <ButtonWrap>
      <CreateButton {...props}>
        <ButtonText>Export Excel</ButtonText>
      </CreateButton>
    </ButtonWrap>
  );
};
export default ExportExcelV2;
