export interface IProductKey {
  code: string;
  device_id: string;
  application: ProductKeyApplication;
  activation_date: number;
  expiration_date: number;
  deactivate_date: number;
  status: ProductKeyStatus;
}

export enum ProductKeyStatus {
  Active = "ACTIVE",
  InActive = "INACTIVE"
}

export enum ProductKeyApplication {
  PoolsWallet = "POOLS_WALLET",
  FriendifyAI = "FRIENDIFY_AI",
}
