import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";

import {
  ColDef,
  FirstDataRenderedEvent,
  ModuleRegistry,
  PaginationNumberFormatterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Api from "../../../../service";
import { convertDateToYYYYMMDD_HHmm } from "../../../../utils";
import { PageSizeContainer, TableContainer } from "../table/OrderTable";
import ActionsCell from "./ActionsCell";
import OrderContext from "../../context/order-context";

export type TSupportRequestCancel = {
  full_name: string;
  email: string;
  phone_number: string;
  reason: string;
  user: any;
  createdAt?: any;
  order: {
    orders_code: string;
  };
};
// Register the required feature modules with the Grid
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const SupportRequestCancelTable = () => {
  const cellStyle = {
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
  };
  const TotalStyle = {
    fontWeight: "700",
    display: "flex",
    alignItems: "center",
  };
  const orderCtx = useContext(OrderContext);
  const { isReloadSupportRequestList } = orderCtx;
  const gridRef = useRef<AgGridReact<TSupportRequestCancel>>(null);
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(
    () => ({ height: "100%", width: "100%", padding: "0 20px" }),
    []
  );
  const [rowData, setRowData] = useState<TSupportRequestCancel[]>();
  const [columnDefs] = useState<ColDef[]>([
    {
      field: "order.orders_code",
      headerName: "Order Code",
      cellStyle: cellStyle,
      filter: true,
    },
    {
      field: "full_name",
      headerName: "Full Name",
      cellStyle: cellStyle,
    },
    {
      field: "email",
      headerName: "Email",
      cellStyle: cellStyle,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      cellStyle: cellStyle,
    },
    {
      field: "createdAt",
      cellStyle: cellStyle,
      filter: false,
      floatingFilter: false,
    },
    {
      colId: "action",
      headerName: "",
      floatingFilter: false,
      cellRenderer: ActionsCell,
      filter: false,
      resizable: false,
      minWidth: 200,
    },
  ]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      editable: true,

      sortable: true,
      resizable: true,
      flex: 1,
      minWidth: 100,
    };
  }, []);

  const gridOptions = {
    suppressRowTransform: true,
    enableRangeSelection: true,
    copyHeadersToClipboard: true,
  };

  const paginationNumberFormatter = useCallback(
    (params: PaginationNumberFormatterParams) => {
      return "[" + params.value.toLocaleString() + "]";
    },
    []
  );

  const onGridReady = useCallback(async () => {
    let response = await Api.SupportRequest.getSupportRequestCancel();
    const orders: TSupportRequestCancel[] = response.data;
    const formattedDateOrders = orders?.map((order: TSupportRequestCancel) => {
      return {
        ...order,
        createdAt: `${convertDateToYYYYMMDD_HHmm(
          order?.createdAt || new Date()
        )}`,
      };
    });
    setRowData(formattedDateOrders);
  }, []);

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    gridRef.current!.api.paginationGoToPage(0);
    gridRef.current!.api.paginationSetPageSize(20);
    gridRef?.current?.api.sizeColumnsToFit({
      defaultMinWidth: 100,
      columnLimits: [{ key: "action", minWidth: 300 }],
    });
  }, []);

  useEffect(() => {
    onGridReady();
  }, [onGridReady, isReloadSupportRequestList]);

  const onPageSizeChanged = useCallback(() => {
    var value = (document.getElementById("page-size") as HTMLInputElement)
      .value;
    gridRef.current!.api.paginationSetPageSize(Number(value));
  }, []);

  return (
    <TableContainer>
      <div style={containerStyle}>
        <div
          className="example-wrapper"
          style={{ width: "100%", height: "100%", position: "relative" }}
        >
          <div style={gridStyle} className="ag-theme-alpine">
            <AgGridReact<TSupportRequestCancel>
              ref={gridRef}
              rowData={rowData}
              getRowHeight={() => 60}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              pivotPanelShow={"always"}
              pagination={true}
              animateRows={true}
              paginationPageSize={10}
              gridOptions={gridOptions}
              paginationNumberFormatter={paginationNumberFormatter}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
          </div>

          <PageSizeContainer className="example-header">
            Page Size:{" "}
            <select onChange={onPageSizeChanged} id="page-size">
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="500">500</option>
            </select>
          </PageSizeContainer>
        </div>
      </div>
    </TableContainer>
  );
};

export default React.memo(SupportRequestCancelTable);
