import React, { createContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Media } from "../../../constants/interface";
import { toast } from "react-toastify";
import { LangType } from "../../../constants/types";

interface Props {
  children: React.ReactNode;
}
// Define the context interface
interface MediasContextType {
  isReloadList: boolean;
  setIsReloadList: React.Dispatch<React.SetStateAction<boolean>>;

  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;

  media: Media | null;
  setMedia: React.Dispatch<React.SetStateAction<Media | null>>;

  node: any;
  setNode: React.Dispatch<any>;
}

// Create the context
const MediaContext = createContext<MediasContextType>({
  /*eslint-disable */
  isReloadList: false,
  setIsReloadList: () => {},
  isOpenModal: false,
  setIsOpenModal: () => {},
  media: null,
  setMedia: () => {},
  node: "",
  setNode: () => {},
});

// Custom Provider component
export const MediaContextProvider = (props: Props) => {
  const [isReloadList, setIsReloadList] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [media, setMedia] = useState<Media | null>(null);
  const [node, setNode] = useState<any | null>(null);

  return (
    <MediaContext.Provider
      value={{
        isReloadList,
        setIsReloadList,
        isOpenModal,
        setIsOpenModal,
        media,
        setMedia,
        node,
        setNode,
      }}
    >
      {props.children}
    </MediaContext.Provider>
  );
};

export const MediaOutlet = (props: any) => {
  return (
    <MediaContextProvider>
      {props.children ? props.children : <Outlet />}
    </MediaContextProvider>
  );
};
export default MediaContext;
