import React, { useContext } from "react";
import { OrderStatusType, SummaryKeys } from "../../../constants";
import styled from "styled-components";
import useTrackingOrder, { ITracking } from "../../../hooks/use-tracking-order";
import { useSearchParams } from "react-router-dom";
import useTrackingShipment from "../../../hooks/use-tracking-shipment";
import TrackingShipment from "./TrackingShipment";
import OrderContext from "../../../context/order-context";

export const TrackingStatusC = ({
  title,
  trackingData,
}: {
  title: string;
  trackingData: ITracking[] | null;
}) => {
  return (
    <RestStatusContainer>
      <h2>{title}</h2>
      {trackingData && trackingData.length > 0 ? (
        trackingData.map((item, index) => (
          <Row key={index}>
            <div>{item.time}</div>
            {trackingData.length === 1 ? (
              <BreakOneItem />
            ) : index === 0 ? (
              <FirstBreak />
            ) : index === trackingData.length - 1 ? (
              <EndBreak />
            ) : (
              <Break />
            )}
            <div>{item.status}</div>
          </Row>
        ))
      ) : (
        <p>No data</p>
      )}
    </RestStatusContainer>
  );
};
const TrackingStatus = () => {
  // const { order } = useContext(OrderContext);
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");

  const { data: trackingData } = useTrackingOrder({
    orderId: orderId as string,
  });

  return (
    <Container>
      <TrackingStatusC title={"Status"} trackingData={trackingData} />
      {/* {(order?.status === OrderStatusType.Shipping ||
        order?.status === OrderStatusType.Delivered) && <TrackingShipment />} */}
    </Container>
  );
};

export default TrackingStatus;

export const Break = styled.div`
  width: 1px;
  height: 100%;
  background-color: #323232;
  position: relative;
  ::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background-color: #000;
    border-radius: 50%;
  }
`;
export const BreakOneItem = styled(Break)`
  width: 0 !important;
`;

export const FirstBreak = styled(Break)`
  height: 50% !important;
  align-self: flex-end !important;
  ::after {
    top: 0% !important;
    transform: translateX(-50%) !important;
  }
`;
export const EndBreak = styled(Break)`
  height: 42% !important;
  align-self: flex-start !important;
  ::after {
    top: 100% !important;
    transform: translateX(-50%) !important;
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  height: 6rem;
  justify-content: space-between;
  align-items: center;
  > div {
    height: 100%;

    :first-child {
      width: 40%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    /* :nth-child(2) {
      width: 1px;
      height: 100%;
      background-color: #323232;
      position: relative;
      ::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        transform: translate(-50%, -50%);
        background-color: #000;
        border-radius: 50%;
      }
    } */
    :last-child {
      width: 40%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      font-weight: bold;
    }
  }
`;

export const RestStatusContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
  /* row-gap: 2rem; */
  > p {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 2rem;
    font-weight: bold;
    font-style: italic;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
