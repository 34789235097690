import { useContext } from 'react'
import ProductKeyContext from './context/product-key-context';

const useProductKey = () => {
    const productKeyCtx = useContext(ProductKeyContext);
    if (!productKeyCtx) throw new Error('useProductKey must be used within a ProductKeyProvider');

    return productKeyCtx;
}

export default useProductKey