import { ICellRendererParams } from "ag-grid-community";
import React, { useContext } from "react";
import styled from "styled-components";
import ButtonCRUD from "../../../components/buttonCRUD";
import { CRUDType } from "../../../components/buttonCRUD";
import WithdrawContext from "../context/withdraw-context";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
const Wrap = styled.div``;

function ActionsCell(props: ICellRendererParams) {
  const { setIsOpenDetailModal, setNode, setWithdraw } =
    useContext(WithdrawContext);

  const onClickDetailBtn = async () => {
    setIsOpenDetailModal(true);
    setNode(props.node);
    setWithdraw(props.data);
  };
  return (
    <Container>
      <Wrap onClick={onClickDetailBtn}>
        <ButtonCRUD type={CRUDType.View} size="medium" />
      </Wrap>
    </Container>
  );
}

export default ActionsCell;
