import { Button, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import UserContext from "../context/user-context";

const Container = styled.div`
  width: 500px;
  height: 300px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
`;
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;
const ConfirmBtn = styled(Button)`
  background-color: rgba(0, 176, 0, 0.8) !important;
  color: #fff !important;
  transition: all 0.3s !important;
  :hover {
    filter: brightness(1.2);
  }
`;
const CancelBtn = styled(ConfirmBtn)`
  background-color: rgba(255, 187, 0, 0.8) !important;
  color: #000 !important;
`;

function AddPoint() {
  const userCtx = useContext(UserContext);
  const { setIsOpenModal } = userCtx;
  const [point, setPoint] = useState<number>(0);

  const onConfirm = () => {};

  return (
    <Container>
      <TextField
        id="outlined-controlled"
        label="Point"
        type="number"
        value={point}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setPoint(+event.target.value);
        }}
      />
      <ButtonContainer>
        <CancelBtn onClick={() => setIsOpenModal(false)}>Cancel</CancelBtn>
        <ConfirmBtn onClick={onConfirm}>Confirm</ConfirmBtn>
      </ButtonContainer>
    </Container>
  );
}

export default AddPoint;
