import React, { useContext, useEffect, useState } from "react";
import AgentContext from "../../../context/agent-context";
import AuthContext from "../../../../../context/auth-context";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import {
  Chip,
  FormControl,
  FormControlLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { breakPoints } from "../../../../../constants/device";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import useCountry from "../../../../../hooks/use-country";
import useCity from "../../../../../hooks/use-city";
import useDistrict from "../../../../../hooks/use-district";
import dayjs, { Dayjs } from "dayjs";
import { Gender } from "../../../../../constants/interface";
import {
  BUSINESS_AREA_LIST_DEFAULT,
  CURRENT_JOB_LIST_DEFAULT,
  SALE_EXPERIENCE_LIST,
  SALE_VOLUME_LIST,
} from "../constants";
import Api from "../../../../../service";
import { toast } from "react-toastify";
import CustomModal from "../../../../../components/modal/CustomModal";
import FileInput from "./FileInput";
import { IPhoto } from "../../../../../constants/types";

export interface ISelect {
  value: string;
  label: string;
}

export interface AgentUpdate {
  fullname?: string;
  email?: string;
  phone?: string;
  phone_code?: string;
  country?: string | number;
  state?: string;
  state_id?: string;
  state_other?: string;
  city?: string;
  city_id?: string;
  city_other?: string;
  zip_code?: string | number;
  gender?: Gender;
  dob?: string;
  main_channel?: string;
  communication_channel?: string;
  currently_job?: string;
  currently_job_custom?: string;
  sale_experience?: string;
  sales_volume?: string;
  areas_of_bussiness?: string;
  areas_of_bussiness_custom?: string;
  about_yourself?: string;
  address_detail?: string;
  outline_reason?: string;
  as_an_agent?: string;
  what_do_you_think?: string;
  provider?: string;
}

function Profile() {
  const userCtx = useContext(AgentContext);
  const { node, agent, setAgent, setIsOpenDetailModal } = userCtx;

  const { listCountries } = useCountry();
  const { listCities, fetchCities } = useCity();
  const { listDistricts, fetchDistricts, setListDistricts } = useDistrict();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [imgPopup, setImgPopup] = useState<string | null>(null);

  const [chosenImageSelfie, setChosenImageSelfie] = useState<IPhoto>();
  const [chosenIDFront, setChosenIDFront] = useState<IPhoto>();
  const [chosenIDBack, setChosenIDBack] = useState<IPhoto>();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm<AgentUpdate>({
    defaultValues: {
      fullname: agent?.fullname,
      email: agent?.email,
      phone: agent?.phone?.split(" ")[1],
      phone_code: agent?.phone?.split(" ")[0],
      country: agent?.country,
      state: agent?.state_id === "0" ? "Others" : agent?.state,
      state_id: agent?.state_id,
      state_other: agent?.state_id === "0" ? agent?.state : "",
      city: agent?.city_id === "0" ? "Others" : agent?.city,
      city_id: agent?.city_id,
      city_other: agent?.city_id === "0" ? agent?.city : "",
      zip_code: agent?.zip_code,
      dob: agent?.dob,
      gender: agent?.gender,
      currently_job: agent?.currently_job,
      currently_job_custom: agent?.currently_job_custom,
      sale_experience: agent?.sale_experience,
      sales_volume: agent?.sales_volume,
      areas_of_bussiness: agent?.areas_of_bussiness,
      areas_of_bussiness_custom: agent?.areas_of_bussiness_custom,
      about_yourself: agent?.about_yourself,
      address_detail: agent?.address_detail,
      outline_reason: agent?.outline_reason,
      as_an_agent: agent?.as_an_agent,
      what_do_you_think: agent?.what_do_you_think,
      provider: agent?.provider,
    },
    mode: "onBlur",
  });

  const country = watch("country");
  const country_id = listCountries.find(
    (countryItem) => countryItem.name === country
  )?.id;

  const province = watch("state");
  const province_id = listCities.find(
    (provinceItem) => provinceItem.name === province
  )?.id;

  const district = watch("city");
  const district_id = listDistricts.find((item) => item.name === district)?.id;

  const phoneCodeWatch = watch("phone_code");
  const handleOpenEdit = (e: any) => {
    e.preventDefault();
    setIsEdit(true);
  };

  const handleCancelEdit = () => {
    setIsEdit(false);
  };

  const makeUpPhoneCode = () => {
    const filteredPhoneCodeNumber = listCountries.filter(
      (item) => !item.phoneCode.includes("+1-")
    );
    const filteredPhoneCodeString = listCountries.filter((item) =>
      item.phoneCode.includes("+1-")
    );
    const sortedPhoneCodeNumber = filteredPhoneCodeNumber.sort(function (a, b) {
      return Number(a.phoneCode) - Number(b.phoneCode);
    });
    const sortedPhoneCodeString = filteredPhoneCodeString.sort();

    return [...sortedPhoneCodeNumber, ...sortedPhoneCodeString];
  };

  const uploadImage = async (imgValue: string) => {
    let formData = new FormData();
    formData.append("image", imgValue);

    const response = await Api.Agent.uploadImage(formData);

    if (response.data) {
      return { imageUrl: response?.data?.imageUrl };
    }
    return { imageUrl: "" };
  };

  const onSubmit = async (data: AgentUpdate) => {
    // upload image
    try {
      setIsLoading(true);
      const uploadedImageSelfie = chosenImageSelfie?.formDataValue
        ? await (
            await uploadImage(chosenImageSelfie?.formDataValue)
          )?.imageUrl
        : agent?.image_selfie;
      const uploadedIDFront = chosenIDFront?.formDataValue
        ? await (
            await uploadImage(chosenIDFront?.formDataValue)
          )?.imageUrl
        : agent?.image_card_identify_front;
      const uploadedIDBack = chosenIDBack?.formDataValue
        ? await (
            await uploadImage(chosenIDBack?.formDataValue)
          )?.imageUrl
        : agent?.image_card_identify_back;

      const dataToPost = {
        agentId: agent?._id,
        ...data,
        state: data.state === "Others" ? data.state_other : data.state,
        state_id: data.state === "Others" ? "0" : province_id,
        city: data.city === "Others" ? data.city_other : data.city,
        city_id: data.city === "Others" ? "0" : district_id,
        zip_code: +(data?.zip_code || 0),
        phone: data.phone_code + " " + data.phone,
        dob: dayjs(data.dob).format("DD MMM YYYY").toString(),
        image_selfie: uploadedImageSelfie,
        image_card_identify_front: uploadedIDFront,
        image_card_identify_back: uploadedIDBack,
      };

      delete dataToPost.email;
      delete dataToPost.provider;
      delete dataToPost.phone_code;
      delete dataToPost.state_other;
      delete dataToPost.city_other;

      const response = await Api.Agent.updateProfile(dataToPost);
      if (response.data) {
        node.setData(response.data);
        setAgent(response.data);
        setIsEdit(false);
        toast.success("Update agent profile success !");
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (district_id !== "0") {
      setValue("city_other", undefined);
    }
  }, [district_id]);
  useEffect(() => {
    if (country_id) {
      fetchCities(+country_id);
      setListDistricts([]);
      // select phone code follow country
      const selectedCountryObj = listCountries.find((c) => c.name === country);
      setValue("phone_code", selectedCountryObj?.phoneCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  useEffect(() => {
    if (province_id && country_id) {
      fetchDistricts(+country_id, +province_id);
      if (province_id === "0") {
        setValue("city", "Others");
      } else {
        setValue("state_other", undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [province_id, country_id]);

  useEffect(() => {
    const selectedCountryObj = listCountries.find(
      (c) => c.phoneCode === phoneCodeWatch
    );
    setValue("country", selectedCountryObj?.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneCodeWatch]);

  useEffect(() => {
    if (agent?.country_id) {
      fetchCities(+agent.country_id);
      if (agent?.state_id) {
        fetchDistricts(+agent.country_id, +agent.state_id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agent]);
  return (
    <Container>
      <Wrapper>
        <Row isContainer>
          <Column style={{ minWidth: "30%" }}>
            <GroupTitleStyle>General Information</GroupTitleStyle>
            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Full Name</TitleStyle>
              </WrapText>
              <Wrap>
                {!isEdit ? (
                  <WrapText>
                    <TextStyle>
                      {agent?.fullname ? agent?.fullname : "-"}
                    </TextStyle>
                  </WrapText>
                ) : (
                  <WrapInput>
                    <TextField
                      {...register("fullname", {
                        required: {
                          message: "Full name is required",
                          value: true,
                        },
                        pattern: {
                          value: new RegExp(/^[\p{L} ]+$/u),
                          message: "Full name is not valid",
                        },
                      })}
                      fullWidth
                      id="outlined"
                      size="small"
                    />
                    {errors.fullname && (
                      <ErrorField>{errors.fullname.message}</ErrorField>
                    )}
                  </WrapInput>
                )}
              </Wrap>
            </Row>
            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Email</TitleStyle>
              </WrapText>
              <Wrap>
                <WrapText>
                  <TextStyle>{agent?.email ? agent?.email : "-"}</TextStyle>
                </WrapText>
                {/* {!isEdit ? (
                  <WrapText>
                    <TextStyle>{agent?.email ? agent?.email : "-"}</TextStyle>
                  </WrapText>
                ) : (
                  <WrapInput>
                    <TextField
                      required
                      {...register("email")}
                      fullWidth
                      id="outlined"
                      size="small"
                    />
                  </WrapInput>
                )} */}
              </Wrap>
            </Row>
            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Country</TitleStyle>
              </WrapText>
              <Wrap>
                {!isEdit ? (
                  <WrapText>
                    <TextStyle>
                      {agent?.country ? agent?.country : "-"}
                    </TextStyle>
                  </WrapText>
                ) : (
                  <WrapInput>
                    <FormControl fullWidth>
                      <Controller
                        name="country"
                        control={control}
                        render={({ field }) => (
                          <Select
                            fullWidth
                            size="small"
                            id="country"
                            {...field}
                          >
                            {listCountries?.map((country) => (
                              <MenuItem key={country._id} value={country.name}>
                                {country.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </WrapInput>
                )}
              </Wrap>
            </Row>
            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Province</TitleStyle>
              </WrapText>
              <Wrap>
                {!isEdit ? (
                  <WrapText>
                    <TextStyle>{agent?.state ? agent?.state : "-"}</TextStyle>
                  </WrapText>
                ) : (
                  <WrapInput>
                    <FormControl fullWidth>
                      <Controller
                        name="state"
                        control={control}
                        render={({ field }) => (
                          <Select fullWidth size="small" id="state" {...field}>
                            {listCities?.map((city) => (
                              <MenuItem key={city._id} value={city.name}>
                                {city.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                    {province_id === "0" && (
                      <>
                        <TextField
                          required
                          {...register("state_other", {
                            required: {
                              message: "Province is required",
                              value: true,
                            },
                            pattern: {
                              value: new RegExp(/^[\p{L} ]+$/u),
                              message: "Province is not valid",
                            },
                          })}
                          fullWidth
                          id="outlined"
                          size="small"
                          sx={{ marginTop: "0.8rem" }}
                          placeholder="Enter your province"
                        />
                        {errors.state_other && (
                          <ErrorField>{errors.state_other.message}</ErrorField>
                        )}
                      </>
                    )}
                  </WrapInput>
                )}
              </Wrap>
            </Row>
            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>District</TitleStyle>
              </WrapText>
              <Wrap>
                {!isEdit ? (
                  <WrapText>
                    <TextStyle>{agent?.city ? agent?.city : "-"}</TextStyle>
                  </WrapText>
                ) : (
                  <WrapInput>
                    <FormControl fullWidth>
                      <Controller
                        name="city"
                        control={control}
                        render={({ field }) => (
                          <Select fullWidth size="small" id="state" {...field}>
                            {listDistricts?.map((district) => (
                              <MenuItem
                                key={district._id}
                                value={district.name}
                              >
                                {district.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                    {district === "Others" && (
                      <>
                        <TextField
                          required
                          {...register("city_other", {
                            required: {
                              message: "District is required",
                              value: true,
                            },
                            pattern: {
                              value: new RegExp(/^[\p{L}\d ]+$/u),
                              message: "District is not valid",
                            },
                          })}
                          fullWidth
                          id="outlined"
                          size="small"
                          sx={{ marginTop: "0.8rem" }}
                          placeholder="Enter your district"
                        />
                        {errors.city_other && (
                          <ErrorField>{errors.city_other.message}</ErrorField>
                        )}
                      </>
                    )}
                  </WrapInput>
                )}
              </Wrap>
            </Row>
            <Row marginLeft={50}>
              <WrapTextRightColumn>
                <TitleStyle>Address Detail</TitleStyle>
              </WrapTextRightColumn>
              <Wrap>
                {!isEdit ? (
                  <WrapText>
                    <TextStyle>
                      {agent?.address_detail ? agent?.address_detail : "-"}
                    </TextStyle>
                  </WrapText>
                ) : (
                  <WrapInput>
                    <TextField
                      {...register("address_detail")}
                      fullWidth
                      id="outlined"
                      size="small"
                    />
                  </WrapInput>
                )}
              </Wrap>
            </Row>
            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Phone</TitleStyle>
              </WrapText>
              <Wrap>
                {!isEdit ? (
                  <WrapText>
                    <TextStyle>{agent?.phone ? agent?.phone : "-"}</TextStyle>
                  </WrapText>
                ) : (
                  <>
                    <WrapPhone>
                      <FormControl>
                        <Controller
                          name="phone_code"
                          control={control}
                          render={({ field }) => (
                            <Select
                              sx={{ minWidth: "8rem" }}
                              size="small"
                              id="phone_code"
                              {...field}
                            >
                              {makeUpPhoneCode()?.map((countryItem) => (
                                <MenuItem
                                  key={countryItem._id}
                                  value={countryItem.phoneCode}
                                >
                                  <CustomLabelPhoneCodeSelect>
                                    <span>
                                      {!countryItem.phoneCode.includes("+") && (
                                        <span>+&nbsp;</span>
                                      )}
                                      {countryItem.phoneCode}
                                    </span>
                                    <span>{countryItem.emoji}</span>
                                  </CustomLabelPhoneCodeSelect>
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      </FormControl>
                      <TextField
                        {...register("phone", {
                          required: {
                            message: "Phone is required",
                            value: true,
                          },
                          pattern: {
                            value: new RegExp(/^\d+$/),
                            message: "Phone is not valid",
                          },
                        })}
                        sx={{ width: "60%" }}
                        id="outlined"
                        size="small"
                      />
                    </WrapPhone>
                    {errors.phone && (
                      <ErrorField>{errors.phone.message}</ErrorField>
                    )}
                  </>
                )}
              </Wrap>
            </Row>

            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Dob</TitleStyle>
              </WrapText>
              {!isEdit ? (
                <WrapText>
                  <TextStyle>
                    {agent?.dob
                      ? moment(agent?.dob).format("YYYY-MMM-DD")
                      : "-"}
                  </TextStyle>
                </WrapText>
              ) : (
                <Wrap>
                  <FormControl>
                    <Controller
                      name="dob"
                      control={control}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker
                              label="Date Of Birth"
                              {...field}
                              slotProps={{ textField: { size: "small" } }}
                              format="DD-MMM-YYYY"
                              value={field.value ? dayjs(field.value) : null} // Convert value to Date object
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      )}
                    />
                  </FormControl>
                </Wrap>
              )}
            </Row>
            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Gender</TitleStyle>
              </WrapText>
              {!isEdit ? (
                <WrapText>
                  <TextStyle>{agent?.gender}</TextStyle>
                </WrapText>
              ) : (
                <Wrap>
                  <FormControl>
                    <Controller
                      name="gender"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup row {...field}>
                          {Object.keys(Gender).map((key) => {
                            return (
                              <FormControlLabel
                                value={Gender[key as keyof typeof Gender]}
                                control={<Radio />}
                                label={key}
                              />
                            );
                          })}
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Wrap>
              )}
            </Row>
            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Zip Code</TitleStyle>
              </WrapText>
              {!isEdit ? (
                <WrapText>
                  <TextStyle>
                    {agent?.zip_code ? agent?.zip_code : "-"}
                  </TextStyle>
                </WrapText>
              ) : (
                <WrapInput>
                  <TextField
                    {...register("zip_code", {
                      required: {
                        value: true,
                        message: "Zip code is required",
                      },
                      pattern: {
                        value: new RegExp(/^\d+$/),
                        message: "Zip code is not valid",
                      },
                      minLength: {
                        value: 4,
                        message: "Zip code is not valid",
                      },
                      maxLength: {
                        value: 7,
                        message: "Zip code is not valid",
                      },
                    })}
                    fullWidth
                    id="outlined"
                    size="small"
                  />
                  {errors.zip_code && (
                    <ErrorField>{errors.zip_code.message}</ErrorField>
                  )}
                </WrapInput>
              )}
            </Row>

            <GroupTitleStyle>Identity & Credentials</GroupTitleStyle>
            <Row marginLeft={50}>
              <WrapTextRightColumn>
                <TitleStyle>Selfie ID front</TitleStyle>
              </WrapTextRightColumn>
              <WrapText>
                <ImageWrapper>
                  {agent?.image_selfie ? (
                    <img
                      src={agent?.image_selfie}
                      alt=""
                      onClick={() => setImgPopup(agent?.image_selfie)}
                    />
                  ) : (
                    "-"
                  )}
                </ImageWrapper>
              </WrapText>
              {isEdit && (
                <Wrap>
                  <FileInput
                    field_name="image_selfie"
                    chooseFile={chosenImageSelfie}
                    setChosenFile={setChosenImageSelfie}
                  />
                </Wrap>
              )}
            </Row>
            <Row marginLeft={50}>
              <WrapTextRightColumn>
                <TitleStyle>ID Front</TitleStyle>
              </WrapTextRightColumn>
              <WrapText>
                <ImageWrapper>
                  {agent?.image_card_identify_front ? (
                    <img
                      src={agent?.image_card_identify_front}
                      alt=""
                      onClick={() =>
                        setImgPopup(agent?.image_card_identify_front)
                      }
                    />
                  ) : (
                    "-"
                  )}
                </ImageWrapper>
              </WrapText>
              {isEdit && (
                <Wrap>
                  <FileInput
                    field_name="image_card_identify_front"
                    chooseFile={chosenIDFront}
                    setChosenFile={setChosenIDFront}
                  />
                </Wrap>
              )}
            </Row>
            <Row marginLeft={50}>
              <WrapTextRightColumn>
                <TitleStyle>ID Back</TitleStyle>
              </WrapTextRightColumn>
              <WrapText>
                <ImageWrapper>
                  {agent?.image_card_identify_back ? (
                    <img
                      src={agent?.image_card_identify_back}
                      alt=""
                      onClick={() =>
                        setImgPopup(agent?.image_card_identify_back)
                      }
                    />
                  ) : (
                    "-"
                  )}
                </ImageWrapper>
              </WrapText>
              {isEdit && (
                <Wrap>
                  <FileInput
                    field_name="image_card_identify_back"
                    chooseFile={chosenIDBack}
                    setChosenFile={setChosenIDBack}
                  />
                </Wrap>
              )}
            </Row>

            <GroupTitleStyle>Sales Experience</GroupTitleStyle>

            <Row marginLeft={50}>
              <WrapTextRightColumn>
                <TitleStyle>Current Job</TitleStyle>
              </WrapTextRightColumn>
              <Wrap>
                {!isEdit ? (
                  <WrapText>
                    <TextStyle>
                      {agent?.currently_job?.toLowerCase() !== "others"
                        ? agent?.currently_job
                        : `Others: ${agent?.currently_job_custom}`}

                      {!agent?.currently_job && "-"}
                    </TextStyle>
                  </WrapText>
                ) : (
                  <WrapInputMutiline>
                    <FormControl fullWidth>
                      <Controller
                        name="currently_job"
                        control={control}
                        render={({ field }) => (
                          <Select fullWidth size="small" {...field}>
                            {CURRENT_JOB_LIST_DEFAULT.map((job) => (
                              <MenuItem key={job.value} value={job.value}>
                                <span>{job.label}</span>
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                    {watch("currently_job")?.toLowerCase() === "others" && (
                      <FormControl fullWidth>
                        <TextField
                          {...register("currently_job_custom", {
                            required: {
                              value: true,
                              message: "Your other job is required",
                            },
                          })}
                          fullWidth
                          multiline
                          rows={2}
                          id="outlined"
                          size="small"
                          sx={{ marginTop: "1rem" }}
                          placeholder="Your other job"
                        />
                      </FormControl>
                    )}
                  </WrapInputMutiline>
                )}
              </Wrap>
            </Row>
            <Row marginLeft={50}>
              <WrapTextRightColumn>
                <TitleStyle>Business Area</TitleStyle>
              </WrapTextRightColumn>
              <Wrap>
                {!isEdit ? (
                  <WrapText>
                    <TextStyle>
                      {agent?.areas_of_bussiness !== "others"
                        ? agent?.areas_of_bussiness
                        : `Others: ${agent?.areas_of_bussiness_custom}`}

                      {!agent?.areas_of_bussiness && "-"}
                    </TextStyle>
                  </WrapText>
                ) : (
                  <WrapInputMutiline>
                    {/* <TextField
                      {...register("areas_of_bussiness")}
                      fullWidth
                      multiline
                      rows={5}
                      id="outlined"
                      size="small"
                    /> */}
                    <FormControl fullWidth>
                      <Controller
                        name="areas_of_bussiness"
                        control={control}
                        render={({ field }) => (
                          <Select fullWidth size="small" {...field}>
                            {BUSINESS_AREA_LIST_DEFAULT.map((item) => (
                              <MenuItem key={item.value} value={item.value}>
                                <span>{item.label}</span>
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                    {watch("areas_of_bussiness")?.toLowerCase() ===
                      "others" && (
                      <FormControl fullWidth>
                        <TextField
                          {...register("areas_of_bussiness_custom", {
                            required: {
                              value: true,
                              message: "Your business area is required",
                            },
                          })}
                          fullWidth
                          multiline
                          rows={2}
                          id="outlined"
                          size="small"
                          sx={{ marginTop: "1rem" }}
                          placeholder="Your business"
                        />
                      </FormControl>
                    )}
                  </WrapInputMutiline>
                )}
              </Wrap>
            </Row>
            <Row marginLeft={50}>
              <WrapTextRightColumn>
                <TitleStyle>Sales Experience</TitleStyle>
              </WrapTextRightColumn>
              <Wrap>
                {!isEdit ? (
                  <WrapText>
                    <TextStyle>
                      {agent?.sale_experience ? agent?.sale_experience : "-"}
                    </TextStyle>
                  </WrapText>
                ) : (
                  <WrapInputMutiline>
                    <FormControl fullWidth>
                      <Controller
                        name="sale_experience"
                        control={control}
                        render={({ field }) => (
                          <Select fullWidth size="small" {...field}>
                            {SALE_EXPERIENCE_LIST.map((item) => (
                              <MenuItem key={item.value} value={item.value}>
                                <span>{item.label}</span>
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </WrapInputMutiline>
                )}
              </Wrap>
            </Row>
            <Row marginLeft={50}>
              <WrapTextRightColumn>
                <TitleStyle>Expected Sales Per Week</TitleStyle>
              </WrapTextRightColumn>
              <Wrap>
                {!isEdit ? (
                  <WrapText>
                    <TextStyle>
                      {agent?.sales_volume ? agent?.sales_volume : "-"}
                    </TextStyle>
                  </WrapText>
                ) : (
                  <WrapInputMutiline>
                    <FormControl fullWidth>
                      <Controller
                        name="sales_volume"
                        control={control}
                        render={({ field }) => (
                          <Select fullWidth size="small" {...field}>
                            {SALE_VOLUME_LIST.map((item) => (
                              <MenuItem key={item.value} value={item.value}>
                                <span>{item.label}</span>
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </WrapInputMutiline>
                )}
              </Wrap>
            </Row>
            <Row marginLeft={50}>
              <WrapTextRightColumn>
                <TitleStyle>More About yourself</TitleStyle>
              </WrapTextRightColumn>
              <Wrap>
                {!isEdit ? (
                  <WrapText>
                    <TextStyle>
                      {agent?.about_yourself ? agent?.about_yourself : "-"}
                    </TextStyle>
                  </WrapText>
                ) : (
                  <WrapInputMutiline>
                    <TextField
                      {...register("about_yourself")}
                      fullWidth
                      multiline
                      rows={5}
                      id="outlined"
                      size="small"
                    />
                  </WrapInputMutiline>
                )}
              </Wrap>
            </Row>
            <GroupTitleStyle>other features</GroupTitleStyle>
            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Verified</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>
                  {agent?.verified ? (
                    <Chip label="True" color="success" variant="outlined" />
                  ) : (
                    <Chip label="False" color="warning" variant="outlined" />
                  )}
                </TextStyle>
              </WrapText>
            </Row>

            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Provider</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>{agent?.provider ? agent?.provider : "-"}</TextStyle>
              </WrapText>
            </Row>
            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Login Type</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>
                  {agent?.googleId ? "Google" : "Email/Password"}
                </TextStyle>
              </WrapText>
            </Row>

            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Id Card</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>{agent?.id_card ? agent?.id_card : "-"}</TextStyle>
              </WrapText>
            </Row>
            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Google Id</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>{agent?.googleId ? agent?.googleId : "-"}</TextStyle>
              </WrapText>
            </Row>
            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>RefCode</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>{agent?.ref_code}</TextStyle>
              </WrapText>
            </Row>
          </Column>
        </Row>
        <Row style={{ justifyContent: "center" }}>
          {!isEdit ? (
            <WrapButton>
              <CustomButton
                onClick={handleOpenEdit}
                bgColor="#E4A11B"
                type="button"
              >
                Update
              </CustomButton>
              <CustomButton
                onClick={() => setIsOpenDetailModal(false)}
                bgColor="gray"
                type="button"
              >
                Close
              </CustomButton>
            </WrapButton>
          ) : (
            <>
              <WrapButton>
                <CustomButton
                  loading={isLoading}
                  disabled={isLoading}
                  loadingPosition="start"
                  startIcon={<></>}
                  // type="submit"
                  bgColor="#14A44D"
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </CustomButton>
                <CustomButton
                  onClick={handleCancelEdit}
                  bgColor="gray"
                  type="button"
                  // width={50}
                >
                  Cancel
                </CustomButton>
              </WrapButton>
            </>
          )}
        </Row>
      </Wrapper>

      {imgPopup && (
        <CustomModal
          isOpen={imgPopup ? true : false}
          onClose={() => setImgPopup(null)}
        >
          <img src={imgPopup} alt="" />
        </CustomModal>
      )}
    </Container>
  );
}

export default Profile;

const Container = styled.div`
  height: 100%;
`;

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 50px;
`;

const Row = styled.div<{ marginLeft?: number; isContainer?: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 32px;
  ${({ marginLeft }) => marginLeft && `padding-left: ${marginLeft}px`}
  ${({ isContainer }) => isContainer && "gap: 160px; overflow: hidden auto;"}
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  @media (max-width: ${breakPoints.exDesktop}) {
    gap: 10px;
  }
`;

const WrapText = styled.div`
  min-width: 250px;
  @media (max-width: ${breakPoints.exDesktop}) {
    min-width: 200px;
  }
`;
const WrapInput = styled.div`
  min-width: 400px;
  transition: all 0.2s;
  @media (max-width: ${breakPoints.exDesktop}) {
    min-width: 250px;
  }
`;
const WrapPhone = styled(WrapInput)`
  display: flex;
  /* justify-content: space-between; */
  gap: 1rem;
`;

const WrapInputMutiline = styled.div`
  min-width: 800px;
  @media (max-width: ${breakPoints.exDesktop}) {
    min-width: 400px;
  }
`;

const WrapTextRightColumn = styled.div`
  min-width: 250px;
  @media (max-width: ${breakPoints.exDesktop}) {
    min-width: 250px;
  }
`;

const TextStyle = styled.span`
  line-height: 40px;
  font-size: 20px;
  font-weight: 600;
  font-family: "Quicksand";
  font-style: normal;
  /* text-transform: capitalize; */
  color: #222222;
  @media (max-width: ${breakPoints.exDesktop}) {
    font-size: 16px;
  }
`;

const TitleStyle = styled(TextStyle)`
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
  color: gray;
  @media (max-width: ${breakPoints.exDesktop}) {
    font-size: 16px;
  }
`;

const GroupTitleStyle = styled(TextStyle)`
  font-size: 24px;
  font-weight: 700;
  text-transform: capitalize;
  @media (max-width: ${breakPoints.exDesktop}) {
    font-size: 20px;
  }
`;

const Wrap = styled.div``;

const WrapButton = styled.div`
  display: flex;
  gap: 24px;
  @media (max-width: ${breakPoints.exDesktop}) {
    gap: 12px;
  }
`;

const CustomButton = styled(LoadingButton)<{ bgColor: string; width?: number }>`
  display: inline-block !important;
  vertical-align: middle !important;
  /* padding: 0 !important; */
  padding: 0 24px !important;
  margin: 0 !important;
  width: ${({ width }) => (width ? `${width}px` : "auto")};
  /* min-width: 35px !important; */
  min-width: 110px !important;
  height: 35px !important;
  line-height: unset !important;
  background-color: ${({ bgColor }) =>
    bgColor ? `${bgColor} !important` : "#54B4D3 !important"};
  color: #fff !important;

  .MuiIcon-root {
    height: auto;
  }
`;

const ImageWrapper = styled.div`
  width: 100px;
  height: 100px;
  img {
    width: 100%;
    height: 100%;
    transition: all 0.2s;
    object-fit: contain;
    :hover {
      transform: scale(4);
      cursor: zoom-in;
    }
  }
`;
const CustomLabelPhoneCodeSelect = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  > span {
    &:last-child {
      font-family: "Twemoji Country Flags";
      font-size: 2rem;
    }
  }
`;
const ErrorField = styled.p`
  margin-top: 0.2rem;
  color: red;
  font-size: 1rem;
  font-style: italic;
  animation: appear 0.2s;
  @keyframes appear {
    from {
      opacity: 0;
      transform: translateY(-20%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;
