import { IDateFilterParams } from "ag-grid-community";
import moment from "moment";

export const filterParams: IDateFilterParams = {
    comparator: (filterLocalDateAtMidnight: Date, cellValue: number) => {
        if (!cellValue) return -1;
        const dataTime = moment(cellValue * 1000).utc().format('YYYY-MM-DD');
        const filterTime = moment(filterLocalDateAtMidnight).format('YYYY-MM-DD');

        if (dataTime === filterTime) {
            return 0;
        }
        if (dataTime < filterTime) {
            return -1;
        }
        if (dataTime > filterTime) {
            return 1;
        }
        return 0;
    },
    minValidYear: 2022,
    inRangeFloatingFilterDateFormat: 'YYYY MMM Do',
};