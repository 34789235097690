import React from "react";
import useMaterialsLibraryImage from "./MaterialsLibraryImage.hooks";
import useMaterialsLibrary from "../../MaterialsLibrary.hooks";
import MaterialsTable from "../../components/MaterialsTable";
import LoadingPage from "../../../../components/LoadingPage";
import { MaterialType } from "../../../../constants/types";

const MaterialsLibraryImage = () => {
  const { isReloadList } = useMaterialsLibrary();
  const { imagesData, isLoading } = useMaterialsLibraryImage({
    refresh: isReloadList,
  });

  if (isLoading) return <LoadingPage />;
  if (!imagesData) return null;

  return <MaterialsTable data={imagesData} type={MaterialType.IMAGE} />;
};

export default MaterialsLibraryImage;
