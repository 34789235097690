import React, { useContext } from "react";
import OrderContext from "../../../context/order-context";
import { useSearchParams } from "react-router-dom";
import useTrackingOrder, {
  useTrackingOrderV2,
} from "../../../hooks/use-tracking-order";
import styled from "styled-components";
import TrackingStatus, { TrackingStatusC } from "./TrackingStatus";
import LoadingPage from "../../../../../components/LoadingPage";

const TrackingStatusV2 = () => {
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");

  const {
    data: trackingData,
    shipmentCheckpoints,
    loading,
  } = useTrackingOrderV2({
    orderId: orderId as string,
  });

  return (
    <Container>
      {!loading ? (
        <>
          {trackingData ? (
            <>
              <TopContainer>
                {/* row 1 */}
                <div>
                  <span>
                    Order code: <span>{trackingData.orders_code}</span>
                  </span>
                </div>
                <div>
                  <span>
                    Order status: <span>{trackingData.status}</span>
                  </span>
                </div>
                <div></div>
                {/* row 2 */}
                <div>
                  <span>
                    Warehouse code:{" "}
                    <span>{trackingData.warehouse_order_code}</span>
                  </span>
                </div>
                <div>
                  <span>
                    Warehouse status:{" "}
                    <span>{trackingData.warehouse_status}</span>
                  </span>
                </div>
                <div>
                  <span>
                    Warehouse error: <p>{trackingData.warehouse_error}</p>
                  </span>
                </div>
                {/* row 3 */}
                <div>
                  <span>
                    Shipment code:{" "}
                    <span>{trackingData.shipment_order_code}</span>
                  </span>
                </div>
                <div>
                  <span>
                    Shipment status: <span>{trackingData.shipment_status}</span>
                  </span>
                </div>
                <div>
                  <span>
                    Shipment error: <p>{trackingData.shipment_error}</p>
                  </span>
                </div>
              </TopContainer>
              <BottomContainer>
                <div>
                  <TrackingStatus />
                </div>
                <div>
                  <TrackingStatusC
                    title={"Shipment checkpoints"}
                    trackingData={shipmentCheckpoints}
                  />
                </div>
              </BottomContainer>
            </>
          ) : (
            <NoData>No data</NoData>
          )}
        </>
      ) : (
        <LoadingPage />
      )}
    </Container>
  );
};

export default TrackingStatusV2;

const Container = styled.div`
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.1); */
`;
const TopContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  margin-top: 2rem;
  > div > span > span {
    font-weight: 700;
    text-transform: uppercase;
  }
`;
const BottomContainer = styled.div`
  margin-top: 4rem;
  display: flex;
  justify-content: space-between;
  > div {
    width: 45%;
  }
`;

const NoData = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 2.4rem;
  font-style: italic;
`;
