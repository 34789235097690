import { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import Modal from "@mui/material/Modal";
import { Icon, Switch } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import Api from "../../../service";
import EventContext from "../context/event-context";
import { LangType } from "../../../constants/types";
import { toast } from "react-toastify";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import "react-quill/dist/quill.snow.css";
import { TabContext, TabList } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import CustomTabComponent from "./CustomTab";

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
`;
const CreateButton = styled(LoadingButton)`
  padding: 8px 32px !important;
  background: rgb(85, 177, 71) !important;
  color: #ffffff !important;
  border-radius: 8px !important;
  cursor: pointer;
`;
const ButtonText = styled.span`
  font-size: 20px;
`;

const FormWrap = styled.div`
  overflow: hidden auto;
  height: 100vh;
`;

const Form = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  min-height: 400px;
  background: #fff;
  border-radius: 24px;
  width: 70%;
  margin: 5% auto;
`;

const RowWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 0 24px;
  width: 100%;
`;

const AndroidIOSWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 24px;
  gap: 24px;
`;

const ColumnWrap = styled.div<{ widthPercent?: number }>`
  display: flex;
  flex-direction: column;
  width: ${({ widthPercent }) => (widthPercent ? `${widthPercent}%` : "100%")};
  gap: 16px;
  .MuiFormHelperText-root {
    color: #d32f2f;
  }
`;

const TitleWrap = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  margin: 0;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.0025em;
  text-transform: uppercase;
  color: #222222;
`;

const RequiredStart = styled.span`
  vertical-align: middle;
  font-size: 20px;
  font-weight: 700;
  color: red;
`;

const ErrorText = styled.p`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 4px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
  color: rgb(211, 47, 47);
`;

const UploadWrap = styled.div`
  cursor: pointer;
  width: 300px;
  height: 300px;
  border-radius: 8px;
  background-color: "rgba(0,0,0,.02)";
  border: 1px dashed #d9d9d9;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const UploadText = styled.span`
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #222222;
`;

const UploadTextNote = styled(UploadText)`
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #cccccc;
`;

const CameraBtn = styled(CloudUploadIcon)`
  width: 100% !important;
  height: 100% !important;
`;

const FieldTitle = styled.span`
  min-width: 200px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 35px;
  letter-spacing: -0.0025em;
  color: #222222;
`;

const WrapDatePicker = styled.div`
  .MuiTextField-root {
    width: 100%;
    .MuiOutlinedInput-input {
      padding: 9px 14px;
    }
  }
`;

const PhotoWrap = styled.div`
  max-width: 300px;
  height: 200px;
  max-height: 200px;
`;

const Photo = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
`;

const CloseWrap = styled.div<{ isCloseLang?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: ${({ isCloseLang }) => (isCloseLang ? "0" : "0 24px")};

  ${({ isCloseLang }) =>
    isCloseLang && {
      position: "absolute",
      right: "0",
      top: "0",
    }};
`;

const IconWrap = styled(Icon)`
  cursor: pointer;
`;

const CloseBtn = styled(CloseIcon)`
  width: 100% !important;
  height: 100% !important;
`;

const CustomTabContext = styled(TabContext)`
  width: 100%;
  height: 100%;
  background-color: #fff;
`;

const CustomTab = styled(Tab)`
  font-size: 18px !important;
  font-weight: 700 !important;
  font-family: "Quicksand" !important;
  font-style: normal !important;
  text-transform: capitalize !important;
  color: #222222 !important;
`;

interface IPhoto {
  urlPreview?: string;
  formDataValue: string;
}

export interface ILang {
  lang: string;
  title: string;
  shortDescription: string;
  description: string;
  banner: string;
  checkedUseBannerForAll: boolean;
}

interface Iprops {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export enum BroadcastActionType {
  Send = "SEND",
  Unsend = "UNSEND",
}

export interface IBroadcastBannerActionProps {
  actionType: BroadcastActionType.Send | BroadcastActionType.Unsend;
  lang: string;
  url: string;
}

function CreateFormModal({ isOpen, setIsOpen }: Iprops) {
  const eventCtx = useContext(EventContext);
  const { event, setEvent, isReloadList, setIsReloadList } = eventCtx;

  const [tab, setTab] = useState<string>(LangType.English);
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckerror, setIsCheckerror] = useState(false);
  const [show, setShow] = useState<boolean>(true);
  const [hot, setHot] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState<Dayjs | null>();
  const [toDate, setToDate] = useState<Dayjs | null>();

  const [langEN, setLangEN] = useState<ILang>({
    lang: LangType.English,
    title: "",
    shortDescription: "",
    description: "",
    banner: "",
    checkedUseBannerForAll: false,
  });
  const [langKO, setLangKO] = useState<ILang>({
    lang: LangType.Korea,
    title: "",
    shortDescription: "",
    description: "",
    banner: "",
    checkedUseBannerForAll: false,
  });
  const [langVN, setLangVN] = useState<ILang>({
    lang: LangType.vietnamese,
    title: "",
    shortDescription: "",
    description: "",
    banner: "",
    checkedUseBannerForAll: false,
  });
  const [langJP, setLangJP] = useState<ILang>({
    lang: LangType.Japan,
    title: "",
    shortDescription: "",
    description: "",
    banner: "",
    checkedUseBannerForAll: false,
  });
  const [langCN, setLangCN] = useState<ILang>({
    lang: LangType.China,
    title: "",
    shortDescription: "",
    description: "",
    banner: "",
    checkedUseBannerForAll: false,
  });
  const [langES, setLangES] = useState<ILang>({
    lang: LangType.Spanish,
    title: "",
    shortDescription: "",
    description: "",
    banner: "",
    checkedUseBannerForAll: false,
  });
  const [langPT, setLangPT] = useState<ILang>({
    lang: LangType.Portuguese,
    title: "",
    shortDescription: "",
    description: "",
    banner: "",
    checkedUseBannerForAll: false,
  });

  const [photoSelected, setPhotoSelected] = useState<IPhoto>({
    urlPreview: "",
    formDataValue: "",
  });

  const handleClose = () => {
    setIsOpen(false);
    event?._id && clearEventSelected();
  };

  const handleUploadImages = async (file: any) => {
    if (!file) return "";
    let formData = new FormData();
    formData.append("image", file);
    try {
      const responsive = await Api.Event.uploadImage(formData);
      return responsive.data;
    } catch (error) {
      console.log("Upload file error:", error);
    }
  };

  const handleValidateForm = () => {
    const invalid =
      !photoSelected.urlPreview ||
      (hot &&
        (!fromDate ||
          !toDate ||
          dayjs(fromDate).unix() > dayjs(toDate).unix())) ||
      !langEN.title ||
      !langEN.shortDescription ||
      !langEN.banner ||
      !langEN.description ||
      langEN.description === "<p><br></p>";

    return invalid;
  };

  const handleCapture = (event: any) => {
    const file = event.target.files[0];
    if (!file) return;
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (e) => {
      setPhotoSelected({
        urlPreview: e.target?.result?.toString() || "",
        formDataValue: file || "",
      });
    };
  };

  const handleSubmitForm = async (e: any) => {
    e.preventDefault();

    setIsCheckerror(true);

    if (handleValidateForm()) return;

    try {
      setIsLoading(true);

      const thumbnail = photoSelected.formDataValue
        ? await handleUploadImages(photoSelected.formDataValue)
        : "";

      const eventToPost = {
        ...(thumbnail && { thumbnail }),
        isShow: show,
        isHot: hot,
        isHotFrom: hot ? dayjs(fromDate).unix() : 0,
        isHotTo: hot ? dayjs(toDate).unix() : 0,
        contents: [
          {
            lang: langEN.lang,
            title: langEN.title,
            banner: langEN.banner,
            description: langEN.description,
            shortDescription: langEN.shortDescription,
          },
          {
            lang: langKO.lang,
            title: langKO.title,
            banner: langKO.banner,
            description: langKO.description,
            shortDescription: langKO.shortDescription,
          },
          {
            lang: langVN.lang,
            title: langVN.title,
            banner: langVN.banner,
            description: langVN.description,
            shortDescription: langVN.shortDescription,
          },
          {
            lang: langJP.lang,
            title: langJP.title,
            banner: langJP.banner,
            description: langJP.description,
            shortDescription: langJP.shortDescription,
          },
          {
            lang: langCN.lang,
            title: langCN.title,
            banner: langCN.banner,
            description: langCN.description,
            shortDescription: langCN.shortDescription,
          },
          {
            lang: langES.lang,
            title: langES.title,
            banner: langES.banner,
            description: langES.description,
            shortDescription: langES.shortDescription,
          },
          {
            lang: langPT.lang,
            title: langPT.title,
            banner: langPT.banner,
            description: langPT.description,
            shortDescription: langPT.shortDescription,
          },
        ],
        ...(event?._id && { eventId: event?._id }),
      };

      const response = event?._id
        ? await Api.Event.updateEvent(eventToPost)
        : await Api.Event.addEvent(eventToPost);
      if (response) {
        toast.success(event?._id ? "Update succes !" : "Create success !");
        setIsReloadList(!isReloadList);
        handleClose();
      }
    } catch (error) {
      console.log("error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const broadcastBanner = (props: IBroadcastBannerActionProps) => {
    if (!props.url) return;

    props.lang !== LangType.English &&
      setLangEN({
        ...langEN,
        banner: props.actionType === BroadcastActionType.Send ? props.url : "",
        checkedUseBannerForAll: false,
      });

    props.lang !== LangType.Korea &&
      setLangKO({
        ...langKO,
        banner: props.actionType === BroadcastActionType.Send ? props.url : "",
        checkedUseBannerForAll: false,
      });

    props.lang !== LangType.vietnamese &&
      setLangVN({
        ...langVN,
        banner: props.actionType === BroadcastActionType.Send ? props.url : "",
        checkedUseBannerForAll: false,
      });

    props.lang !== LangType.Japan &&
      setLangJP({
        ...langJP,
        banner: props.actionType === BroadcastActionType.Send ? props.url : "",
        checkedUseBannerForAll: false,
      });

    props.lang !== LangType.China &&
      setLangCN({
        ...langCN,
        banner: props.actionType === BroadcastActionType.Send ? props.url : "",
        checkedUseBannerForAll: false,
      });

    props.lang !== LangType.Spanish &&
      setLangES({
        ...langES,
        banner: props.actionType === BroadcastActionType.Send ? props.url : "",
        checkedUseBannerForAll: false,
      });

    props.lang !== LangType.Portuguese &&
      setLangPT({
        ...langPT,
        banner: props.actionType === BroadcastActionType.Send ? props.url : "",
        checkedUseBannerForAll: false,
      });
  };

  const clearEventSelected = () => {
    setEvent(null);
  };

  const handleBindingFormData = async () => {
    bindingFormDataSelected();
  };

  const bindingFormDataSelected = () => {
    if (!event?._id) return;

    setPhotoSelected({
      urlPreview: event?.thumbnail || "",
      formDataValue: "",
    });

    const langENFound = event?.contents.find(
      (item) => item.lang === LangType.English
    );
    langENFound && setLangEN({ ...langENFound, checkedUseBannerForAll: false });

    const langKOFound = event?.contents.find(
      (item) => item.lang === LangType.Korea
    );
    langKOFound && setLangKO({ ...langKOFound, checkedUseBannerForAll: false });

    const langVNFound = event?.contents.find(
      (item) => item.lang === LangType.vietnamese
    );
    langVNFound && setLangVN({ ...langVNFound, checkedUseBannerForAll: false });

    const langJPFound = event?.contents.find(
      (item) => item.lang === LangType.Japan
    );
    langJPFound && setLangJP({ ...langJPFound, checkedUseBannerForAll: false });

    const langCNFound = event?.contents.find(
      (item) => item.lang === LangType.China
    );
    langCNFound && setLangCN({ ...langCNFound, checkedUseBannerForAll: false });

    const langESFound = event?.contents.find(
      (item) => item.lang === LangType.Spanish
    );
    langESFound && setLangES({ ...langESFound, checkedUseBannerForAll: false });

    const langPTFound = event?.contents.find(
      (item) => item.lang === LangType.Portuguese
    );
    langPTFound && setLangPT({ ...langPTFound, checkedUseBannerForAll: false });

    setShow(event?.isShow);
    setHot(event?.isHot);
    setFromDate(event?.isHotFrom ? dayjs.unix(event?.isHotFrom) : null);
    setToDate(event?.isHotTo ? dayjs.unix(event?.isHotTo) : null);
  };

  useEffect(() => {
    handleBindingFormData();
  }, []);

  const handleChangeTab = (e: any, value: string) => {
    setTab(value);
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box component="form">
          <FormWrap>
            <Form>
              <CloseWrap>
                <IconWrap fontSize="large" onClick={handleClose}>
                  <CloseBtn />
                </IconWrap>
              </CloseWrap>
              <TitleWrap>
                <Title>Event</Title>
              </TitleWrap>
              <RowWrap>
                <ColumnWrap>
                  <FieldTitle>
                    Thumbnail{" "}
                    {!photoSelected.urlPreview && (
                      <RequiredStart>*</RequiredStart>
                    )}
                  </FieldTitle>
                  <AndroidIOSWrap style={{ alignItems: "flex-start" }}>
                    <ColumnWrap style={{ maxWidth: 300 }}>
                      <ColumnWrap style={{ maxWidth: 300 }}>
                        <input
                          name="thumbnail"
                          onChange={handleCapture}
                          accept="image/*"
                          id="icon-button-photo-android"
                          //multiple
                          type="file"
                          style={{ display: "none" }}
                        />
                        <label htmlFor="icon-button-photo-android">
                          <UploadWrap>
                            <IconWrap fontSize="large">
                              <CameraBtn />
                            </IconWrap>
                            <UploadText>Select image to upload</UploadText>
                            <UploadTextNote>
                              (ratio: 1x1 - pixel: 800 x 800)
                            </UploadTextNote>
                          </UploadWrap>
                        </label>
                        {isCheckerror && !photoSelected.urlPreview && (
                          <ErrorText>is required</ErrorText>
                        )}
                      </ColumnWrap>
                    </ColumnWrap>
                    <ColumnWrap widthPercent={50}>
                      {photoSelected.urlPreview && (
                        <AndroidIOSWrap>
                          <PhotoWrap>
                            <Photo
                              src={photoSelected.urlPreview}
                              alt="thumbnail"
                              loading="lazy"
                            />
                          </PhotoWrap>
                        </AndroidIOSWrap>
                      )}
                    </ColumnWrap>
                  </AndroidIOSWrap>
                </ColumnWrap>
              </RowWrap>
              <RowWrap>
                <FieldTitle>Show</FieldTitle>
                <ColumnWrap>
                  <Switch
                    checked={show}
                    onChange={(e: any) => setShow(e?.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </ColumnWrap>
              </RowWrap>
              <RowWrap style={{ minHeight: 135, alignItems: "flex-start" }}>
                <FieldTitle>Hot</FieldTitle>
                <ColumnWrap widthPercent={50}>
                  <Switch
                    checked={hot}
                    onChange={(e: any) => setHot(e?.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </ColumnWrap>
                {hot && (
                  <>
                    <ColumnWrap>
                      <FieldTitle>From date</FieldTitle>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <WrapDatePicker>
                          <DateTimePicker
                            value={fromDate}
                            onChange={(newValue) => setFromDate(newValue)}
                          />
                        </WrapDatePicker>
                      </LocalizationProvider>
                      {isCheckerror && !fromDate && (
                        <ErrorText>is required</ErrorText>
                      )}
                    </ColumnWrap>
                    <ColumnWrap>
                      <FieldTitle>To date</FieldTitle>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <WrapDatePicker>
                          <DateTimePicker
                            value={toDate}
                            onChange={(newValue) => setToDate(newValue)}
                          />
                        </WrapDatePicker>
                      </LocalizationProvider>
                      {isCheckerror && !toDate && (
                        <ErrorText>is required</ErrorText>
                      )}
                      {isCheckerror &&
                        fromDate &&
                        toDate &&
                        dayjs(fromDate).unix() > dayjs(toDate).unix() && (
                          <ErrorText>more than from date</ErrorText>
                        )}
                    </ColumnWrap>
                  </>
                )}
              </RowWrap>
              <RowWrap style={{ alignItems: "flex-start" }}>
                <ColumnWrap>
                  <FieldTitle>Languages</FieldTitle>
                  <CustomTabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChangeTab}
                        aria-label="lab API tabs example"
                      >
                        <CustomTab label={"English"} value={LangType.English} />
                        <CustomTab label={"Korea"} value={LangType.Korea} />
                        <CustomTab
                          label={"Vietnamese"}
                          value={LangType.vietnamese}
                        />
                        <CustomTab label={"Japanese"} value={LangType.Japan} />
                        <CustomTab label={"Chinese"} value={LangType.China} />
                        <CustomTab label={"Spanish"} value={LangType.Spanish} />
                        <CustomTab
                          label={"Portuguese"}
                          value={LangType.Portuguese}
                        />
                      </TabList>
                    </Box>
                    <CustomTabComponent
                      value={LangType.English}
                      lang={langEN}
                      setLang={setLangEN}
                      isCheckerror={isCheckerror}
                      setIsCheckerror={setIsCheckerror}
                      isShowError
                      setIsLoading={setIsLoading}
                      broadcastBanner={broadcastBanner}
                    />
                    <CustomTabComponent
                      value={LangType.Korea}
                      lang={langKO}
                      setLang={setLangKO}
                      isCheckerror={isCheckerror}
                      setIsCheckerror={setIsCheckerror}
                      setIsLoading={setIsLoading}
                      broadcastBanner={broadcastBanner}
                    />
                    <CustomTabComponent
                      value={LangType.vietnamese}
                      lang={langVN}
                      setLang={setLangVN}
                      isCheckerror={isCheckerror}
                      setIsCheckerror={setIsCheckerror}
                      setIsLoading={setIsLoading}
                      broadcastBanner={broadcastBanner}
                    />
                    <CustomTabComponent
                      value={LangType.Japan}
                      lang={langJP}
                      setLang={setLangJP}
                      isCheckerror={isCheckerror}
                      setIsCheckerror={setIsCheckerror}
                      setIsLoading={setIsLoading}
                      broadcastBanner={broadcastBanner}
                    />
                    <CustomTabComponent
                      value={LangType.China}
                      lang={langCN}
                      setLang={setLangCN}
                      isCheckerror={isCheckerror}
                      setIsCheckerror={setIsCheckerror}
                      setIsLoading={setIsLoading}
                      broadcastBanner={broadcastBanner}
                    />
                    <CustomTabComponent
                      value={LangType.Spanish}
                      lang={langES}
                      setLang={setLangES}
                      isCheckerror={isCheckerror}
                      setIsCheckerror={setIsCheckerror}
                      setIsLoading={setIsLoading}
                      broadcastBanner={broadcastBanner}
                    />
                    <CustomTabComponent
                      value={LangType.Portuguese}
                      lang={langPT}
                      setLang={setLangPT}
                      isCheckerror={isCheckerror}
                      setIsCheckerror={setIsCheckerror}
                      setIsLoading={setIsLoading}
                      broadcastBanner={broadcastBanner}
                    />
                  </CustomTabContext>
                </ColumnWrap>
              </RowWrap>
              <ButtonWrap>
                <CreateButton
                  loading={isLoading}
                  disabled={isLoading}
                  loadingPosition="start"
                  startIcon={<></>}
                  onClick={handleSubmitForm}
                >
                  <ButtonText>{event?._id ? "Update" : "Create"}</ButtonText>
                </CreateButton>
              </ButtonWrap>
            </Form>
          </FormWrap>
        </Box>
      </Modal>
    </>
  );
}

export default CreateFormModal;
