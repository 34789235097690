import React, { useCallback, useEffect, useState } from 'react';
import Api from '../service';

export interface District {
    _id: string;
    name: string;
    country_id: number;
    state_id: number;
    id?: string;
}
const useDistrict = () => {
    const [listDistricts, setListDistricts] = useState<District[]>([]);
    const getDistricts = async (country_id: number, state_id: number) => {
        try {
            const response = await Api.Admin.getProvinces(
                { country_id, state_id }
            );
            if (response?.data) {
                const data = response.data;
                // add other city
                data.push({ _id: '0', id: '0', name: 'Others' })
                setListDistricts(data);
            }
        } catch (error) {
            console.log('error:', error)
        }
    };


    return { listDistricts, fetchDistricts: getDistricts, setListDistricts }
}

export default useDistrict