import { ICellRendererParams } from "ag-grid-community";
import { IProductKey, ProductKeyApplication } from "../../constants";

const ApplicationNameRender = (props: ICellRendererParams) => {
  const data = props.data as IProductKey;

  return (
    <>
      {data.application === ProductKeyApplication.FriendifyAI && "Friendify AI"}
      {data.application === ProductKeyApplication.PoolsWallet && "POOLS Wallet"}
    </>
  );
};

export default ApplicationNameRender;
