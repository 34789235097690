import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "@ag-grid-community/react";
import {
  CheckboxSelectionCallbackParams,
  ColDef,
  FirstDataRenderedEvent,
  GridReadyEvent,
  HeaderCheckboxSelectionCallbackParams,
  PaginationNumberFormatterParams,
} from "@ag-grid-community/core";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import styled from "styled-components";
import { User } from "../../../constants/interface";
import Detail from "./detail/Detail";

import { convertDateToYYYYMMDD_HHmm } from "../../../utils";
import Api from "../../../service";
import { Modal } from "@mui/material";
import ActionsCell from "./ActionsCell";
import UserContext from "../context/user-context";
import AddPoint from "./AddPoint";
import LoginTypeCell from "./LoginTypeCell";
import { ExportExcel } from "../../../components/export/Excel";

const Container = styled.div`
  width: 100%;
  height: 75vh;
  /* box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.2); */
  border-radius: 10px;
  position: relative;
  .ag-row {
    cursor: pointer !important;
  }
`;
const PaginationContainer = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: center;
`;
const NumberOfDaoWithSameLevelContainer = styled.div`
  margin-top: 50px;
  position: absolute;
  right: 0;
  label {
    font-size: 18px;
    font-style: italic;
    span {
      font-weight: 700;
    }
  }
`;
const ModalContainer = styled.div`
  width: 70%;
  overflow-y: auto;
`;

const CustomModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PageSizeContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: -50px;
`;

// Register the required feature modules with the Grid
ModuleRegistry.registerModules([ClientSideRowModelModule]);

var checkboxSelection = function (params: CheckboxSelectionCallbackParams) {
  // we put checkbox on the name if we are not doing grouping
  return params.columnApi.getRowGroupColumns().length === 0;
};

var headerCheckboxSelection = function (
  params: HeaderCheckboxSelectionCallbackParams
) {
  // we put checkbox on the name if we are not doing grouping
  return params.columnApi.getRowGroupColumns().length === 0;
};

const ListUser = () => {
  const userCtx = useContext(UserContext);
  const {
    isOpenModal,
    setIsOpenModal,
    isOpenDetailModal,
    setIsOpenDetailModal,
    setUser,
  } = userCtx;

  const gridRef = useRef<AgGridReact<User>>(null);
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState<User[]>();
  const [excelData, setExcelData] = useState<any[]>();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    // {
    //   field: "avatar",
    //   cellRenderer: Avatar,
    //   filter: false,
    //   floatingFilter: false,
    // },
    { field: "fullname", headerName: "Full name" },
    {
      field: "provider",
      headerName: "Login Type",
      cellStyle: { fontWeight: "700" },
      cellRenderer: LoginTypeCell,
    },
    {
      field: "email",
      headerName: "Email",
      cellStyle: { fontWeight: "700" },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      cellStyle: { fontWeight: "700" },
      filter: false,
    },
    {
      colId: "action",
      headerName: "",
      floatingFilter: false,
      cellRenderer: ActionsCell,
      filter: false,
    },
  ]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      // editable: true,
      // enableRowGroup: true,

      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      // flex: 1,
      minWidth: 100,
    };
  }, []);
  const paginationNumberFormatter = useCallback(
    (params: PaginationNumberFormatterParams) => {
      return "[" + params.value.toLocaleString() + "]";
    },
    []
  );

  const headersExcel = [
    [
      ["Full Name"],
      ["Email"],
      ["Avatar"],
      ["Provider"],
      ["Login Type"],
      ["Google Id"],
    ],
  ];

  const formatExcelData = (listUser: User[]) => {
    const customData = listUser?.map((item) => {
      return {
        fullname: item.fullname,
        email: item.email,
        avatar: item.avatar,
        provider: item.provider,
        loginType: item.googleId ? "Google" : "Email/Password",
        googleId: item.googleId?.toString(),
      };
    });
    setExcelData(customData);
  };

  const onGridReady = useCallback((params: GridReadyEvent) => {
    Api.Admin.getUsers().then((listUser: any) => {
      formatExcelData(listUser.data.data);
      const formattedDateListUser = listUser.data.data.map((user: User) => {
        return {
          ...user,
          createdAt: `${convertDateToYYYYMMDD_HHmm(user.createdAt)}`,
        };
      });
      setRowData(formattedDateListUser);
      gridRef?.current?.api.sizeColumnsToFit({
        defaultMinWidth: 50,
        columnLimits: [
          { key: "avatar", maxWidth: 100 },
          { key: "fullname", minWidth: 100 },
          { key: "loginType", minWidth: 100 },
          { key: "uuid", minWidth: 400 },
          { key: "point", minWidth: 200 },
        ],
      });
    });
  }, []);

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    gridRef.current!.api.paginationGoToPage(0);
    gridRef.current!.api.paginationSetPageSize(20);
  }, []);

  const onPageSizeChanged = useCallback(() => {
    var value = (document.getElementById("page-size") as HTMLInputElement)
      .value;
    gridRef.current!.api.paginationSetPageSize(Number(value));
  }, []);

  const onRowDoubleClicked = useCallback(() => {
    const selectedRows = gridRef.current!.api.getSelectedRows();
    if (selectedRows.length !== 1) return;
    setIsOpenDetailModal(true);
    setUser(selectedRows[0]);
  }, []);

  return (
    <Container>
      <ExportExcel
        headers={headersExcel}
        data={excelData || ""}
        fileName={"User-List.xlsx"}
      />

      <div style={containerStyle}>
        <div
          className="example-wrapper"
          style={{ width: "100%", height: "100%", position: "relative" }}
        >
          <div style={gridStyle} className="ag-theme-alpine">
            <AgGridReact<User>
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows={true}
              // suppressRowClickSelection={true}
              // groupSelectsChildren={true}
              // rowGroupPanelShow={"always"}
              pivotPanelShow={"always"}
              pagination={true}
              paginationPageSize={10}
              paginationNumberFormatter={paginationNumberFormatter}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
              // rowSelection={"single"}
              // onRowDoubleClicked={onRowDoubleClicked}
            ></AgGridReact>
          </div>

          <PageSizeContainer className="example-header">
            Page Size:{" "}
            <select onChange={onPageSizeChanged} id="page-size">
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="500">500</option>
            </select>
          </PageSizeContainer>
        </div>
      </div>
      {isOpenModal && (
        <CustomModal
          open={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <AddPoint />
        </CustomModal>
      )}
      {isOpenDetailModal && (
        <CustomModal
          open={isOpenDetailModal}
          onClose={() => setIsOpenDetailModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModalContainer>
            <Detail />
          </ModalContainer>
        </CustomModal>
      )}
    </Container>
  );
};
export default ListUser;
