import React, { createContext, useState } from "react";
import { Outlet } from "react-router-dom";
import { IProductKey } from "../constants";

interface Props {
  children: React.ReactNode;
}

// Define the context interface
interface ProductKeyContextType {
  isReloadList: boolean;
  setIsReloadList: React.Dispatch<React.SetStateAction<boolean>>;

  productKey: IProductKey | null;
  setProductKey: React.Dispatch<React.SetStateAction<IProductKey | null>>;
}

// Create the context
const ProductKeyContext = createContext<ProductKeyContextType | undefined>(
  undefined
);

// Custom Provider component
export const ProductKeyContextProvider = (props: Props) => {
  const [isReloadList, setIsReloadList] = useState<boolean>(false);
  const [productKey, setProductKey] = useState<IProductKey | null>(null);

  return (
    <ProductKeyContext.Provider
      value={{
        isReloadList,
        setIsReloadList,
        productKey,
        setProductKey,
      }}
    >
      {props.children}
    </ProductKeyContext.Provider>
  );
};

export const ProductKeyOutlet = (props: any) => {
  return (
    <ProductKeyContextProvider>
      {props.children ? props.children : <Outlet />}
    </ProductKeyContextProvider>
  );
};
export default ProductKeyContext;
