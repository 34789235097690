import { ICellRendererParams } from "ag-grid-community";
import styled from "styled-components";
import { IMaterial } from "../../../../constants/interface";
import { MaterialType } from "../../../../constants/types";
import FilmIcon from "../../imgs/filmIcon.png";
import PdfIcon from "../../imgs/pdfIcon.png";
import fileIcon from "../../imgs/fileIcon.png";

function ImageCell(props: ICellRendererParams) {
  const rowData = props.data as IMaterial;

  return (
    <Container>
      {rowData.type === MaterialType.IMAGE && (
        <PhotoWrap>
          <Photo src={rowData.url} alt="photo" loading="lazy" />
        </PhotoWrap>
      )}
      {rowData.type === MaterialType.VIDEO && (
        <IconWrap>
          <img
            src={rowData?.thumbnail || FilmIcon}
            alt="video"
            loading="lazy"
          />
        </IconWrap>
      )}
      {rowData.type === MaterialType.OTHER && (
        <IconWrap>
          <img src={fileIcon} alt="file" loading="lazy" />
        </IconWrap>
      )}
    </Container>
  );
}

export default ImageCell;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PhotoWrap = styled.div`
  padding: 0.5rem;
  width: 140px;
  max-width: 140px;
  height: 140px;
  max-height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Photo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;
const IconWrap = styled.div`
  width: 100%;
  height: 100%;
  padding: 0.5rem;

  text-align: center;
  > img {
    /* width: 100%; */
    height: 100%;
  }
`;
