import React, { createContext, useState } from "react";
import { Outlet } from "react-router-dom";
import { NewsfeedComment } from "../../../../../constants/interface";

interface Props {
  children: React.ReactNode;
}
// Define the context interface
interface CommentContextType {
  isReloadList: boolean;
  setIsReloadList: React.Dispatch<React.SetStateAction<boolean>>;

  comment: NewsfeedComment | null;
  setComment: React.Dispatch<React.SetStateAction<NewsfeedComment | null>>;

  node: any;
  setNode: React.Dispatch<any>;
}

// Create the context
const CommentContext = createContext<CommentContextType>({
  /*eslint-disable */
  isReloadList: false,
  setIsReloadList: () => {},
  comment: null,
  setComment: () => {},
  node: "",
  setNode: () => {},
  /*eslint-disable */
});

// Custom Provider component
export const CommentContextProvider = (props: Props) => {
  const [comment, setComment] = useState<NewsfeedComment | null>(null);
  const [node, setNode] = useState<any | null>(null);
  const [isReloadList, setIsReloadList] = useState<boolean>(false);

  return (
    <CommentContext.Provider
      value={{
        isReloadList,
        setIsReloadList,
        comment,
        setComment,
        node,
        setNode,
      }}
    >
      {props.children}
    </CommentContext.Provider>
  );
};

export const CommentOutlet = (props: any) => {
  return (
    <CommentContextProvider>
      {props.children ? props.children : <Outlet />}
    </CommentContextProvider>
  );
};
export default CommentContext;
