import { ICellRendererParams } from "ag-grid-community";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PhotoWrap = styled.div`
  width: 140px;
  max-width: 140px;
  height: 140px;
  max-height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Photo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
`;

function ImageCell(props: ICellRendererParams) {
  // const rowData = props.data;
  // const column = props.colDef;
  return (
    <Container>
      <PhotoWrap>
        {props?.data?.imagePreview && (
          <Photo src={props.data.imagePreview} alt="" loading="lazy" />
        )}
      </PhotoWrap>
    </Container>
  );
}

export default ImageCell;
